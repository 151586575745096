import { Mutation, Query } from '@rhinofi/effect-rtk'
import { BridgeClient } from '../../services/bridge/BridgeClient'
import { createApiFromEffectTag } from '../appRuntime'

export const { api: bridgeApi, ApiErrorPhantom } = createApiFromEffectTag(
  BridgeClient,
  { reducerPath: 'bridgeApi' },
  {
    quotePublicQuote: {
      type: Query,
    },
    quoteUserQuote: {
      type: Query,
    },
    quoteUserQuote2: {
      type: Mutation,
    },
    quoteCommitQuote: {
      type: Mutation,
    },
    historyUserHistory: {
      type: Query,
    },
    historyBridgeHistory: {
      type: Query,
    },
    historyPatchBridgeHistory: {
      type: Mutation,
    },
  },
)

export type BridgeApiError = typeof ApiErrorPhantom

// TODO BINF-536 - refactor bridge api spec to improve method names and remove these alliases
export const useGetPublicQuoteQuery = bridgeApi.useQuotePublicQuoteQuery
export const useGetUserQuoteQuery = bridgeApi.useQuoteUserQuoteQuery
export const useGenUserQuoteMutation = bridgeApi.useQuoteUserQuote2Mutation
export const useCommitUserQuoteMutation = bridgeApi.useQuoteCommitQuoteMutation
export const useGetUserHistoryQuery = bridgeApi.useHistoryUserHistoryQuery
export const useGetBridgeHistoryQuery = bridgeApi.useHistoryBridgeHistoryQuery
export const usePatchBridgeHistoryMutation = bridgeApi.useHistoryPatchBridgeHistoryMutation

import { useMemo } from 'react'
import { SkeletonCard } from '../../../../components/common/Skeletons/Skeleton.styled'
import { useNationStatus } from '../../../../hooks/nation/useNationStatus'
import { getRhinoNationBridgeBanner } from '../../helpers/getRhinoNationBridgeBanner'
import { useGetHighlightsByIdQuery } from '../../services/highlights.api'
import { BridgeHighlightItem } from './BridgeHighlightItem'
import { BridgeHighlightsContainer } from './BridgeHighlights.styled'

const HighlightsSkeleton = () => (
  <>
    <SkeletonCard $height="100px" $width="352px" />
    <SkeletonCard $height="100px" $width="352px" />
  </>
)

export const BridgeHighlights = () => {
  const { status } = useNationStatus()
  const { data, isFetching } = useGetHighlightsByIdQuery('bridge-highlights')
  const rhinoNationBanner = useMemo(() => getRhinoNationBridgeBanner(status), [status])

  return (
    <BridgeHighlightsContainer id="bridge-highlights-container">
      <BridgeHighlightItem highlight={rhinoNationBanner} />

      {isFetching ? (
        <HighlightsSkeleton />
      ) : (
        data?.items?.map((item) => <BridgeHighlightItem key={item.id} highlight={item} />)
      )}
    </BridgeHighlightsContainer>
  )
}

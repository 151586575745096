import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { useMemo } from 'react'
import GemIcon from '../../../../../assets/icons/gem.svg?react'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { useAppSelector } from '../../../../../hooks'
import { useNationStatus } from '../../../../../hooks/nation/useNationStatus'
import { translate } from '../../../../../intl/i18n'
import { selectIsAuthenticated } from '../../../../../store/selectors/user.selectors'
import { useBannerGemsBalance } from '../../../hooks/useBannerGemsBalance'
import { useGemsBalance } from '../../../hooks/useGemsBalance'
import { NationUserStatus } from '../../../types/nation.types'
import { isFreeMintCampaignActive } from '../../../utils/isFreeMintCampaignActive'
import { Container } from './RhinoNationBalance.styled'
import { RhinoNationBalanceCount } from './RhinoNationBalanceCount'

const freeMintActive = isFreeMintCampaignActive()

export const RhinoNationBalance = ({ showRxp }: { showRxp?: boolean }) => {
  const isAuthenticationDone = useAppSelector(selectIsAuthenticated)
  const { status } = useNationStatus()
  const { currentData, isLoading } = useGemsBalance({ beforeFullAccess: true })
  const bannerData = useBannerGemsBalance()

  const getRxp = (rxp: number) => (showRxp ? rxp : undefined)

  const notJoinedTooltipText = useMemo(() => {
    const notSupportedStatus = status !== NationUserStatus.NotJoined
    const gemsNotLoaded = typeof bannerData.gems === 'string'
    const gemsLoadedNoBalance = typeof bannerData.gems === 'number' && bannerData.gems === 0

    if (notSupportedStatus || gemsNotLoaded || gemsLoadedNoBalance) {
      return ''
    }

    return freeMintActive ? translate('nation.mint_to_claim_gems_free') : translate('nation.mint_to_claim_gems')
  }, [status, bannerData])

  if (!isAuthenticationDone) {
    return null
  }

  if (status === NationUserStatus.NotJoined) {
    return (
      <RhinoNationBalanceCount
        gems={typeof bannerData.gems === 'string' ? 0 : bannerData.gems}
        isLoading={bannerData.isLoading}
        tooltipText={notJoinedTooltipText}
        rxp={getRxp(typeof bannerData.rxp === 'string' ? 0 : bannerData.rxp)}
      />
    )
  }

  if (status === NationUserStatus.MintedNFT) {
    return (
      <Container id="minted-nft-nav">
        <FlexContainer $gap="4px">
          <GemIcon />
          <Text size={TextSize.S}>{translate('nation.updating_rewards')}</Text>
        </FlexContainer>
      </Container>
    )
  }

  return (
    <RhinoNationBalanceCount
      isLoading={isLoading}
      gems={currentData ? currentData.balance : 0}
      rxp={getRxp(currentData ? currentData.balance + currentData.spent : 0)}
    />
  )
}

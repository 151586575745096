import { useCallback } from 'react'
import { doChainTx } from '../services/txService/doChainTx'
import { selectAddress, selectConnectedNetwork, selectIsAuthenticated } from '../store/selectors/user.selectors'
import type { ChainExecutionFuncFactory, ChainTxArgsExternal } from '../services/txService/txService.types'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import { useConfig } from './useConfig'
import { useWallet } from './useWallet'

type Props<T> = {
  executionArgs: T
  executionFuncFactory: ChainExecutionFuncFactory<T>
  destinationChainKey: string
} & ChainTxArgsExternal

export const useDoChainTx = <T,>({
  destinationChainKey,
  onInit,
  onError,
  onReceipt,
  onTxHash,
  executionFuncFactory,
  executionArgs,
  secondaryConnectedChainKey,
}: Props<T>) => {
  const { config } = useConfig()
  const wallet = useWallet()
  const dispatch = useAppDispatch()

  const signer = wallet.getEthersSigner()
  const address = useAppSelector(selectAddress)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const networkId = useAppSelector(selectConnectedNetwork)

  return useCallback(() => {
    if (!address || !isAuthenticated || !networkId || !signer || !config) {
      return
    }

    doChainTx({
      executionArgs,
      executionFuncFactory,
      config,
      chainTxArgs: {
        dispatch,
        address,
        signer,
        currentChainId: networkId,
        secondaryConnectedChainKey,
        destinationChainKey,
        onInit,
        onError,
        onTxHash,
        onReceipt,
      },
    }).catch(console.error)
  }, [
    dispatch,
    address,
    isAuthenticated,
    executionArgs,
    executionFuncFactory,
    secondaryConnectedChainKey,
    networkId,
    destinationChainKey,
    onInit,
    onError,
    onTxHash,
    onReceipt,
    signer,
    config,
  ])
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { type AllTrackersResponse, type TrackerResponse } from '@rhinofi/activity-trackers-api-spec'
import { heapEvents } from '../../../constants/heapEvents'
import { userflowEvents } from '../../../constants/userflowEvents'
import { envConfig } from '../../../env/envConfig'
import { reportMessageToSentry } from '../../../services/helperService/reportToSentry'
import { trackUserflowEvent } from '../../../services/apiService/trackUserflowEvent'
import type {
  ActivityTrackersBreakdownChain,
  ChainExplorerBaseTx,
  ChainExplorerTokenTransferTx,
  ChainExplorerTxs,
} from '../types/activityTrackers.types'
import { trackHeapEvent } from '../../../services/apiService'

const baseUrl = `${envConfig.tradingApi}/activity-trackers`

export const activityTrackersApi = createApi({
  reducerPath: 'activityTrackersApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: ['TrackerMetrics', 'TrackerMetricsAllChains', 'Transactions', 'BridgeVolumeUsd', 'TrackerMetrics'],
  keepUnusedDataFor: 5,
  endpoints: (builder) => ({
    getTrackerMetrics: builder.query<TrackerResponse, { chain: string; address: string; forceRefresh?: boolean }>({
      query: ({ chain, address, forceRefresh = true }) => {
        if (!envConfig.activityTrackers.includes(chain)) {
          throw new Error('Chain not supported')
        }

        return {
          url: `/trackers/${chain}`,
          params: {
            address,
            forceRefresh,
          },
        }
      },
      providesTags: ['TrackerMetrics'],
      transformResponse: (response: TrackerResponse, _, arg) => {
        trackHeapEvent(heapEvents.activityTracker, {
          address: arg.address,
          chain: arg.chain,
          ranking: response.ranking,
        })

        trackUserflowEvent(userflowEvents.activityTracker, {
          chain: arg.chain,
          score: response.transactionsCount,
        })

        return response
      },
      transformErrorResponse: (response, _, arg) => {
        reportMessageToSentry(`Failed to fetch tracker metrics`, {
          chain: arg.chain,
          address: arg.address,
        })
        return response
      },
    }),
    getAllTrackerMetrics: builder.query<AllTrackersResponse, { address: string }>({
      queryFn: async ({ address }) => {
        const promises = envConfig.activityTrackers.map(async (chain): Promise<TrackerResponse> => {
          const response = await fetch(`${baseUrl}/trackers/${chain}?address=${address}&forceRefresh=true`)
          const res = await response.json()
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
          return res as TrackerResponse
        })

        try {
          const res = await Promise.all(promises)

          const data = res.reduce<AllTrackersResponse>((acc, chainData, index) => {
            const trackerChain = envConfig.activityTrackers[index]

            if (!trackerChain) {
              throw new Error('Missing tracker chain')
            }

            acc[trackerChain] = chainData
            return acc
          }, {})

          trackHeapEvent(heapEvents.profileAllChains, {
            address,
          })

          return {
            data,
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch all chains data' } }
        }
      },
      providesTags: ['TrackerMetricsAllChains'],
    }),
    getTransactionsForAddress: builder.query<
      ChainExplorerTxs,
      { address: string; chain: ActivityTrackersBreakdownChain }
    >({
      queryFn: async ({ address, chain }) => {
        try {
          const explorerConfig = envConfig.ACTIVITY_TRACKER_CHAIN_EXPLORER_CONFIG_MAPPING[chain]

          if (!explorerConfig) {
            throw new Error('No explorer config')
          }

          const queryParts = [
            'module=account',
            'startblock=1',
            'endblock=99999999',
            'sort=desc',
            'page=1',
            'offset=1000',
            `address=${address}`,
          ]

          if (explorerConfig.apiKeys.length) {
            const randomIndex = Math.floor(Math.random() * explorerConfig.apiKeys.length)
            queryParts.push(`apiKey=${explorerConfig.apiKeys[randomIndex]}`)
          }

          const requests = [
            fetch(`${explorerConfig.url}?action=txlist&${queryParts.join('&')}`),
            fetch(`${explorerConfig.url}?action=tokentx&${queryParts.join('&')}`),
          ]

          const [txResponse, transfersResponse] = await Promise.all(requests)

          if (!txResponse) {
            throw new Error('No tx response')
          }

          if (!transfersResponse) {
            throw new Error('No transfersResponse response')
          }

          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
          const { result: transactions } = (await txResponse.json()) as { result: ChainExplorerBaseTx[] }
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Legacy
          const { result: tokenTransfers } = (await transfersResponse.json()) as {
            result: ChainExplorerTokenTransferTx[]
          }

          const transactionsWithTransfers = transactions
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Returned from api
            .filter(({ txreceipt_status }) => txreceipt_status === '1')
            .map((tx) => ({
              ...tx,
              tokenTransfers: tokenTransfers.filter((tokenTransfer) => tokenTransfer.hash === tx.hash),
            }))

          return {
            data: transactionsWithTransfers,
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch users transactions' } }
        }
      },
      providesTags: ['Transactions'],
    }),
  }),
})

export const { useGetTrackerMetricsQuery, useGetAllTrackerMetricsQuery, useGetTransactionsForAddressQuery } =
  activityTrackersApi

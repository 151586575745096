import type { MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { NationMissionModalReward } from '../NationMissionModalReward'

import { shareOnX } from '../../../../../../utils/shareOnX'
import { Copy } from '../../../../../../components/common/Helpers/Copy'
import { translate } from '../../../../../../intl/i18n'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { NationCTAAccessWrapper } from '../../../NationCTAAccessWrapper'
import { CopyContainer, CustomButton } from './NationMissionModalReferral.styled'
import { useReferralData } from '../../../../hooks/useReferralData'

type Props = {
  mission: MissionAPIDocument
}

export const NationMissionModalReferral = ({ mission }: Props) => {
  const { referralLink } = useReferralData()

  return (
    <FlexContainer $direction="column" $justifyContent="space-between" $alignItems="stretch" $gap="24px">
      <FlexContainer $gap="16px" $alignItems="stretch">
        <NationCTAAccessWrapper>
          <CopyContainer $flexGrow="1">
            <Copy
              text={referralLink}
              hideIcon
              showTop
              copiedText={translate('global.link_copied')}
              id="mission-copy-referral-link-wrapper"
            >
              <CustomButton variant={ButtonVariant.primary} id="mission-copy-referral-link">
                {translate('rhino_alpha_key.copy_link')}
              </CustomButton>
            </Copy>
          </CopyContainer>

          <CustomButton
            variant={ButtonVariant.secondary}
            id="mission-share-referral-twitter"
            onClick={() => {
              shareOnX(translate('nation.mission_referral_share_on_x', { '%link': referralLink }))
            }}
          >
            {translate('rhino_alpha_key.share_on_x')}
          </CustomButton>
        </NationCTAAccessWrapper>
      </FlexContainer>

      <NationMissionModalReward gems={mission.gemsAwarded} />
    </FlexContainer>
  )
}

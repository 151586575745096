/* eslint-disable @typescript-eslint/no-unsafe-member-access -- handles random errors */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- handles random errors */
import * as Sentry from '@sentry/react'

export const reportToSentry = (error: any) => {
  const errorMessage =
    error?.error?.details?.error?.message ||
    error?.error?.message ||
    error?.reason ||
    error?.message ||
    (typeof error === 'string' && error) ||
    'Unknown error'
  if (
    typeof errorMessage === 'string' &&
    (errorMessage.includes('denied') || errorMessage.includes('failed to fetch') || errorMessage.includes('MetaMask'))
  ) {
    return false
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- expected any
    Sentry.captureException(error instanceof Error ? error : new Error(errorMessage || ''), { extra: error })
    return true
  } catch {
    //
  }

  return false
}

export const reportMessageToSentry = (message: string, extra: Record<string, unknown>) => {
  try {
    Sentry.captureMessage(message, {
      level: 'error',
      extra,
    })
  } catch {
    //
  }
}

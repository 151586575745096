import { NetworkType } from '@rhinofi/dvf-shared-ui'
import { Struct } from 'effect'
import { useMemo, useCallback } from 'react'
import { selectBlockchainBalances } from '../../../store/selectors/portal.selectors'
import { useAppSelector } from '../../../hooks'
import { getTokenPrice } from '../../../services/helperService/getTokenPrice'
import { selectTokenPrices } from '../../../services/usdPricesApi'
import { selectAvailableChains, selectAvailableTokens } from '../../../store/apis/config.api'
import { selectBridgeChainsConfig } from '../../../store/selectors/bridgeConfig.selectors'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { getBridgeBadge, getBridgeCategory } from '../helpers'
import { isChainAvailableForToken } from '../helpers/isChainAvailableForToken'
import { selectBridge } from '../slices/bridge.slice'
import type { ChainWithBalance, TokenWithBalance } from '../types/bridge-widget.types'
import { selectSecondaryWalletChain } from '../../../store/selectors/secondaryWallet.selectors'
import { selectAddress } from '../../../store/selectors/user.selectors'
import { useIsCombinedBalancesEnabled } from './useIsCombinedBalancesEnabled'

type UseBridgeDataWithBalancesOutput = {
  availableChainsWithBalances: ChainWithBalance[]
  tokensListWithBalances: TokenWithBalance[]
}

export const useBridgeDataWithBalances = (): UseBridgeDataWithBalancesOutput => {
  const availableChains = useAppSelector(selectAvailableChains)
  const availableTokens = useAppSelector(selectAvailableTokens)
  const blockchainBalances = useAppSelector(selectBlockchainBalances)
  const tokenPrices = useAppSelector(selectTokenPrices)
  const bridgeState = useAppSelector(selectBridge)
  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)

  const isEvmWalletConnected = !!useAppSelector(selectAddress)
  const secondaryWalletChain = useAppSelector(selectSecondaryWalletChain)

  const isCombinedBalancesEnabled = useIsCombinedBalancesEnabled()

  const isWalletConnected = useCallback(
    (chain: string, networkType: NetworkType) => {
      if (networkType === NetworkType.Evm) {
        return isEvmWalletConnected
      }
      return secondaryWalletChain === chain
    },
    [isEvmWalletConnected, secondaryWalletChain],
  )

  const availableChainsWithBalances: ChainWithBalance[] = useMemo(
    () =>
      availableChains.map(({ chain, config }) => {
        const balance = blockchainBalances?.[chain]?.[bridgeState.token]?.balance ?? '0'
        const tokenUsdPrice = getTokenPrice(tokenPrices, bridgeState.token)
        const tokensToConsider = [...Object.keys(config.tokens)]
        const balanceUsd = isCombinedBalancesEnabled
          ? Object.values(blockchainBalances?.[chain] ?? {})
              .filter(({ token }) => tokensToConsider.includes(token))
              .map((tokenBalance) => {
                const localTokenUsdPrice = getTokenPrice(tokenPrices, tokenBalance.token)
                return SafeDecimal(tokenBalance.balance).times(localTokenUsdPrice)
              })
              .reduce((acc, curr) => acc.plus(curr), SafeDecimal(0))
              .toString()
          : SafeDecimal(balance).times(tokenUsdPrice).toString()
        const tokenBalances = tokensToConsider.flatMap((token) => {
          const balanceForToken = blockchainBalances?.[chain]?.[token]?.balance
          return balanceForToken && balanceForToken !== '0' ? [{ token, balance: balanceForToken }] : []
        })
        const category = getBridgeCategory(config)
        const badge = getBridgeBadge(config)
        const type = config.type === 'EVM' ? NetworkType.Evm : NetworkType.NonEvm

        const baseConfig = Struct.omit('category', 'badge', 'type')(config)

        return {
          ...baseConfig,
          type,
          chain,
          chainName: config.name,
          isAvailable: isChainAvailableForToken(config, bridgeState.token),
          balance,
          balanceUsd,
          isWalletConnected: isWalletConnected(chain, type),
          tokenBalances: isCombinedBalancesEnabled ? tokenBalances : [],
          ...(config.category && { category }),
          ...(config.badge && { badge }),
        }
      }),
    [availableChains, blockchainBalances, bridgeState.token, tokenPrices, isWalletConnected, isCombinedBalancesEnabled],
  )

  const tokensListWithBalances: TokenWithBalance[] = useMemo(
    () =>
      availableTokens.map((token) => {
        const tokenUsdPrice = getTokenPrice(tokenPrices, token)
        const balance = blockchainBalances?.[bridgeState.chainIn]?.[token]?.balance || '0'
        const balanceUsd = SafeDecimal(balance).times(tokenUsdPrice).toString()
        return {
          token,
          chain: bridgeState.chainIn,
          chainName: chainInConfig?.name ?? '',
          balanceToken: balance,
          balanceUsd,
        }
      }),
    [availableTokens, blockchainBalances, bridgeState.chainIn, tokenPrices, chainInConfig?.name],
  )

  return { availableChainsWithBalances, tokensListWithBalances }
}

import styled from 'styled-components'
import { Title, TitleSize } from '@rhinofi/dvf-shared-ui'
import type { PerkAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import GemIcon from '../../../../../../assets/icons/gem.svg?react'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'

type Props = {
  perk: PerkAPIDocument
}

export const NationPerkModalCardGemPrice = ({ perk }: Props) => (
  <FlexContainer $direction="column" $gap="8px">
    <ImageContainer>
      <Image src={perk.imageUrl} />
    </ImageContainer>

    <FlexContainer $gap="8px">
      <Title id="perk-modal-card-gems-price" size={TitleSize.XS}>
        -{perk.gemsPrice}
      </Title>
      <GemIcon width={50} height={50} />
    </FlexContainer>
  </FlexContainer>
)

const ImageContainer = styled.div`
  background: ${({ theme }) => theme.secondary20};
  max-width: 356px;
  aspect-ratio: 1.648;
  overflow: hidden;
  border-radius: 8px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

import type React from 'react'
import { Navigate, Route, useParams } from 'react-router-dom'
import type { deployContractsSubRoutes } from '../../router'
import { makeRouteTo, publicRoutes } from '../../router'
import { useSearchParams } from '../../hooks/useSearchParams'

// Static redirects
const staticRedirects = [
  {
    from: '/',
    to: {
      pathname: publicRoutes.bridge,
      search: window.location.search,
    },
  },
  {
    from: '*',
    to: publicRoutes.bridge,
  },
]

type ChainParamRedirectProps = {
  makeRouteFunc: (chain: string) => string
}

const ChainParamRedirect: React.FC<ChainParamRedirectProps> = ({ makeRouteFunc }) => {
  const { chain } = useParams<{ chain: string }>()
  if (!chain) {
    return <Navigate to="/" />
  }
  return <Navigate to={makeRouteFunc(chain)} />
}

type QueryParamRedirectProps = {
  makeRouteFunc: (params: {
    subRoute?: keyof typeof deployContractsSubRoutes | undefined
    chain?: string | undefined
  }) => string
  subRoute?: keyof typeof deployContractsSubRoutes | undefined
  specificQueryParams?: string[] | undefined
}

const QueryParamRedirect: React.FC<QueryParamRedirectProps> = ({
  makeRouteFunc,
  subRoute,
  specificQueryParams = [],
}) => {
  const queryParams = useParams<{ chain: string }>()
  const { searchParams } = useSearchParams()

  const chain = queryParams?.chain
  const contractAddress = searchParams.get('contractAddress')

  if (!chain || (specificQueryParams?.includes('contractAddress') && !contractAddress)) {
    return <Navigate to="/" />
  }

  return (
    <Navigate
      to={makeRouteFunc({ subRoute, chain }) + (contractAddress ? `?contractAddress=${contractAddress}` : '')}
    />
  )
}

// Dynamic redirects configuration
const dynamicRedirects = [
  {
    from: publicRoutes.deployContractsOld,
    element: <QueryParamRedirect makeRouteFunc={makeRouteTo.chainDeployContracts} />,
  },
  {
    from: publicRoutes.deployContractsFriendOld,
    element: (
      <QueryParamRedirect
        makeRouteFunc={makeRouteTo.chainDeployContracts}
        subRoute="friend"
        specificQueryParams={['contractAddress']}
      />
    ),
  },
  {
    from: publicRoutes.deployContractsInteractionsOld,
    element: <QueryParamRedirect makeRouteFunc={makeRouteTo.chainDeployContracts} subRoute="interactions" />,
  },
  {
    from: publicRoutes.deployContractsYourContractsOld,
    element: <QueryParamRedirect makeRouteFunc={makeRouteTo.chainDeployContracts} subRoute="yourContracts" />,
  },
  { from: publicRoutes.nftMinterOld, element: <QueryParamRedirect makeRouteFunc={makeRouteTo.nftMinter} /> },
  {
    from: publicRoutes.analyticsOld,
    element: <ChainParamRedirect makeRouteFunc={makeRouteTo.analytics} />,
  },
]

export const AppRedirects = () => (
  <>
    {staticRedirects.map(({ from, to }) => (
      <Route key={from} path={from} element={<Navigate to={to} />} />
    ))}
    {dynamicRedirects.map(({ from, element }) => (
      <Route key={from} path={from} element={element} />
    ))}
  </>
)

import { isEmpty } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'

import { envConfig } from '../../../env/envConfig'
import { useAppSelector } from '../../../hooks/useAppSelector'
import {
  useCreateReferralIdMutation,
  useGetCompletedReferralsCountQuery,
  useGetReferralConfigQuery,
  useGetReferralQuery,
  useGetReferralUserFreeBridgesQuery,
  useGetReferralUserQuery,
} from '../../../services/referralApi'
import { selectAddress, selectIsAuthenticated } from '../../../store/selectors/user.selectors'

const { appURL } = envConfig

export const useReferralData = () => {
  const address = useAppSelector(selectAddress)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const skip = !address || !isAuthenticated
  const { data: config } = useGetReferralConfigQuery()
  const { data: referralData } = useGetReferralQuery(address || '', { skip })
  const {
    data: userData,
    isUninitialized: isUninitializedUser,
    refetch: refetchUser,
  } = useGetReferralUserQuery(address || '', {
    skip,
    refetchOnMountOrArgChange: true,
  })
  const {
    data: referralFreeBridges,
    isUninitialized: isUninitializedFreeBridges,
    refetch: refetchFreeBridges,
  } = useGetReferralUserFreeBridgesQuery(address || '', {
    skip,
    refetchOnMountOrArgChange: true,
  })
  const { data: completedReferralsCount } = useGetCompletedReferralsCountQuery(address || '', { skip })
  const [createReferralIdMutation] = useCreateReferralIdMutation()

  const hasRefereeFreeBridge = useMemo(
    () => (referralFreeBridges ? referralFreeBridges.some((bridge) => bridge._id.includes('referee')) : false),
    [referralFreeBridges],
  )

  const hasReferrerFreeBridge = useMemo(
    () => (referralFreeBridges ? referralFreeBridges.some((bridge) => bridge._id.includes('referrer')) : false),
    [referralFreeBridges],
  )

  const hasCompletedBridge = useMemo(
    () => (config && userData ? userData.bridgeCount >= config.minBridgeCount : false),
    [userData, config],
  )
  const hasVerifiedEmail = Boolean(userData?.emailVerified)

  const referralLink = useMemo(
    () => (userData?.referralId ? `https://${appURL}?ref=${userData.referralId}` : ''),
    [userData],
  )

  const onCompleteEmailVerification = useCallback(() => {
    if (!isUninitializedUser) {
      void refetchUser()
    }
    if (!isUninitializedFreeBridges) {
      void refetchFreeBridges()
    }
  }, [isUninitializedUser, refetchUser, isUninitializedFreeBridges, refetchFreeBridges])

  const createReferralId = useCallback(() => {
    if (!address) {
      console.error('Cannot create referral id without address')
      return
    }
    void createReferralIdMutation({ user: address })
  }, [address, createReferralIdMutation])

  useEffect(() => {
    if (!isEmpty(userData) && !userData.referralId) {
      createReferralId()
    }
  }, [userData, createReferralId])

  return {
    config,
    referralData,
    userData,
    referralFreeBridges,
    hasCompletedBridge,
    hasVerifiedEmail,
    hasRefereeFreeBridge,
    hasReferrerFreeBridge,
    referralId: userData?.referralId,
    referralLink,
    completedReferralsCount,
    onCompleteEmailVerification,
    createReferralId,
  }
}

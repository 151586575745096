import { ReduxState } from '@rhinofi/effect-rtk'
import { Effect, Option, pipe, Schema } from 'effect'
import { getEnvConfig } from '../../env/envConfig'
import { AuthDataSchema } from './auth.types'

export const envConfig = getEnvConfig()

const AuthDataInRootState = Schema.Struct({
  user: Schema.Struct({
    auth: Schema.Struct({
      data: Schema.OptionFromNullOr(Schema.typeSchema(AuthDataSchema)),
    }),
  }),
})

const decodeState = Schema.decodeUnknown(AuthDataInRootState)

export const authDataFromRootState = (rootState: unknown) =>
  pipe(
    decodeState(rootState),
    Effect.map((state) => state.user.auth.data),
    Effect.catchTag('ParseError', (error) =>
      Effect.zipRight(Effect.logError('Mallformed ReduxState', error), Effect.die(error)),
    ),
  )

// This could be moved to ReduxState service
const getReduxStateOrDie = pipe(
  Effect.serviceOptional(ReduxState),
  Effect.orDieWith(() => new Error('ReduxState was not found in Effect Context')),
)

export const getJWTTokenFromStateEffect = pipe(
  getReduxStateOrDie,
  Effect.flatMap(authDataFromRootState),
  Effect.map(Option.map((data) => data.jwt)),
)

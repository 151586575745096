import type { ReactElement, ReactText } from 'react'
import { useLayoutEffect, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import styled from 'styled-components'
import { gentleFigma } from '../constants/springs'

const AnimationDiv = styled(animated.div)`
  position: absolute;
`
type MovePreviousToTopAnimationProps = {
  maxOffset?: number
  className?: string
  showChildrenAsPrevious?: boolean
} & (
  | {
      version: number | string
      children: ReactElement
    }
  | {
      children: ReactText
    }
)

export const MovePreviousToTopAnimation = ({
  className,
  maxOffset = 60,
  children,
  showChildrenAsPrevious = false,
  ...props
}: MovePreviousToTopAnimationProps) => {
  const version = 'version' in props ? props.version : null

  const previousChildrenRef = useRef(children)
  const previousVersionRef = useRef(version)
  const [animate, setAnimate] = useState(false)

  useLayoutEffect(() => {
    // if version was given, react only to version change
    if (version === null ? previousChildrenRef.current !== children : version !== previousVersionRef.current) {
      setAnimate(true)
    }
  }, [children, version])

  const { opacity, yTop, yBottom } = useSpring({
    config: gentleFigma,
    pause: !animate,
    reset: !animate,
    from: {
      opacity: 1,
      yTop: 0,
      yBottom: maxOffset,
    },
    to: {
      opacity: 0,
      yTop: -maxOffset,
      yBottom: 0,
    },
    onRest: () => {
      previousChildrenRef.current = children
      previousVersionRef.current = version
      setAnimate(false)
    },
  })

  return (
    <>
      <AnimationDiv
        className={`${className || ''} previous-child`}
        style={{
          opacity: opacity,
          y: yTop,
        }}
      >
        {showChildrenAsPrevious ? children : previousChildrenRef.current}
      </AnimationDiv>
      <AnimationDiv
        className={`${className || ''} current-child`}
        style={{
          y: yBottom,
          opacity: opacity.to((vaule) => 1 - vaule),
        }}
      >
        {children}
      </AnimationDiv>
    </>
  )
}

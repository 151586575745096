import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { useGetGemsBalanceQuery } from '../services/nation.api'
import { useHasUserFullNationAccess } from './useHasUserFullNationAccess'

type Props = {
  beforeFullAccess?: boolean
}

export const useGemsBalance = (props?: Props) => {
  const isAuthenticationDone = useAppSelector(selectIsAuthenticated)
  const hasFullAccess = useHasUserFullNationAccess()

  const skip = props?.beforeFullAccess ? !isAuthenticationDone : !hasFullAccess

  const { data, currentData, isLoading, error } = useGetGemsBalanceQuery(undefined, { skip })

  return {
    data,
    currentData,
    isLoading,
    error,
  }
}

import type { MouseEvent } from 'react'
import { useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { useAutoClose } from '@rhinofi/dvf-shared-ui/lib/hooks/useAutoClose'
import { CSSTransition } from 'react-transition-group'
import { clearModal } from '../../store/actions/modalActions/clearModal'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { DefaultBellIndicator } from './DefaultBellIndicator'
import { IconWrapper, Tab, TabBar, TrayTab, Wrapper } from './MobileNavigation.styled'
import { isDirectLink } from './MobileNavigation.types'
import { mobileNavigationLinks, showInvestAlertLocalStorageKey } from './MobileNavigationLinks'
import { MobileSupportChat } from './MobileSupportChat'
import { MoreTray } from './Trays/MoreTray'

export const MobileNavigation = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [showTray, setShowTray] = useState<boolean>(false)
  const [showChat, setShowChat] = useState(false)

  const tray = useRef<HTMLDivElement>(null)

  useAutoClose({
    setIsOpen: setShowTray,
    elementToClose: tray,
  })

  const triggerTray = (event: MouseEvent<HTMLDivElement>, trayShowStatus: boolean, showAlert: boolean) => {
    const didShowInvestAlert = localStorage.getItem(showInvestAlertLocalStorageKey)
    event.stopPropagation()
    if (showAlert && !didShowInvestAlert) {
      localStorage.setItem(showInvestAlertLocalStorageKey, 'true')
    }
    setShowTray(trayShowStatus)
  }

  const activeTabIndex = mobileNavigationLinks.findIndex((link) => 'path' in link && pathname === link.path)
  const animationRef = useRef<HTMLDivElement>(null)
  return (
    <Wrapper ref={tray}>
      <CSSTransition
        in={showTray}
        timeout={300}
        appear={showTray}
        classNames="mobile-transition"
        unmountOnExit
        nodeRef={animationRef}
      >
        <MoreTray closeTray={() => setShowTray(false)} showChat={() => setShowChat(true)} ref={animationRef} />
      </CSSTransition>
      <TabBar $isTrayOpen={showTray}>
        {mobileNavigationLinks.map((link, index) => {
          if (isDirectLink(link)) {
            const { id, icon, title, path } = link
            return (
              <Tab
                key={id}
                $isActive={activeTabIndex === index}
                onClick={() => {
                  clearModal(dispatch)()
                  setShowTray(false)
                  navigate(path)
                }}
                className={`${pathname === path ? 'active' : ''}`}
              >
                <IconWrapper>{icon}</IconWrapper>
                <Text className="header-tab-title" size={TextSize.XS} bold>
                  {title}
                </Text>
              </Tab>
            )
          }
          const { id, icon, title, showAlert } = link
          return (
            <TrayTab
              key={id}
              $isActive={activeTabIndex > 1}
              onClick={(event: MouseEvent<HTMLDivElement>) => triggerTray(event, !showTray, showAlert || false)}
            >
              <IconWrapper>
                {icon}
                {showAlert && <DefaultBellIndicator />}
              </IconWrapper>
              <Text className="header-tab-title" size={TextSize.XS} bold>
                {title}
              </Text>
            </TrayTab>
          )
        })}
      </TabBar>
      <MobileSupportChat showChat={showChat} setShowChat={setShowChat} />
    </Wrapper>
  )
}

import { Button, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { Form, Formik } from 'formik'
import { memo } from 'react'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../intl/i18n'
import { NationCTAAccessWrapper } from '../../NationCTAAccessWrapper'
import { NationMissionModalReward } from './NationMissionModalReward'
import { getRTKErrMessage } from '../../../../../services/helperService'
import { useDailyCheckin } from '../../../hooks/useDailyCheckin'

type Props = {
  gems: number
}

export const NationMissionModalDailyCheckin = memo(({ gems }: Props) => {
  const { postDailyCheckin, error, pending } = useDailyCheckin()

  return (
    <NationCTAAccessWrapper>
      <Formik initialValues={{}} onSubmit={() => postDailyCheckin({})}>
        <Form>
          <FlexContainer $gap="16px" $alignItems="stretch" $direction="column">
            <NationMissionModalReward gems={gems} />

            <div>
              <Button fullWidth type="submit" id="nation-mission-daily-checkin" disabled={pending}>
                {translate(pending ? 'nation.updating_rewards' : 'nation.daily_checkin_btn_label')}
              </Button>
            </div>
          </FlexContainer>

          {error && (
            <Text color="accentRed" size={TextSize.XS}>
              {getRTKErrMessage(error)}
            </Text>
          )}
        </Form>
      </Formik>
    </NationCTAAccessWrapper>
  )
})

import type { NavigationLinkArray } from './NavigationDesktop.types'
import { translate } from '../../intl/i18n'
import { makeRouteTo, publicRoutes } from '../../router'
import gemIcon from '../../assets/icons/gem.svg'
import { DEFAULT_FEATURE_CHAIN } from '../../pages/Chain/constants/activityTrackers.constants'
import { isFeatureEnabled } from '../../services/helperService/isFeatureEnabled'
import { F_FLAGS_TYPES } from '../../constants/types'
import { getDefaultTokenDistribution } from '../../pages/TokenDistribution/utils/getDefaultTokenDistribution'

export const topNavigationHeight = '72px'

// TODO DOPE-424 - Add campaigns
// const enabledMilestoneCampaigns = getMilestoneCampaigns()
// if migrating predictions
//
// const isPredictionsEnabled = isFeatureEnabled(F_FLAGS_TYPES.PREDICTIONS)
const isMemecoinTrackerEnabled = isFeatureEnabled(F_FLAGS_TYPES.MEMECOIN_TRACKER)
const isTokenDistributionEnabled = isFeatureEnabled(F_FLAGS_TYPES.TOKEN_DISTRIBUTION)

export const navigationLinks: NavigationLinkArray = [
  {
    icon: 'rainbow',
    title: translate('bridge.bridge'),
    to: publicRoutes.bridge,
  },
  {
    icon: gemIcon,
    title: translate('helpers.rhino_nation'),
    to: publicRoutes.nation,
    id: 'nav-nation',
  },
  {
    icon: 'images',
    title: translate('helpers.mint_interact'),
    subLinks: [
      {
        title: translate('realm.analytics'),
        to: makeRouteTo.analytics(DEFAULT_FEATURE_CHAIN),
        nestedPath: true,
      },
      {
        title: translate('helpers.mint_nfts'),
        to: makeRouteTo.nftMinter({ chain: DEFAULT_FEATURE_CHAIN }),
        nestedPath: true,
      },
      {
        title: translate('helpers.deploy_and_interact'),
        to: makeRouteTo.chainDeployContracts({ chain: DEFAULT_FEATURE_CHAIN }),
        nestedPath: true,
      },
      ...(isMemecoinTrackerEnabled
        ? [
            {
              title: translate('memecoin_tracker.title'),
              to: publicRoutes.memecoinTracker,
              nestedPath: true,
            },
          ]
        : []),
      ...(isTokenDistributionEnabled
        ? [
            {
              title: translate('token_distribution.menu_title'),
              to: makeRouteTo.tokenDistribution(getDefaultTokenDistribution()),
              nestedPath: true,
            },
          ]
        : []),
      // {
      //   title: translate('airdrop.campaigns'),
      //   to: makeRouteTo.campaigns(enabledMilestoneCampaigns[0]),
      //   nestedPath: true,
      // },
      // ...(isPredictionsEnabled
      //   ? [
      //       {
      //         title: translate('helpers.predictions'),
      //         to: publicRoutes.predictions,
      //         nestedPath: true,
      //       },
      //     ]
      //   : []),
    ],
  },
  // {
  //   icon: 'grid',
  //   title: translate('helpers.platform'),
  //   subLinks: [
  //     {
  //       title: translate('helpers.history'),
  //       to: makeRouteTo.history(),
  //     },
  //   ],
  // },
]

import styled, { css, keyframes, useTheme } from 'styled-components'
import { to, useSpring, config, animated } from '@react-spring/web'
import logo from '../../assets/icons/rhino-solo.svg'
import { MovePreviousToTopAnimation } from '../MovePreviousToTopAnimation'
import { Checkmark } from './assets/Checkmark'
import { ErrorCross } from './assets/ErrorCross'

// eslint-disable-next-line react-refresh/only-export-components -- Legacy
export enum SpinningType {
  none = 'none',
  linear = 'linear',
  easeInOut = 'ease-in-out',
}

type ProgressCircleProps = {
  fillPercent: number
  isDone: boolean
  isError?: boolean
  showRhinoLogo?: boolean
  customFillColor?: string
  size?: number
  circleWidth?: number
  spinningType?: SpinningType
  gradientFill?: boolean
}

const defaultSize = 200
const defaultCircleWidth = 20

export const ProgressCircle = ({
  fillPercent,
  isDone,
  isError = false,
  showRhinoLogo = true,
  customFillColor,
  size = defaultSize,
  circleWidth = defaultCircleWidth,
  spinningType = SpinningType.none,
  gradientFill = false,
}: ProgressCircleProps) => {
  const theme = useTheme()
  const { degreesFilled } = useSpring({
    config: config.default,
    degreesFilled: fillPercent * 3.6,
  })

  const { fillColor } = useSpring({
    config: config.default,
    fillColor: isError ? theme.errorMain : isDone ? theme.successMain : customFillColor || theme.accentBlue,
  })

  const backgroundImage = to([degreesFilled, fillColor], (degree, fill) => {
    if (typeof degree === 'string') {
      throw new Error('Degrees are supposed to be a number')
    }

    if (gradientFill) {
      return `conic-gradient(from 180deg at 50% 50%, ${fill} 0deg, ${theme.elevationL1} 360deg),
      linear-gradient(0deg, ${theme.elevationL1}, ${theme.elevationL1})`
    }
    if (degree <= 180) {
      return `linear-gradient(${-90 + degree}deg, ${theme.elevationL2} 50%, transparent 50%),
      linear-gradient(-90deg, ${fill} 50%, transparent 50%)`
    }
    return `linear-gradient(-90deg, ${fill} 50%, transparent 50%),
    linear-gradient(${-270 + degree}deg, ${fill} 50%, transparent 50%)`
  })

  return (
    <Container
      $size={size}
      $spinningType={spinningType}
      style={{
        backgroundImage,
      }}
    >
      <Middle $circleWidth={circleWidth}>
        <MovePreviousToTopAnimation version={isError ? 3 : isDone ? 1 : 2} maxOffset={60}>
          <>
            {isError && <ErrorCross />}
            {!isError && isDone && <Checkmark customFill={customFillColor} />}
            {!isError && !isDone && showRhinoLogo && (
              <ProgressLogo alt="rhino-fi-logo" className="progress-logo" src={logo} />
            )}
          </>
        </MovePreviousToTopAnimation>
      </Middle>
    </Container>
  )
}

const rotation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled(animated.div)<{ $size: number; $spinningType: SpinningType }>`
  min-width: ${({ $size }) => $size}px;
  min-height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ $size }) => $size / 2}px;
  position: relative;
  background: ${({ theme }) => theme.elevationL2};

  ${({ $spinningType }) => {
    if ($spinningType !== SpinningType.none) {
      return css`
        animation: ${rotation} ${$spinningType} 2s infinite;
        animation-direction: reverse;
        .progress-logo {
          opacity: 0;
        }
      `
    }

    return ''
  }}
`

const Middle = styled.div<{ $circleWidth: number }>`
  top: ${({ $circleWidth }) => $circleWidth}px;
  bottom: ${({ $circleWidth }) => $circleWidth}px;
  left: ${({ $circleWidth }) => $circleWidth}px;
  right: ${({ $circleWidth }) => $circleWidth}px;
  border-radius: ${({ $circleWidth }) => 100 - $circleWidth}px;
  position: absolute;
  background: ${({ theme }) => theme.elevationL1};
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProgressLogo = styled.img`
  height: 49px;
  transition: opacity 0.3s ease-in-out;
`

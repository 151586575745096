import type { PublicQuoteQuerySchema } from '@rhinofi/bridge-api-spec'
import { useAppSelector } from '../../../hooks'
import { useGetPublicQuoteQuery } from '../../../store/apis/bridge.api'
import { selectBridge } from '../slices/bridge.slice'
import { useDebouncedValues } from './useDebouncedValues'
import { isBelowMaxDecimals } from '../../../utils/isBelowMaxDecimals'
import { unsafeFromString } from 'effect/BigDecimal'

type UsePublicQuoteInput = {
  mode?: PublicQuoteQuerySchema['mode']
  amount?: string
  gasBoostAmountNative?: string | undefined
  skip?: boolean
}

export const usePublicQuote = ({
  mode = 'receive',
  amount,
  gasBoostAmountNative,
  skip = false,
}: UsePublicQuoteInput = {}) => {
  const bridgeState = useAppSelector(selectBridge)

  const quoteAmount = amount !== undefined ? amount : bridgeState.amount

  const debouncedValues = useDebouncedValues({
    amount: quoteAmount,
  })

  const {
    data: publicQuote,
    isError,
    error,
  } = useGetPublicQuoteQuery(
    {
      urlParams: {
        amount: unsafeFromString(debouncedValues.amount),
        chainIn: bridgeState.chainIn,
        chainOut: bridgeState.chainOut,
        token: bridgeState.token,
        mode,
        ...(gasBoostAmountNative && { amountNative: unsafeFromString(gasBoostAmountNative) }),
      },
    },
    {
      skip:
        skip ||
        !quoteAmount ||
        quoteAmount.toString() === '0' ||
        !bridgeState.chainIn ||
        !bridgeState.chainOut ||
        !bridgeState.token ||
        isBelowMaxDecimals(unsafeFromString(quoteAmount), 8),
    },
  )

  return { quote: publicQuote, isError, error }
}

import { Schema } from 'effect'
import { LogFeature, makeLog } from '../../utils/makeLog'
import type { AuthDataType } from './auth.types'
import { AuthDataSchema } from './auth.types'
import { authKey } from './authKey'

const log = makeLog(LogFeature.AUTH)

export const getAuthData = (address: string): AuthDataType | null => {
  const item = localStorage.getItem(authKey(address))
  if (!item) {
    return null
  }

  const parsed = JSON.parse(item)

  try {
    const decoded = Schema.decodeUnknownSync(AuthDataSchema)(parsed)
    log('Loading auth from local storage', decoded)
    return decoded
  } catch {
    return null
  }
}

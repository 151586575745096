import type { FC, PropsWithChildren } from 'react'
import { useNationStatus } from '../../../hooks/nation/useNationStatus'
import { useAppSelector } from '../../../hooks/useAppSelector'

import { ButtonLink } from '../../../components/common/Helpers/LinkHelpers'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { translate } from '../../../intl/i18n'
import { makeRouteTo } from '../../../router'
import { clearModal } from '../../../store/actions/modalActions/clearModal'
import { selectIsWalletConnected } from '../../../store/selectors/user.selectors'
import { NationUserStatus } from '../types/nation.types'
import { isFreeMintCampaignActive } from '../utils/isFreeMintCampaignActive'
import { NationConnectWalletButton } from './NationConnectWalletButton'

type Props = {
  fullWidth?: boolean
}

const freeMintActive = isFreeMintCampaignActive()

export const NationCTAAccessWrapper: FC<PropsWithChildren<Props>> = ({ children, fullWidth = true }) => {
  const dispatch = useAppDispatch()
  const { status } = useNationStatus()
  const isWalletConnected = useAppSelector(selectIsWalletConnected)

  const closeModal = () => clearModal(dispatch)()

  if (!isWalletConnected) {
    return <NationConnectWalletButton id="cta-connect-wallet" fullWidth={fullWidth} />
  }

  if (status !== NationUserStatus.Joined) {
    return (
      <ButtonLink
        to={makeRouteTo.nation({ subRoute: 'access' })}
        onClick={closeModal}
        fullWidth={fullWidth}
        id="cta-mint-passport"
      >
        {freeMintActive ? translate('nation.unlock_mint_rhino_passport_free') : translate('gated.mint_rhino_passport')}
      </ButtonLink>
    )
  }

  return children
}

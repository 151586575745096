import { showModal } from '../store/actions/modalActions/showModal'
import { modalKeys } from '../constants/modalKeys'
import { connectSecondaryWallet } from '../store/slices/secondaryWallet.slice'
import { isNonEVMChain } from '../services/helperService/isNonEVMChain'
import { useAppDispatch } from './useAppDispatch'

export const useConnectWallet = () => {
  const dispatch = useAppDispatch()
  return (chain: string) => {
    if (isNonEVMChain(chain)) {
      void dispatch(connectSecondaryWallet({ chain }))
    } else {
      showModal(dispatch)(modalKeys.connectWallet)
    }
  }
}

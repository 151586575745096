import { combineReducers } from '@reduxjs/toolkit'

import { bridgeApi } from './apis/bridge.api'
import { bridgeSlice } from '../pages/Bridge/slices/bridge.slice'
import { bridgeProgressSlice } from '../pages/Bridge/slices/bridgeProgress.slice'
import { userVerificationSlice } from './slices/userVerification.slice'
import { modalSlice } from './slices/modal.slice'
import { portalSlice } from './slices/portal.slice'
import { secondaryWalletSlice } from './slices/secondaryWallet.slice'
import { tokenPricesSlice } from './slices/tokenPrices.slice'
import { userSlice } from './slices/user.slice'
import { usdPricesApi } from '../services/usdPricesApi'
import { userApi } from '../services/userApi'
import { userVerificationApi } from '../services/userVerificationApi'
import { authApi } from './apis/auth.api'
import { configApi } from './apis/config.api'
import { highlightsApi } from '../pages/Bridge/services/highlights.api'
import { settingsSlice } from './slices/settings/settings.slice'
import { nationSlice } from '../pages/Nation/slices/nation.slice'
import { nationApi } from '../pages/Nation/services/nation.api'
import { gatedFeaturesApi } from '../pages/Nation/services/gatedFeatures.api'
import { mintedNFTsApi } from '../pages/Chain/services/mintedNFTsApi'
import { nationWidgetSlice } from '../pages/Nation/slices/nationWidget.slice'
import { referralApi } from '../services/referralApi'
import { contractInteractionsSlice } from '../pages/Chain/slices/contractsInteractionsSlice'
import { contractsInteractionsApi } from '../pages/Chain/services/contractsInteractions.api'
import { activityTrackersApi } from '../pages/Chain/services/activityTrackers.api'
import { tokenDistributionApi } from '../pages/TokenDistribution/services/tokenDistribution.api'

export const rootReducer = combineReducers({
  // Slice Reducers
  [bridgeSlice.name]: bridgeSlice.reducer,
  [contractInteractionsSlice.name]: contractInteractionsSlice.reducer,
  [bridgeProgressSlice.name]: bridgeProgressSlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
  [tokenPricesSlice.name]: tokenPricesSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [userVerificationSlice.name]: userVerificationSlice.reducer,
  [portalSlice.name]: portalSlice.reducer,
  [secondaryWalletSlice.name]: secondaryWalletSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [nationSlice.name]: nationSlice.reducer,
  [nationWidgetSlice.name]: nationWidgetSlice.reducer,
  // Api Reducers -- when adding here also add to ./configureStore.ts
  [authApi.reducerPath]: authApi.reducer,
  [bridgeApi.reducerPath]: bridgeApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [usdPricesApi.reducerPath]: usdPricesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [userVerificationApi.reducerPath]: userVerificationApi.reducer,
  [highlightsApi.reducerPath]: highlightsApi.reducer,
  [nationApi.reducerPath]: nationApi.reducer,
  [gatedFeaturesApi.reducerPath]: gatedFeaturesApi.reducer,
  [mintedNFTsApi.reducerPath]: mintedNFTsApi.reducer,
  [referralApi.reducerPath]: referralApi.reducer,
  [contractsInteractionsApi.reducerPath]: contractsInteractionsApi.reducer,
  [activityTrackersApi.reducerPath]: activityTrackersApi.reducer,
  [tokenDistributionApi.reducerPath]: tokenDistributionApi.reducer,
})

export type RootReducerState = ReturnType<typeof rootReducer>

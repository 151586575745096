import type { ConnectedQuotePayloadSchema } from '@rhinofi/bridge-api-spec'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import { BigDecimal } from 'effect'
import { heapEvents, heapStatuses } from '../../../constants/heapEvents'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useWallet } from '../../../hooks/useWallet'
import { trackHeapEvent } from '../../../services/apiService'
import { isNonEVMChain } from '../../../services/helperService/isNonEVMChain'
import { isValidWalletError } from '../../../services/helperService/isValidWalletError'
import { useCreateAffiliateIdMutation } from '../../../services/referralApi'
import { requireChain } from '../../../store/actions/user.actions'
import {
  useCommitUserQuoteMutation,
  useGenUserQuoteMutation,
  usePatchBridgeHistoryMutation,
} from '../../../store/apis/bridge.api'
import {
  selectBridgeChainsConfig,
  selectBridgeChainsTokenConfig,
} from '../../../store/selectors/bridgeConfig.selectors'
import { selectSecondaryWalletAddress } from '../../../store/selectors/secondaryWallet.selectors'
import { selectWallet } from '../../../store/selectors/user.selectors'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { callBridgeContract } from '../services/bridge.contract'
import { trackBridgeEvent } from '../services/trackBridgeEvent'
import { selectBridge } from '../slices/bridge.slice'
import { BridgeProgressStep } from '../slices/bridgeProgress.slice'
import { BridgeConfirmationScreen } from '../types/bridge-widget.types'
import { useBridgeProgress } from './useBridgeProgress'
import { useIsGasBoostRedesignActive } from './useIsGasBoostRedesignActive'

const log = makeLog(LogFeature.BRIDGE_WIDGET)

type UseBridgeInput = {
  mode?: ConnectedQuotePayloadSchema['mode']
  gasBoostAmountNative?: string
  triggerConfirmationScreen: (screen: BridgeConfirmationScreen) => Promise<boolean>
}

type UseBridgeOutput = {
  handleBridge: () => Promise<void>
}

export const useBridge = ({
  mode = 'receive',
  gasBoostAmountNative,
  triggerConfirmationScreen,
}: UseBridgeInput): UseBridgeOutput => {
  const dispatch = useAppDispatch()
  const bridgeState = useAppSelector(selectBridge)
  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)
  const { chainInTokenConfig } = useAppSelector(selectBridgeChainsTokenConfig)
  const walletData = useAppSelector(selectWallet)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)
  const isGasBoostRedesignActive = useIsGasBoostRedesignActive()

  const { setProgress, resetProgressState } = useBridgeProgress()
  const { ethersSigner } = useWallet()

  const [genUserQuoteMutation] = useGenUserQuoteMutation()
  const [commitUserQuoteMutation] = useCommitUserQuoteMutation()
  const [patchBridgeHistoryMutation] = usePatchBridgeHistoryMutation()
  const [createAffiliateId] = useCreateAffiliateIdMutation()

  const handleBridge = async () => {
    // eslint-disable-next-line functional/no-let -- needed for catch
    let bridgeId: string | undefined
    try {
      if (!bridgeState.amount || !bridgeState.token || !bridgeState.chainIn || !bridgeState.chainOut) {
        throw new Error('All fields are required to proceed with the bridge.')
      }

      if (!walletData) {
        throw new Error('Wallet data not found.')
      }

      if (bridgeState.recipient) {
        const didConfirm = await triggerConfirmationScreen(BridgeConfirmationScreen.bridgeToAny)
        if (!didConfirm) {
          return
        }
      }

      setProgress((state) => ({
        ...state,
        progressStep: BridgeProgressStep.Pending,
      }))
      if (!isNonEVMChain(bridgeState.chainIn)) {
        const switchChainResult = await requireChain(dispatch)(bridgeState.chainIn)
        if (switchChainResult.done === false) {
          resetProgressState()
          return
        }
        log({ switchChainResult })
      }
      const depositor = isNonEVMChain(bridgeState.chainIn) ? secondaryWalletAddress : walletData.address
      const recipient =
        bridgeState.recipient || (isNonEVMChain(bridgeState.chainOut) ? secondaryWalletAddress : walletData.address)
      const userQuote = await genUserQuoteMutation({
        path: { userId: walletData.address },
        payload: {
          amount: BigDecimal.unsafeFromString(bridgeState.amount),
          chainIn: bridgeState.chainIn,
          chainOut: bridgeState.chainOut,
          token: bridgeState.token,
          mode,
          depositor,
          recipient,
          amountNative: gasBoostAmountNative
            ? BigDecimal.unsafeFromString(gasBoostAmountNative)
            : BigDecimal.unsafeFromNumber(0),
        },
      }).unwrap()

      log({ userQuote })

      if (!userQuote?.quoteId) {
        throw new Error('Failed to generate user quote.')
      }

      const commitmentResult = await commitUserQuoteMutation({
        path: { userId: walletData.address, quoteId: userQuote.quoteId },
      }).unwrap()

      if (!commitmentResult?.quoteId) {
        throw new Error('Failed to commit user quote.')
      }

      bridgeId = commitmentResult.quoteId

      if (!chainInConfig) {
        throw new Error('ChainIn config not found.')
      }

      if (!chainInTokenConfig) {
        throw new Error('ChainIn token config not found.')
      }

      const isNativeToken = bridgeState.token === chainInConfig.nativeTokenName

      const receipt = await callBridgeContract({
        ethersSigner: ethersSigner,
        walletAddress: walletData.address,
        secondaryWalletAddress: isNonEVMChain(bridgeState.chainIn) ? secondaryWalletAddress : undefined,
        amount: userQuote.payAmount,
        amountWithDecimals: SafeDecimal(BigDecimal.format(userQuote.payAmount))
          .mul(10 ** chainInTokenConfig.decimals)
          .toFixed(),
        chain: bridgeState.chainIn,
        commitmentId: commitmentResult.quoteId,
        bridgeContractAddress: chainInConfig.contractAddress,
        tokenAddress: chainInTokenConfig.address,
        isNativeToken,
        callback: (txHash) => {
          setProgress((previous) => ({
            ...previous,
            transactionHash: txHash,
            isTxSubmitted: true,
          }))
        },
      })
      setProgress((previous) => ({
        ...previous,
        isTxConfirmed: true,
        timestamp: Date.now(),
      }))

      setTimeout(() => {
        setProgress((previous) => ({
          ...previous,
          progressStep: BridgeProgressStep.Done,
        }))
      }, 3000)

      const affiliateRefId = window.localStorage.getItem('refId')
      if (affiliateRefId) {
        await createAffiliateId({ user: walletData.address, affiliateId: affiliateRefId })
      }

      trackBridgeEvent({
        status: heapStatuses.success,
        token: bridgeState.token,
        chainIn: bridgeState.chainIn,
        chainOut: bridgeState.chainOut,
        amount: bigDecimalToNumericString(userQuote.receiveAmount),
        usdAmount: bigDecimalToNumericString(userQuote.payAmount),
        fee: bigDecimalToNumericString(userQuote.fees.fee),
        feeUsd: bigDecimalToNumericString(userQuote.fees.feeUsd),
        gasBoostUsed: gasBoostAmountNative ? (isGasBoostRedesignActive ? 'new' : 'standard') : 'false',
      })

      log('Native Transaction confirmed:', receipt)
    } catch (error) {
      console.error('Bridge failed:', error)
      if (isValidWalletError(error)) {
        trackHeapEvent(heapEvents.bridge, {
          status: heapStatuses.fail,
          token: bridgeState.token,
          chainIn: bridgeState.chainIn,
          chainOut: bridgeState.chainOut,
          amount: bridgeState.amount,
        })
      }
      // Clear bridge from history on failure
      if (bridgeId) {
        void patchBridgeHistoryMutation({
          path: { bridgeId },
          payload: { state: 'CANCELLED' },
        }).unwrap()
      }

      setProgress((previous) => ({
        ...previous,
        progressStep: BridgeProgressStep.Error,
      }))
    }
  }

  return { handleBridge }
}

import { useCallback, useMemo } from 'react'
import type { MouseEvent } from 'react'
import styled from 'styled-components'
import { Button, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import { selectAddress, selectIsAuthenticated } from '../../../../../store/selectors/user.selectors'
import { showModal } from '../../../../../store/actions/modalActions/showModal'
import { modalKeys } from '../../../../../constants/modalKeys'
import {
  type ChainTxOnErrorHandlerArgs,
  type ChainTxOnReceiptHandlerArgs,
  type ChainTxOnTxHashHandlerArgs,
  TxStatus,
} from '../../../../../services/txService/txService.types'
import { useDoChainTx } from '../../../../../hooks/useDoChainTx'
import { gmContract } from '../../../services/gmContract'
import { trackHeapEvent } from '../../../../../services/apiService'
import { heapEvents } from '../../../../../constants/heapEvents'
import { contractsInteractionsApi } from '../../../services/contractsInteractions.api'
import type { GmSuccessType } from '../../../types/contractsInteractions.types'

type Props = {
  chain: string
  contractAddress: string
  text?: string
  stopPropagation?: boolean
  successVariant?: GmSuccessType
  buttonVariant?: ButtonVariant
  disabled?: boolean
}

export const ContractsInteractionsGMButton = ({
  chain,
  contractAddress,
  text = '',
  stopPropagation = false,
  buttonVariant = ButtonVariant.secondary,
  successVariant = 'regular',
  disabled = false,
}: Props) => {
  const dispatch = useAppDispatch()

  const address = useAppSelector(selectAddress)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const notLoggedIn = useMemo(() => !address || !isAuthenticated, [address, isAuthenticated])

  const onGmInitCallback = useCallback(() => {
    showModal(dispatch)(modalKeys.contractGM, {
      txStatus: TxStatus.WAITING_WALLET,
      chain,
      successVariant,
    })
  }, [dispatch, chain, successVariant])

  const onGmTxHashCallback = useCallback(
    ({ txHash }: ChainTxOnTxHashHandlerArgs) => {
      showModal(dispatch)(modalKeys.contractGM, {
        txStatus: TxStatus.PENDING,
        txHash,
        chain,
        successVariant,
      })
    },
    [dispatch, chain, successVariant],
  )

  const onGmErrorCallback = useCallback(
    ({ txHash, retryFunc }: ChainTxOnErrorHandlerArgs) => {
      showModal(dispatch)(modalKeys.contractGM, {
        txStatus: TxStatus.ERROR,
        chain,
        retryFunc,
        txHash,
        successVariant,
      })
    },
    [dispatch, chain, successVariant],
  )

  const onGmReceiptCallback = useCallback(
    ({ txHash }: ChainTxOnReceiptHandlerArgs) => {
      trackHeapEvent(heapEvents.gm, {
        chain,
      })
      dispatch(
        contractsInteractionsApi.util.invalidateTags(['MultipleContracts', 'UserInteractions', 'SingleContract']),
      )

      showModal(dispatch)(modalKeys.contractGM, {
        txStatus: TxStatus.SUCCESS,
        chain,
        txHash,
        successVariant,
      })
    },
    [dispatch, chain, successVariant],
  )

  const doGmCallback = useDoChainTx({
    executionArgs: { contractAddress },
    executionFuncFactory: gmContract,
    destinationChainKey: chain,
    onInit: onGmInitCallback,
    onError: onGmErrorCallback,
    onTxHash: onGmTxHashCallback,
    onReceipt: onGmReceiptCallback,
  })

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) {
        event.stopPropagation()
      }

      doGmCallback()
    },
    [doGmCallback, stopPropagation],
  )

  return (
    <CustomButton
      id="contracts-interactions-gm-action"
      disabled={disabled || notLoggedIn}
      onClick={handleButtonClick}
      variant={buttonVariant}
      fullWidth
    >
      {text || 'GM'}
    </CustomButton>
  )
}

const CustomButton = styled(Button)`
  min-width: 117px;
`

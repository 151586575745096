import { useCallback, useEffect } from 'react'
import { POLLING_INTERVALS } from '../../constants/portalConfig'
import { NON_EVM_CHAINS } from '../../constants/types'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useInterval } from '../../hooks/useInterval'
import { fetchBlockChainBalanceForChain } from '../../store/actions/user.actions'
import { selectAvailableChains } from '../../store/apis/config.api'
import { selectSecondaryWalletAddressAndChain } from '../../store/selectors/secondaryWallet.selectors'
import { selectAddress } from '../../store/selectors/user.selectors'
import { LogFeature, makeLog } from '../../utils/makeLog'

const log = makeLog(LogFeature.L1_BALANCES_FETCH)

export const useChainBalances = () => {
  const address = useAppSelector(selectAddress)
  const secondaryWallet = useAppSelector(selectSecondaryWalletAddressAndChain)
  const dispatch = useAppDispatch()
  const availableChains = useAppSelector(selectAvailableChains)

  const fetchBalances = useCallback(() => {
    if (!address || availableChains.length === 0) {
      return
    }

    const enabledChains = availableChains.filter(({ chain }) =>
      NON_EVM_CHAINS[chain] ? secondaryWallet.connectedChain === chain : true,
    )

    log('fetching balances for', address, enabledChains)

    void enabledChains.map(({ chain, config }) =>
      fetchBlockChainBalanceForChain(dispatch)({
        chain,
        address,
        secondaryWallet,
        chainConfig: config,
      }),
    )
  }, [address, availableChains, dispatch, secondaryWallet])

  useEffect(() => {
    if (address) {
      fetchBalances()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run only on address change
  }, [address, availableChains])

  useInterval(
    () => {
      if (address) {
        fetchBalances()
      }
    },
    POLLING_INTERVALS.BLOCKCHAIN_BALANCE,
    false,
  )
}

import { Button, ButtonSizes, ButtonVariant, Icon, IconSizes, Text } from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { useMemo } from 'react'
import { GasBoostGrid } from '../BridgeWidget/BridgeWidget.styled'
import { NETWORKS } from '../../../../constants/types'
import { translate } from '../../../../intl/i18n'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import type { GasBoostOptions } from '../../hooks/useGasBoost'
import { GasBoostButtons } from './GasBoost.styled'
import { selectBridgeChainsConfig } from '../../../../store/selectors/bridgeConfig.selectors'

// XLAYER matches default, keeping as example for future
// chains requiring custom gas boost options.
const gasBoostPerChain: Record<string, number[]> = {
  [NETWORKS.XLAYER]: [0, 1, 3, 5],
}

export const GasBoost = ({
  token,
  chainOut,
  gasBoostOptions,
}: {
  token: string
  chainOut: string
  gasBoostOptions: GasBoostOptions
  setFieldValue: (field: string, value: string | boolean) => void
}) => {
  const { chainOutConfig } = useAppSelector(selectBridgeChainsConfig)

  const gasBoostValues = useMemo(() => {
    const defaultValues = [0, 1, 3, 5]
    if (gasBoostPerChain.hasOwnProperty(chainOut)) {
      return gasBoostPerChain[chainOut] || defaultValues
    }
    return defaultValues
  }, [chainOut])

  const { setGasBoost, gasBoost } = gasBoostOptions

  if (!chainOutConfig || chainOutConfig.nativeTokenName === token || !chainOutConfig?.gasBoostEnabled) {
    return null
  }

  return (
    <GasBoostGrid>
      <Text size={TextSize.S} bold>
        {translate('bridge.gas_boost')}{' '}
        <Icon
          id="info-circle"
          size={IconSizes.XSmall}
          customIconElement={<GasBoostIcon />}
          tooltip={<Text size={TextSize.XS}>{translate('bridge.gas_boost_tooltip')}</Text>}
        />
      </Text>
      <Text size={TextSize.XS} color="textSecondary">
        {translate('bridge.gas_boost_desc', {
          '%nativeToken': chainOutConfig.nativeTokenName,
          '%chainOut': chainOut,
        })}
      </Text>
      <GasBoostButtons>
        {gasBoostValues.map((value) => (
          <Button
            className="gas-boost-button"
            id={`gas-boost-${value}`}
            key={value}
            variant={ButtonVariant.picker}
            isSelected={value === gasBoost}
            size={ButtonSizes.Small}
            onClick={() => setGasBoost(value)}
          >
            ${value}
          </Button>
        ))}
      </GasBoostButtons>
    </GasBoostGrid>
  )
}

const GasBoostIcon = () => <span>⛽</span>

import { useCallback } from 'react'
import type { MissionId } from '@rhinofi/rhino-nation-api-spec'
import { showModal } from '../../../store/actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'
import { useAppDispatch } from '../../../hooks/useAppDispatch'

export const useWidgetMissionOpenModal = () => {
  const dispatch = useAppDispatch()

  return useCallback(
    (missionId: MissionId) => {
      showModal(dispatch)(modalKeys.nationActiveMission, {
        missionId,
      })
    },
    [dispatch],
  )
}

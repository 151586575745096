import { FetchHttpClient, HttpApiClient } from '@effect/platform'
import { flattenHttpApiClient } from '@rhinofi/effect-rtk'
import { apiSpec } from '@rhinofi/token-distribution-api-spec'
import { Effect } from 'effect'
import { AppConfig } from '../../../services/AppConfig'

export class TokenDistributionClient extends Effect.Service<TokenDistributionClient>()('TokenDistributionClient', {
  effect: Effect.gen(function* () {
    const baseUrl = `${yield* AppConfig.tradingApi}/token-distribution`

    const client = yield* HttpApiClient.make(apiSpec, { baseUrl })

    return flattenHttpApiClient(client)
  }),
  dependencies: [AppConfig.Default, FetchHttpClient.layer],
}) {}

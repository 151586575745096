/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style -- Legacy*/
/* eslint-disable @typescript-eslint/consistent-type-assertions -- Legacy */
/* eslint-disable functional/no-loop-statements -- Legacy */

/* eslint-disable @typescript-eslint/naming-convention -- Legacy */
import { Button, Input, Label, Spacing, Text } from '@rhinofi/dvf-shared-ui'
import type { FormikHelpers, FormikTouched } from 'formik'
import { Form, Formik } from 'formik'
import isEmpty from 'lodash-es/isEmpty'
import max from 'lodash-es/max'
import min from 'lodash-es/min'
import { useState } from 'react'
import styled from 'styled-components'
import { mapValues } from 'lodash-es'
import { VerificationCodeSchema } from '../../services/validation/formValidationService'
import { dynamicSchemaCreator } from '../../services/validation/dynamicSchemaCreator'
import { translate } from '../../intl/i18n'
import { FlexContainer } from '../common/Wrappers/Containers'
import { VerificationType } from './UserVerificationModal'
import { useVerifyCodeMutation } from '../../services/userVerificationApi'
import { useAppSelector } from '../../hooks/useAppSelector'
import { selectAddress } from '../../store/selectors/user.selectors'

type Props = {
  verificationType: VerificationType
  previousStep: () => void
  onSubmitSuccess: () => Promise<void>
  shortButton?: boolean | undefined
  buttonText?: string | undefined
  autoFocus?: boolean | undefined
}
type VerificationCode = {
  code_0: string
  code_1: string
  code_2: string
  code_3: string
  code_4: string
  code_5: string
}

const initialVerificationCode: VerificationCode = {
  code_0: '',
  code_1: '',
  code_2: '',
  code_3: '',
  code_4: '',
  code_5: '',
}

const fieldGeneratorArray: (keyof VerificationCode)[] = new Array(6)
  .fill('')
  .map((_, index) => `code_${index}` as keyof VerificationCode)

export const VerificationCodeForm = ({
  verificationType,
  previousStep,
  onSubmitSuccess,
  shortButton = false,
  buttonText,
  autoFocus = true,
}: Props) => {
  const [error, setError] = useState<string | null>()
  const [verifyCode] = useVerifyCodeMutation()
  const address = useAppSelector(selectAddress)
  const handleChange = (
    value: string | number | undefined,
    index: number,
    setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
  ) => {
    if (!value) {
      setFieldValue(`code_${index}`, '')
      if (index === 0) {
        setError('')
      }

      const previousField = document.querySelector(`input[name=code_${max([0, index - 1])}]`) as HTMLElement

      if (previousField) {
        previousField.focus()
      }
      return
    }

    // Handle code copy-paste

    // eslint-disable-next-line functional/no-let -- Legacy
    let stringValue = value?.toString() || ''
    // eslint-disable-next-line functional/no-let -- Legacy
    let counter = index

    while (stringValue.length > 0 && counter < 6) {
      setFieldValue(`code_${counter}`, stringValue.substring(0, 1))
      stringValue = stringValue.substring(1)
      counter++
    }
    // Focus on next field as you enter the code
    const nextField = document.querySelector(`input[name=code_${min([5, counter])}]`) as HTMLElement

    if (nextField) {
      nextField.focus()
    }
  }

  const submit = async (values: VerificationCode, formikHelpers: FormikHelpers<VerificationCode>) => {
    const code = Object.values(values).join('')
    const res = await verifyCode({ type: verificationType, code, user: address || '' })
    if (!res.error) {
      return await onSubmitSuccess()
    }
    setError(translate('user_verification.invalid_code'))
    formikHelpers.setErrors({
      code_0: 'invalid code',
      code_1: 'invalid code',
      code_2: 'invalid code',
      code_3: 'invalid code',
      code_4: 'invalid code',
      code_5: 'invalid code',
    })
  }

  const showError = (isFormValid: boolean, touched: FormikTouched<Record<string, string>>) => {
    if (verificationType !== VerificationType.phone || isFormValid) {
      return error
    }
    if (Object.keys(touched).length < 6) {
      return ''
    }
    return translate('user_verification.invalid_code')
  }

  return (
    <Formik
      initialValues={initialVerificationCode}
      validate={(formValues) =>
        dynamicSchemaCreator(
          mapValues(formValues, (fieldValue) => +fieldValue),
          VerificationCodeSchema,
        )
      }
      onSubmit={submit}
    >
      {({ values, isValid, setFieldValue, touched, isSubmitting }) => (
        <Form>
          <FlexContainer $direction="column" $alignItems="flex-start">
            <Spacing size="14" />
            <Text transparency={false}>{translate(`user_verification.enter_code_${verificationType}`)}</Text>
            <Spacing size="30" />
            <FlexContainer $fullWidth>
              <Label transparency={false} center={false}>
                {translate('user_verification.code')}
              </Label>
            </FlexContainer>
            <FlexContainer>
              {fieldGeneratorArray.map((key, index) => (
                <CodeInputWrapper key={key}>
                  <Input
                    type="number"
                    id={key}
                    name={key}
                    inputValue={values[key]}
                    onInputChange={(value) => handleChange(value, index, setFieldValue)}
                    showError={false}
                    autoFocus={autoFocus ? index === 0 && isEmpty(touched) : undefined}
                    disableSetValueOnChange
                  />
                </CodeInputWrapper>
              ))}
            </FlexContainer>
            <FlexContainer $justifyContent="space-between" $fullWidth>
              <Label variant="error">{showError(isValid, touched)}</Label>
              <LabelCustom onClick={previousStep}>{translate('user_verification.re_enter_details')}</LabelCustom>
            </FlexContainer>
            <Spacing size="30" />
            <Button
              id="submit-verify"
              loading={isSubmitting}
              type="submit"
              fullWidth={!shortButton}
              icon={buttonText ? '' : 'shield-check'}
              disabled={!isValid}
            >
              {buttonText || translate('user_verification.verify')}
            </Button>
          </FlexContainer>
        </Form>
      )}
    </Formik>
  )
}

const LabelCustom = styled(Label)`
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
`

const CodeInputWrapper = styled.div`
  margin: 5px;
`

import type { PublicQuoteResponseSchema, QuoteFeeSchema, QuotePromotionSchema } from '@rhinofi/bridge-api-spec'
import {
  Button,
  ButtonSizes,
  ButtonVariant,
  Icon,
  IconSizes,
  NetworkSelect,
  Text,
  Title,
  TokenInput,
} from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { useMemo } from 'react'
import SwitchIcon from '../../../../assets/icons/switch.svg?react'
import { ComponentPanel, ComponentPanelRow } from '../../../../components/common/Form/ComponentPanel'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { useAppSelector } from '../../../../hooks'
import { useConnectWallet } from '../../../../hooks/useConnectWallet'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { translate } from '../../../../intl/i18n'
import { formatFloat } from '../../../../services/helperService/formatFloat'
import { getTokenPrice } from '../../../../services/helperService/getTokenPrice'
import { selectTokenPrices } from '../../../../services/usdPricesApi'
import { selectBridgeChainsConfig } from '../../../../store/selectors/bridgeConfig.selectors'
import { networkSelectorDictionary } from '../../../../utils/dictionaries/networkSelectorDictionary'
import { tokenInputDictionary } from '../../../../utils/dictionaries/tokenInputDictionary'
import { LogFeature, makeLog } from '../../../../utils/makeLog'
import { SafeDecimal } from '../../../../utils/SafeDecimal'
import { BRIDGE_CATEGORY_SORT } from '../../constants/bridge.constants'
import { bridgeWidgetLabels } from '../../constants/bridgeWidgetLabels.constants'
import type { NormalizedError } from '../../helpers'
import { selectAddress } from '../../../../store/selectors/user.selectors'
import { getBridgeReceiveToken } from '../../helpers'
import { InputActionKeys, SelectableActionKeys, useBridgeDataWithBalances, useBridgeState } from '../../hooks'
import type { GasBoostOptions } from '../../hooks'
import type { BridgeFormValues } from '../../types/bridge-widget.types'
import { BridgeFeeBreakdown } from '../BridgeFeeBreakdown/BridgeFeeBreakdown'
import { BridgeToAny } from '../BridgeToAny/BridgeToAny'
import {
  BridgeLayout,
  DestinationDetails,
  LabelGroup,
  SwitchNetworks,
  TokenAmount,
  TokenLabel,
} from '../BridgeWidget/BridgeWidget.styled'
import { GasBoost } from '../GasBoost/GasBoost'
import { GasBoostNew } from '../GasBoostNew/GasBoostNew'
import { ReviewButton } from '../ReviewButton'
import { useIsGasBoostRedesignActive } from '../../hooks/useIsGasBoostRedesignActive'
import { useResize } from '../../../../contexts'
import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'

const log = makeLog(LogFeature.BRIDGE_FORM_STATE)

type BridgeFormStateProps = {
  values: BridgeFormValues
  normalizedError: NormalizedError
  setFieldValue: (field: string, value: string | boolean) => void
  withdrawalFeeConfig: QuoteFeeSchema | undefined
  withdrawalPromoConfig: QuotePromotionSchema | undefined
  tokenBalance: string
  maxBridgeableAmount: string
  gasBoostOptions: GasBoostOptions
  payAmount: PublicQuoteResponseSchema['payAmount'] | undefined
}

export const BridgeFormState = ({
  values,
  normalizedError,
  setFieldValue,
  withdrawalFeeConfig,
  withdrawalPromoConfig,
  tokenBalance,
  maxBridgeableAmount,
  gasBoostOptions,
  payAmount,
}: BridgeFormStateProps) => {
  const tokenPrices = useAppSelector(selectTokenPrices)
  const { chainInConfig, chainOutConfig } = useAppSelector(selectBridgeChainsConfig)
  const { isMobile } = useIsMobile()
  const { handleSelectChange, handleSwitchChains, handleInputChange } = useBridgeState({
    setFieldValue,
  })
  const { availableChainsWithBalances, tokensListWithBalances } = useBridgeDataWithBalances()
  const connectWallet = useConnectWallet()
  const isEvmWalletConnected = !!useAppSelector(selectAddress)
  const { width } = useResize()

  const clearRecipient = () => handleInputChange(InputActionKeys.Recipient, '')

  const amountInUsd = useMemo(() => {
    if (!values.amount || !values.token) {
      return '0'
    }
    const tokenPrice = getTokenPrice(tokenPrices, values.token)
    return formatFloat(SafeDecimal(values.amount).times(tokenPrice).toString())
  }, [values.amount, values.token, tokenPrices])

  const isGasBoostRedesignActive = useIsGasBoostRedesignActive()
  const GasBoostComponent = useMemo(
    () => (isGasBoostRedesignActive ? GasBoostNew : GasBoost),
    [isGasBoostRedesignActive],
  )

  const isMobileStyling = useMemo(() => width <= defaultThreshold.xxxs, [width])

  log({ availableChainsWithBalances, tokensListWithBalances, chainInConfig, chainOutConfig })

  return (
    <BridgeLayout $isGasBoostRedesignActive={isGasBoostRedesignActive}>
      <TokenLabel>
        <Title bold id="bridge-title-form-state">
          {bridgeWidgetLabels.bridge()}
        </Title>
      </TokenLabel>
      <DestinationDetails>
        <LabelGroup>
          <Text size={TextSize.XS} color="textSecondary">
            {translate('helpers.from')}
          </Text>
          <NetworkSelect
            name="chainIn"
            networks={availableChainsWithBalances}
            token={values.token}
            handleSelection={({ network, token }) => {
              if (network === values.chainOut) {
                clearRecipient()
                handleSwitchChains()
              } else {
                handleSelectChange(SelectableActionKeys.ChainIn, network)
              }
              if (token) {
                handleSelectChange(SelectableActionKeys.Token, token)
              }
            }}
            dictionary={{ ...networkSelectorDictionary, listLabel: translate('bridge.select_network_from') }}
            categorySort={BRIDGE_CATEGORY_SORT}
            shouldOverridePadding
            fullWidth
            isStandalone
            autoFocus={!isMobile}
            showTabs
            onConnectWallet={connectWallet}
            isEvmWalletConnected={isEvmWalletConnected}
            showFullNetworkName={isMobileStyling}
          />
        </LabelGroup>
        <SwitchNetworks>
          <Icon
            id="arrow-left-right"
            active
            handleClick={() => {
              clearRecipient()
              handleSwitchChains()
            }}
            size={IconSizes.Large}
            customIconElement={<SwitchIcon />}
          />
        </SwitchNetworks>
        <LabelGroup>
          <FlexContainer $justifyContent="space-between" $fullWidth>
            <Text size={TextSize.XS} color="textSecondary">
              {translate('helpers.to')}
            </Text>
          </FlexContainer>
          <NetworkSelect
            name="chainOut"
            networks={availableChainsWithBalances}
            token={values.token}
            handleSelection={({ network, token }) => {
              clearRecipient() // Clear recipient needs to be triggered first to avoid history issues
              if (network === values.chainIn) {
                handleSwitchChains()
              } else {
                handleSelectChange(SelectableActionKeys.ChainOut, network)
              }
              if (token) {
                handleSelectChange(SelectableActionKeys.Token, token)
              }
            }}
            dictionary={{ ...networkSelectorDictionary, listLabel: translate('bridge.select_network_to') }}
            categorySort={BRIDGE_CATEGORY_SORT}
            shouldOverridePadding
            fullWidth
            isStandalone
            showTabs
            onConnectWallet={connectWallet}
            isEvmWalletConnected={isEvmWalletConnected}
            showFullNetworkName={isMobileStyling}
          />
        </LabelGroup>
      </DestinationDetails>

      <TokenAmount>
        <LabelGroup>
          <Text size={TextSize.XS} color="textSecondary">
            {translate('global.select_token')}
          </Text>
          <ComponentPanel $withPadding>
            <ComponentPanelRow $autoHeight $smallLeftMargin>
              <TokenInput
                id="deposit-input"
                selectId="deposit-token-select"
                selectName="token"
                activeChain={values.chainIn}
                displayValue={getBridgeReceiveToken(values.chainIn, values.token)}
                onSelect={(token?: string) => {
                  if (token) {
                    handleSelectChange(SelectableActionKeys.Token, token)
                  }
                }}
                onInputChange={(newDepositAmount) => {
                  handleInputChange(InputActionKeys.Amount, newDepositAmount)
                }}
                inputName="amount"
                list={tokensListWithBalances}
                dictionary={tokenInputDictionary}
                headings={[translate('helpers.token'), translate('helpers.balance')]}
                shouldOverridePadding
                disableHover
                valueError={normalizedError?.errorKey === InputActionKeys.Amount.toLowerCase()}
              />
            </ComponentPanelRow>
            <ComponentPanelRow $autoHeight>
              <FlexContainer $gap="4px">
                <Text size={TextSize.XS} color="textSecondary" noWrap>
                  Available: {formatFloat(tokenBalance)}{' '}
                  {getBridgeReceiveToken(values.chainIn, values.token) || values.token || ''}
                </Text>
                <Button
                  id="max-bridge"
                  variant={ButtonVariant.text}
                  size={ButtonSizes.ExtraSmall}
                  onClick={() => {
                    handleInputChange(InputActionKeys.Amount, maxBridgeableAmount)
                  }}
                >
                  {bridgeWidgetLabels.max().toUpperCase()}
                </Button>
              </FlexContainer>
              <Text size={TextSize.XS} color="textSecondary">
                ${amountInUsd}
              </Text>
            </ComponentPanelRow>
          </ComponentPanel>
        </LabelGroup>
      </TokenAmount>
      <BridgeToAny
        isGasBoostRedesignActive={isGasBoostRedesignActive}
        values={values}
        setFieldValue={setFieldValue}
        valueError={normalizedError?.errorKey === InputActionKeys.Recipient.toLowerCase()}
      />
      <ReviewButton
        chain={values.chainIn}
        withdrawChain={values.chainOut}
        amount={values.amount}
        chainName={chainInConfig?.name ?? ''}
        withdrawChainName={chainOutConfig?.name ?? ''}
        normalizedError={normalizedError}
        isOtherAddress={values.isOtherAddress}
        token={values.token}
        payAmount={payAmount}
      />
      <BridgeFeeBreakdown
        amount={values.amount}
        token={values.token}
        chainIn={values.chainIn}
        chainOut={values.chainOut}
        feesConfig={withdrawalFeeConfig}
        promoConfig={withdrawalPromoConfig}
        payAmount={payAmount}
      />
      <GasBoostComponent
        token={values.token}
        chainOut={values.chainOut}
        gasBoostOptions={gasBoostOptions}
        setFieldValue={setFieldValue}
      />
    </BridgeLayout>
  )
}

import { Text, TextSize, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { findBridgeKeyForNetworkId } from '../../../services/helperService/findBridgeKeyForNetworkId'
import { shortenAddress } from '../../../services/helperService/shortenAddress'
import { selectBridgeConfig } from '../../../store/apis/config.api'
import { selectAddress, selectConnectedNetwork } from '../../../store/selectors/user.selectors'
import { FlexContainer } from '../../common/Wrappers/Containers'

export const EVMConnected = () => {
  const address = useAppSelector(selectAddress)
  const network = useAppSelector(selectConnectedNetwork)
  const bridgeConfig = useAppSelector(selectBridgeConfig)

  const chainID = findBridgeKeyForNetworkId(network?.toString() || '', bridgeConfig) || ''

  return (
    <FlexContainer $gap="8px" className="high-priority-display">
      <TokenIcon token={chainID} customSize="20px" />
      <Text size={TextSize.S}>{shortenAddress(address, false, 5)}</Text>
    </FlexContainer>
  )
}

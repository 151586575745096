import { NETWORKS } from '../../../constants/types'

const DISABLED_ROUTES = [
  { from: NETWORKS.ETHEREUM, to: NETWORKS.SONEIUM },
  { from: NETWORKS.SONEIUM, to: NETWORKS.ETHEREUM },
]

export const isBridgeToAnyRouteDisabled = (chainIn: string, chainOut: string) => {
  return DISABLED_ROUTES.some((route) => route.from === chainIn && route.to === chainOut)
}

import { trackUserflowEvent } from '../../../services/apiService/trackUserflowEvent'
import { trackAddressableEvent, trackHeapEvent } from '../../../services/apiService'
import { heapEvents } from '../../../constants/heapEvents'
import { userflowEvents } from '../../../constants/userflowEvents'
import { addressableEvents } from '../../../constants/addressableEvents'

type BridgeEvent = {
  status: string
  token: string
  chainIn: string
  chainOut: string
  amount: string
  usdAmount: string
  fee: string
  feeUsd: string
  gasBoostUsed: string
}

export const trackBridgeEvent = ({
  status,
  token,
  chainIn,
  chainOut,
  amount,
  usdAmount,
  gasBoostUsed,
}: BridgeEvent) => {
  const data = {
    status,
    token,
    chainIn,
    chainOut,
    amount,
    usdAmount,
    gasBoostUsed,
  }

  trackHeapEvent(heapEvents.bridge, data)
  trackUserflowEvent(userflowEvents.bridge, data)
  trackAddressableEvent(addressableEvents.bridge, true, data)
}

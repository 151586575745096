import { useTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { Dropdown } from '@rhinofi/dvf-shared-ui'
import { NavigationDesktopWrapper, NavigationLinksContent, NavigationLogo } from './NavigationDesktop.styled'
import rhinoSolo from '../../assets/icons/rhino-solo.svg'
import { NavigationLinks } from './NavigationLinks'
import { publicRoutes } from '../../router'
import { FlexContainer } from '../common/Wrappers/Containers'
import { useResize } from '../../contexts'
import { Menu } from '../Menu/Menu'
import { RhinoNationBalance } from '../../pages/Nation/components/NationContent/RhinoNationBalance/RhinoNationBalance'
import { NationWidget } from '../../pages/Nation/components/NationWidget/NationWidget'
import { useMemo } from 'react'

export const NavigationDesktop = () => {
  const theme = useTheme()
  const { width } = useResize()

  const useMobileLogo = useMemo(() => width < 670, [width])

  return (
    <NavigationDesktopWrapper id="navigation-desktop-wrapper">
      <NavigationLinksContent id="navigation-links-content">
        <Link to={publicRoutes.bridge} className="logo-link">
          <NavigationLogo src={useMobileLogo ? rhinoSolo : theme.assets.logo} alt="rhino.fi logo" />
        </Link>
        <NavigationLinks />
      </NavigationLinksContent>
      <FlexContainer $gap="16px">
        <div id="top-communication-space" />

        {width >= defaultThreshold.m && (
          <Dropdown triggerType="click" element={<RhinoNationBalance showRxp />} strongBoxShadow>
            <NationWidget />
          </Dropdown>
        )}

        <Menu />
      </FlexContainer>
    </NavigationDesktopWrapper>
  )
}

import { Table, useTableSort } from '@rhinofi/dvf-shared-ui'
import { useCallback, useMemo } from 'react'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components'
import { useBridgeNavigate } from '../../../../hooks/bridge'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { makeRouteTo } from '../../../../router'
import { selectAddress } from '../../../../store/selectors/user.selectors'
import { isMobileDevice } from '../../../../utils'
import { useBridgeProgress, usePortfolio } from '../../hooks'
import { BridgeProgressStep } from '../../slices/bridgeProgress.slice'
import type { TokenWithBalance } from '../../types/bridge-widget.types'
import { getBridgePortfolioColumns } from './BridgePortfolio.columns'
import { BridgePortfolioPlaceholders } from './BridgePortfolioPlaceholders'

export const BridgePortfolio = () => {
  const address = useAppSelector(selectAddress)

  const isMobile = isMobileDevice()
  const bridgeNavigate = useBridgeNavigate()
  const { progress, resetProgressState } = useBridgeProgress()

  const { portfolio, isLoading } = usePortfolio()

  const onBridgeClick = useCallback(
    ({ token, chain }: TokenWithBalance) => {
      bridgeNavigate(makeRouteTo.bridgeOptional({ from: chain, token }))
      // If bridge button is clicked on while bridge is on success state, reset the progress state
      if (progress.progressStep === BridgeProgressStep.Done) {
        resetProgressState()
      }
    },
    [bridgeNavigate, progress.progressStep, resetProgressState],
  )

  const isBridgePending = progress.progressStep === BridgeProgressStep.Pending

  const columns = useMemo(
    () => getBridgePortfolioColumns({ onBridgeClick, isBridgePending, isMobile }),
    [onBridgeClick, isBridgePending, isMobile],
  )

  const { sortBy, onSort, sortedData } = useTableSort({
    data: portfolio,
    columns,
    defaultSortBy: {
      id: 'balanceUsd',
      direction: 'desc',
    },
  })

  return (
    <SimpleBar style={{ maxHeight: 397 }}>
      <BridgePortfolioContainer>
        <Table
          id="portfolio"
          data={sortedData}
          columns={columns}
          idExtractor={(item) => item.token + item.chain}
          onRowClick={isMobile && !isBridgePending ? onBridgeClick : undefined}
          emptyMessage={<BridgePortfolioPlaceholders isConnected={Boolean(address)} />}
          loading={isLoading}
          sortBy={sortBy}
          onSort={onSort}
          noHover
        />
      </BridgePortfolioContainer>
    </SimpleBar>
  )
}

export const BridgePortfolioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

import { useCallback, useEffect, useMemo, useState } from 'react'
import { F_FLAGS_TYPES } from '../../../../constants/types'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { usePrevious } from '../../../../hooks/usePrevious'
import { translate } from '../../../../intl/i18n'
import { isFeatureEnabled } from '../../../../services/helperService/isFeatureEnabled'
import { useGetUserHistoryQuery } from '../../../../store/apis/bridge.api'
import { selectAddress, selectIsAuthenticated } from '../../../../store/selectors/user.selectors'
import { BridgeTabsStep } from '../../types/bridge-widget.types'
import { BridgeHighlights } from '../BridgeHighlights/BridgeHighlights'
import { BridgePortfolio } from '../BridgePortfolio/BridgePortfolio'
import { BridgeTransitionStates } from '../BridgeTransitionStates'
import { RecentBridgeTransactions } from '../RecentBridgeTransactions/RecentBridgeTransactions'
import { BridgeTabsContainer } from './BridgeTabs.styled'
import { TabSelect } from './TabSelect'

const isRewardsCampaignLive = isFeatureEnabled(F_FLAGS_TYPES.BRIDGE_REWARDS_CAMPAIGN)
const isPortfolioTabEnabled = isFeatureEnabled(F_FLAGS_TYPES.BRIDGE_PORTFOLIO_TAB)

export const BridgeTabs = () => {
  const userAddress = useAppSelector(selectAddress)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const [activeTab, setActiveTab] = useState<BridgeTabsStep>(BridgeTabsStep.highlights)

  const { data, isFetching } = useGetUserHistoryQuery(
    {
      path: {
        userId: userAddress || '',
      },
      urlParams: {
        limit: isRewardsCampaignLive ? 50 : 5,
        page: 1,
        sortBy: 'createdAt',
        sortDirection: 'desc',
      },
    },
    {
      skip: !isAuthenticated,
      refetchOnMountOrArgChange: true,
      pollingInterval: 10000,
    },
  )

  const pendingBridgesCount = useMemo(() => {
    if (!data?.items) {
      return undefined
    }
    const count = data.items.reduce((acc, { bridge }) => {
      if (bridge.state === 'PENDING') {
        return acc + 1
      }
      return acc
    }, 0)
    return count || undefined
  }, [data])

  const previousPendingBridgesCount = usePrevious(pendingBridgesCount)

  const tabs = useMemo(() => {
    const allTabs = [
      {
        label: translate('helpers.explore'),
        id: BridgeTabsStep.highlights,
      },
      {
        label: translate('portfolio.portfolio'),
        id: BridgeTabsStep.portfolio,
      },
      {
        label: translate('helpers.history'),
        id: BridgeTabsStep.history,
        notificationCount: pendingBridgesCount,
      },
    ]
    return isPortfolioTabEnabled ? allTabs : allTabs.filter((tab) => tab.id !== BridgeTabsStep.portfolio)
  }, [pendingBridgesCount])

  useEffect(() => {
    if (!pendingBridgesCount) {
      return
    }
    if ((previousPendingBridgesCount || 0) < pendingBridgesCount) {
      setActiveTab(BridgeTabsStep.history)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on count change
  }, [pendingBridgesCount])

  const renderBridgeTabsContent = useCallback(
    (step: BridgeTabsStep) => {
      switch (step) {
        case BridgeTabsStep.highlights:
          return <BridgeHighlights />
        case BridgeTabsStep.history:
          return <RecentBridgeTransactions bridgeHistory={data} isFetching={isFetching} />
        case BridgeTabsStep.portfolio:
          return isPortfolioTabEnabled ? <BridgePortfolio /> : null
        default:
          return null
      }
    },
    [data, isFetching],
  )

  return (
    <BridgeTabsContainer>
      <TabSelect tabs={tabs} onClick={setActiveTab} activeTab={activeTab} fullWidth />
      <BridgeTransitionStates currentState={activeTab}>
        {({ step }) => renderBridgeTabsContent(step)}
      </BridgeTransitionStates>
    </BridgeTabsContainer>
  )
}

import { useEffect } from 'react'
import { useAppSelector } from '../../hooks'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { clearModal } from '../../store/actions/modalActions/clearModal'
import { selectAddress } from '../../store/selectors/user.selectors'
import { Modal } from '../common/Modal/Modal'
import { ConnectWallet } from '../ConnectWallet/ConnectWallet'

export const ConnectWalletModal = () => {
  const dispatch = useAppDispatch()

  const userAddress = useAppSelector(selectAddress)

  useEffect(() => {
    if (userAddress) {
      clearModal(dispatch)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on address change
  }, [userAddress])

  return (
    <Modal isVisible onClose={() => clearModal(dispatch)()} width="400px" title="Connect your wallet" maxHeight={600}>
      <ConnectWallet />
    </Modal>
  )
}

import { useCallback } from 'react'
import type { MissionId, GetAllGemsTransactionsResponse } from '@rhinofi/rhino-nation-api-spec'
import { showModal } from '../../../store/actions/modalActions/showModal'
import { modalKeys } from '../../../constants/modalKeys'
import { useAppDispatch } from '../../../hooks/useAppDispatch'

type Props = {
  gemsTransactions?: GetAllGemsTransactionsResponse | undefined
}

export const useOpenNationCompletedMissionModalFromGemsTx = ({ gemsTransactions }: Props) => {
  const dispatch = useAppDispatch()

  return useCallback(
    (missionId: MissionId) => {
      if (!gemsTransactions) {
        return
      }

      const gemsTransaction = gemsTransactions.items.find((item) => {
        if ('mission' in item) {
          return item.mission._id.id === missionId.id
        }

        return false
      })

      if (!gemsTransaction) {
        return
      }

      showModal(dispatch)(modalKeys.nationCompletedMission, { gemsTransaction })
    },
    [gemsTransactions, dispatch],
  )
}

import type { NftType as MissionNftType } from '@rhinofi/rhino-nation-api-spec'
import { NETWORKS } from '../../../constants/types'
import type { NFTMinter } from '../types/nfts.types'
import { getNFTImage } from './getNFTImage'
import { getNFTContractConfig } from './nftContractHelpers'
import { safeGetChainName } from '../../../utils/getChainName'
import type { NFTType } from '../types/mintNFT.types'
import type { BridgeConfig } from '../../../contexts'

const getNFTName = (type: NFTType, chain: string, config?: BridgeConfig) => {
  if (type === 'hunter' && chain === NETWORKS.INK) {
    return `Ink Genesis`
  }
  const chainToName = safeGetChainName(config)
  const chainName = chainToName(chain)

  switch (type) {
    case 'wrapped2023':
      return 'rhino.fi Wrapped 2023'
    case 'hunter':
      return `rhino.fi ${chainName} Hunter`
    case 'TOP30':
      return `rhino.fi ${chainName} Pro Hunter`
    case 'VOLUME':
      return `${chainName} Trader`
    case 'TXCOUNT':
      return `${chainName} Frequent Flyer`
    case 'ALPHAKEY':
      return 'Rhino Alpha Key'
    case 'BETAKEY':
      return 'Rhino Passport'
    default:
      return ''
  }
}

export const getNftNameForMissionNFTType = ({
  nftType,
  chain,
  config,
}: {
  nftType: MissionNftType
  chain: string
  config: BridgeConfig | undefined
}) => {
  if (nftType === 'HUNTER') {
    return getNFTName('hunter', chain, config)
  }

  return getNFTName(nftType, chain, config)
}

export const getNFTMetaData = ({
  type,
  chain,
  tokenId,
  config,
  index,
}: {
  type: NFTType
  chain: string
  tokenId: number
  config?: BridgeConfig | undefined
  index?: number | undefined
}): NFTMinter => ({
  name: getNFTName(type, chain, config),
  image: getNFTImage(type, chain),
  contractType: getNFTContractConfig(chain, type).type,
  tokenId,
  chain,
  type,
  index,
})

import type { StarknetWindowObject } from 'starknetkit'
import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { starknetLookup } from '../../../nameServices/starknetIdService'
import {
  updateSecondaryNetworkId,
  updateSecondaryAddress,
  updateSecondaryDomain,
} from '../../../../store/slices/secondaryWallet.slice'

// Braavos only override, as it dispatches it's on networkChanged event
/* eslint-disable @typescript-eslint/naming-convention -- braavos uses this convention */
const NETWORK_OVERRIDES: Record<string, string> = {
  'goerli-alpha': 'SN_GOERLI',
  'mainnet-alpha': 'SN_MAIN',
  'sepolia-alpha': 'SN_SEPOLIA',
}
export const createStarknetListener = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  connection: StarknetWindowObject,
) => {
  const { shortString } = await import('starknet')
  if (connection && typeof connection.on === 'function') {
    // address can be undefined, there were reports of accessing .length on undefined here
    connection.on('accountsChanged', async (address: string[] | string | undefined) => {
      const changedAddress = Array.isArray(address) ? address[0] : address
      if (!changedAddress || changedAddress.length === 0 || changedAddress === '0') {
        return
      }
      dispatch(updateSecondaryAddress(changedAddress))
      const starknetDomain = await starknetLookup(changedAddress)
      updateSecondaryDomain(starknetDomain)
    })
    connection.on('networkChanged', (networkId?: string[] | string) => {
      const changedNetworkId = Array.isArray(networkId) ? networkId[0] : networkId
      if (!changedNetworkId) {
        return
      }
      const parsedNetworkId = NETWORK_OVERRIDES[changedNetworkId]
        ? NETWORK_OVERRIDES[changedNetworkId]
        : shortString.decodeShortString(changedNetworkId)
      dispatch(updateSecondaryNetworkId(parsedNetworkId))
    })
  }
}

import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import { selectBlockchainBalances } from '../../../store/selectors/portal.selectors'
import { useAppSelector } from '../../../hooks'
import { getTokenPrice } from '../../../services/helperService/getTokenPrice'
import { selectMarketData, selectTokenPrices } from '../../../services/usdPricesApi'
import { selectBridgeConfig, selectConfig } from '../../../store/apis/config.api'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import type { TokenWithBalance } from '../types/bridge-widget.types'

export enum PortfolioSortBy {
  NAME = 'name',
  BALANCE_USD = 'balanceUsd',
  CHAIN = 'chain',
}

type UseBridgeStateOutput = {
  portfolio: TokenWithBalance[]
  isLoading: boolean
}

export const usePortfolio = (): UseBridgeStateOutput => {
  // Getting status from rtk queries that are called in higher level contexts
  const marketDataStatus = useAppSelector(selectMarketData).status
  const configStatus = useAppSelector(selectConfig).status

  const tokenPrices = useAppSelector(selectTokenPrices)
  const blockchainBalances = useAppSelector(selectBlockchainBalances)
  const config = useAppSelector(selectBridgeConfig)

  const isLoading = useMemo(
    () => marketDataStatus === 'pending' || configStatus === 'pending' || isEmpty(blockchainBalances),
    [marketDataStatus, configStatus, blockchainBalances],
  )

  const portfolio = useMemo((): TokenWithBalance[] => {
    if (isLoading) {
      return []
    }

    return Object.entries(blockchainBalances).flatMap(([chain, tokens]) =>
      Object.values(tokens)
        .filter(({ balance }) => parseFloat(balance) > 0)
        // Filter those tokens that are not supported by the bridge
        .filter(({ token }) => config[chain]?.['tokens'][token])
        .map(({ token, balance }) => {
          const tokenUsdPrice = getTokenPrice(tokenPrices, token)
          const balanceUsd = SafeDecimal(balance).times(tokenUsdPrice).toString()

          return {
            token,
            chain,
            chainName: config[chain]?.name ?? '',
            balanceToken: balance,
            balanceUsd,
          }
        }),
    )
  }, [isLoading, blockchainBalances, config, tokenPrices])

  return {
    portfolio,
    isLoading,
  }
}

import Aigle from '@rhino.fi/aigle'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getNFTsForChain } from '../helpers/getNFTsForChain'
import type { NFTMinter } from '../types/nfts.types'
import { NETWORKS } from '../../../constants/types'
import type { NFTType } from '../types/mintNFT.types'
import { canMintNFT } from '../helpers/canMintNFT'
import { getEnabledNFTMintersChains } from '../helpers/getEnabledNFTMintersChains'
import type { BridgeConfig } from '../../../contexts'

export const mintedNFTsApi = createApi({
  reducerPath: 'mintedNFTsApi',
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['MintedNFTs', 'StarknetMintedNFTs'],
  endpoints: (builder) => ({
    getMintedNFTs: builder.query<NFTMinter[], { address: string; config: BridgeConfig | undefined }>({
      queryFn: async ({ address, config }) => {
        try {
          const nfts = await Aigle.map(getEnabledNFTMintersChains(), (chain) => getNFTsForChain(chain, address, config))

          return {
            data: nfts.flat(),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch user NFTs' } }
        }
      },
      providesTags: ['MintedNFTs'],
    }),
    getStarknetMintedNFTs: builder.query<NFTMinter[], { secondaryAddress: string; config: BridgeConfig | undefined }>({
      queryFn: async ({ secondaryAddress, config }) => {
        try {
          const nfts = await getNFTsForChain(NETWORKS.STARKNET, secondaryAddress, config)

          return {
            data: nfts.flat(),
          }
        } catch (error) {
          console.error(error)
          return { error: { status: 500, data: 'Failed to fetch user NFTs' } }
        }
      },
      providesTags: ['StarknetMintedNFTs'],
    }),
    canMintNFT: builder.query<
      boolean,
      {
        chain: string
        nftType: NFTType
        user: string
        alreadyMinted: boolean
        config: BridgeConfig | undefined
      }
    >({
      queryFn: async ({ chain, nftType, user, config }) => {
        if (!config) {
          return {
            data: false,
          }
        }

        const canMint = await canMintNFT({ chain, nftType, user, config })
        return { data: canMint }
      },
    }),
  }),
})

export const { useGetMintedNFTsQuery, useGetStarknetMintedNFTsQuery, useCanMintNFTQuery } = mintedNFTsApi

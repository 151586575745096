import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { ModalShowAction } from '../actions/modalActions/showModal'
import { ADDRESS_CHANGE, WALLET_CONNECTED, WALLET_DISCONNECT } from '../actions/types/user'
import type { RootState } from '../configureStore'

type ModalState = {
  modal:
    | ModalShowAction['payload']
    | {
        modalShown: ''
        modalPayload: null
      }
}

export const getInitialState = (): ModalState => ({
  modal: { modalShown: '', modalPayload: null },
})

export const modalSlice = createSlice({
  name: 'modal',
  initialState: getInitialState(),
  reducers: {
    clearModal(state) {
      state.modal = { modalShown: '', modalPayload: null }
    },
    showModal(state, action: PayloadAction<ModalShowAction['payload']>) {
      state.modal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ADDRESS_CHANGE, (state) => {
      state.modal = { modalShown: '', modalPayload: null }
    })
    builder.addCase(WALLET_CONNECTED, (state) => {
      state.modal = { modalShown: '', modalPayload: null }
    })
    builder.addCase(WALLET_DISCONNECT, (state) => {
      state.modal = { modalShown: '', modalPayload: null }
    })
  },
})

export const { showModal, clearModal } = modalSlice.actions

export const selectModal = (state: RootState) => state.modal.modal

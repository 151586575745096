import { useEffect } from 'react'
import { useSearchParams } from './useSearchParams'

export const useQueryParamsTracker = () => {
  const { searchParams } = useSearchParams()

  useEffect(() => {
    // refId is used by the affiliate scheme
    const paramsToSearch = ['refId']
    paramsToSearch.forEach((param) => {
      const value = searchParams.get(param)
      if (value) {
        window.localStorage.setItem(param, value)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on mount.
  }, [])
}

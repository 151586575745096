import {
  Button,
  ButtonSizes,
  ButtonVariant,
  Icon,
  IconSizes,
  Loader,
  Spacing,
  Text,
  TokenIcon,
} from '@rhinofi/dvf-shared-ui'
import { TextSize, TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import moment from 'moment'
import styled from 'styled-components'
import { clearModal } from '../../../../store/actions/modalActions/clearModal'
import { SmallSpinner } from '../../../../components/common/Helpers/SmallSpinner'
import { Modal } from '../../../../components/common/Modal/Modal'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { modalKeys } from '../../../../constants/modalKeys'
import { useConfig } from '../../../../hooks'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useExplorerLink } from '../../../../hooks/useExplorerLink'
import { translate } from '../../../../intl/i18n'
import { capitalize } from '../../../../services/helperService'
import { useGetBridgeHistoryQuery } from '../../../../store/apis/bridge.api'
import { selectIsAuthenticated } from '../../../../store/selectors/user.selectors'
import { selectModal } from '../../../../store/slices/modal.slice'
import { formatFloat } from '../../../../services/helperService/formatFloat'
import { BigDecimal } from 'effect'

export const RecentBridgeTxModal = () => {
  const dispatch = useAppDispatch()
  const { config } = useConfig()

  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const modal = useAppSelector(selectModal)
  const bridgeId = modal.modalShown === modalKeys.recentBridgeTx ? modal.modalPayload.bridgeId : null
  const { data: bridgesQueryResult } = useGetBridgeHistoryQuery(
    {
      path: {
        bridgeId: bridgeId || '',
      },
    },
    {
      skip: !isAuthenticated || !bridgeId,
      pollingInterval: 10000,
    },
  )

  if (modal.modalShown !== modalKeys.recentBridgeTx || !config) {
    return null
  }

  if (!bridgesQueryResult) {
    return (
      <Modal isVisible width="400px" onClose={() => clearModal(dispatch)()} increasedPadding>
        <Text size={TextSize.M} bold>
          {capitalize(translate('global.details'))}
        </Text>
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      </Modal>
    )
  }

  const { createdAt, amountOut, token, chainIn, chainOut, state } = bridgesQueryResult
  const depositTxHash = 'depositTxHash' in bridgesQueryResult ? bridgesQueryResult.depositTxHash : ''
  const withdrawTxHash = 'withdrawTxHash' in bridgesQueryResult ? bridgesQueryResult.withdrawTxHash : ''
  const prettyChainInName = config[chainIn]?.name || chainIn
  const prettyChainOutName = config[chainOut]?.name || chainOut

  return (
    <Modal isVisible width="400px" onClose={() => clearModal(dispatch)()} increasedPadding>
      <Text size={TextSize.M} bold>
        {capitalize(translate('global.details'))}
      </Text>
      <Spacing size="12" />
      <ChainCard>
        <ChainContainer chain={chainIn} name={prettyChainInName} txHash={depositTxHash} state={state} id="in" />
        <Icon id="arrow-right" color="secondaryDark" size={IconSizes.Big} />
        <ChainContainer chain={chainOut} name={prettyChainOutName} txHash={withdrawTxHash} state={state} id="out" />
      </ChainCard>
      <Spacing size="8" />
      <SummaryCard>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('helpers.value')}
        </Text>
        <Text size={TextSize.S} bold align={TypographyAlign.Right} id="amountOut">
          {formatFloat(BigDecimal.format(amountOut))} {token}
        </Text>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('helpers.timestamp')}
        </Text>
        <Text size={TextSize.XS} color="textSecondary" align={TypographyAlign.Right} id="createdAt">
          {createdAt ? moment(createdAt).format('HH:MM A, DD MMM YYYY') : '...'}
        </Text>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('helpers.from')}
        </Text>
        <Text size={TextSize.XS} color="textSecondary" align={TypographyAlign.Right} id="from">
          {prettyChainInName}
        </Text>
        <Text size={TextSize.XS} color="textSecondary">
          {translate('helpers.to')}
        </Text>
        <Text size={TextSize.XS} color="textSecondary" align={TypographyAlign.Right} id="to">
          {prettyChainOutName}
        </Text>
      </SummaryCard>
      <Button
        id="bridge-history-support"
        variant={ButtonVariant.secondary}
        fullWidth
        size={ButtonSizes.Large}
        onClick={() => {
          window.open('https://support.rhino.fi/en', '_blank', 'noopener noreferrer')
        }}
        iconLeft="chat-left-text"
      >
        {translate('bridge.contact_support')}
      </Button>
    </Modal>
  )
}

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 299px;
`

const SummaryCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding: 16px;
  border: ${({ theme }) => `1px solid ${theme.divider}`};
  border-radius: 8px;
  margin-bottom: 24px;
`

const ChainCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ theme }) => `1px solid ${theme.divider}`};
  border-radius: 8px;
  padding: 16px;
`

const ChainContainer = ({
  chain,
  name,
  txHash,
  state,
  id,
}: {
  chain: string
  name: string
  txHash: string
  state: string
  id: string
}) => {
  const { etherscanLink } = useExplorerLink()
  return (
    <FlexContainer $direction="column" $alignItems="center" $justifyContent="center" $gap="12px" $fullWidth>
      <div style={{ position: 'relative' }}>
        <TokenIcon token={chain} size="large" />
        <StatusIconWrapper>{getStateIcon(state)}</StatusIconWrapper>
      </div>
      <FlexContainer $direction="column" $gap="2px">
        <Text size={TextSize.S} bold id={`${id}-name`}>
          {name}
        </Text>
        {txHash !== '' ? (
          <TxLink
            href={etherscanLink(txHash, chain, true)}
            target="_blank"
            rel="noopener noreferrer"
            id={`${id}-explorer`}
          >
            {translate('helpers.view_details')}
          </TxLink>
        ) : (
          <Text id={`${id}-pending`} size={TextSize.XS}>
            {translate('helpers.pending')}...
          </Text>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

const getStateIcon = (state: string) => {
  switch (state) {
    case 'EXECUTED':
      return <Icon id="check-circle-fill" className="success-bridge" color="primaryMain" />
    case 'CANCELLED':
      return <Icon id="cross-circle-fill" className="success-bridge" color="accentRed" />
  }
  return <SmallSpinner />
}

const TxLink = styled.a`
  color: ${({ theme }) => theme.textLink};
  font-size: 12px;
  text-decoration: none;
`

const StatusIconWrapper = styled.div`
  position: absolute;
  bottom: -3px;
  right: -3px;
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 100%;
`

import { Icon, IconSizes, Text, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import type { MouseEvent, MouseEventHandler } from 'react'
import { FlexContainer } from '../../common/Wrappers/Containers'
import type { TraySubLink } from '../MobileNavigation.types'

type Props = TraySubLink & {
  handleActionClick?: MouseEventHandler
}

export const TrayLink = ({ title, path, href, icon, handleActionClick = () => {}, isExternal }: Props) => {
  const content = (
    <>
      {isExternal && (
        <ExternalLinkWrapper>
          <Icon id="arrow-up-right-square" className="tray-external-link" />
        </ExternalLinkWrapper>
      )}
      <TrayIconWrapper>
        <Icon id={icon} size={IconSizes.Big} />
      </TrayIconWrapper>
      <FlexContainer $direction="column" $alignItems="flex-start">
        <Text size={TextSize.XS} align={TypographyAlign.Center}>
          {title}
        </Text>
      </FlexContainer>
    </>
  )

  if (path) {
    return (
      <TrayWrapperLink
        to={path}
        onClick={(event: MouseEvent<HTMLElement>) => {
          handleActionClick(event)
        }}
      >
        {content}
      </TrayWrapperLink>
    )
  }

  if (href) {
    return (
      <TrayWrapperHyperLink href={href} target="_blank" rel="noopener noreferrer">
        {content}
      </TrayWrapperHyperLink>
    )
  }

  return <TrayWrapperActionLink onClick={handleActionClick}>{content}</TrayWrapperActionLink>
}

export const TrayRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const TrayLinksSection = styled.div`
  background: ${({ theme }) => theme.elevationL2};
  border-radius: 16px;
  padding: 8px;
  margin-top: 8px;
`

const TrayIconWrapper = styled.div`
  margin-bottom: 6px;
`

const linkStyles = css`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 96px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  text-decoration: none;
  user-select: none;
  background: ${({ theme }) => theme.elevationL1};
  border: 1px solid ${({ theme }) => theme.disabled};
  border-radius: 8px;
  flex: 1;

  p {
    line-height: 16px;
  }

  label {
    display: inline-block;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
  }

  & svg {
    rect {
      stroke: ${({ theme }) => theme.icon};
    }
  }
`
const TrayWrapperLink = styled(Link)`
  ${linkStyles}
`

const TrayWrapperHyperLink = styled.a`
  ${linkStyles}
`

const TrayWrapperActionLink = styled.span`
  ${linkStyles}
`

const ExternalLinkWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;

  .tray-external-link {
    color: ${({ theme }) => theme.accentBlue};
  }
`

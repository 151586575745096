import { useEffect, useMemo } from 'react'

import type { ABTestExperimentId } from '../services/ABTestService'
import { addABTestVariantUserPropertiesHeapOnAuthenticated, getABTestVariant } from '../services/ABTestService'
import { selectIsAuthenticated } from '../store/selectors/user.selectors'
import { useAppSelector } from './useAppSelector'

export const useABTest = (experimentId: ABTestExperimentId) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const variant = useMemo(() => getABTestVariant(experimentId), [experimentId])
  useEffect(() => {
    if (isAuthenticated) {
      addABTestVariantUserPropertiesHeapOnAuthenticated(experimentId, variant)
    }
  }, [isAuthenticated, experimentId, variant])

  return variant
}

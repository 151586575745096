import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { NationState } from '../types/nationSlice.types'
import { setWallet } from '../../../store/slices/user.slice'

const getInitialState = (): NationState => ({
  justMintedPassportNFT: false,
  isPollingDailyCheckin: false,
})

export const nationSlice = createSlice({
  name: 'nation',
  initialState: getInitialState(),
  reducers: {
    setJustMintedPassportNFT(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        justMintedPassportNFT: action.payload,
      }
    },
    setDailyCheckinPolling(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isPollingDailyCheckin: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setWallet, () => {
      return getInitialState()
    })
  },
})

export const { setJustMintedPassportNFT, setDailyCheckinPolling } = nationSlice.actions

import type { GemsTransactionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { clearModal } from '../../../../store/actions/modalActions/clearModal'
import { NationMissionModalContent } from './NationMissionModalContent/NationMissionModalContent'
import { Modal } from '../../../../components/common/Modal/Modal'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'

type Props = {
  gemsTransaction: GemsTransactionAPIDocument
}

export const NationCompletedMissionModal = ({ gemsTransaction }: Props) => {
  const dispatch = useAppDispatch()

  if (!('mission' in gemsTransaction)) {
    return null
  }

  return (
    <Modal variant="success" isVisible onClose={() => clearModal(dispatch)()} width="480px">
      <NationMissionModalContent
        data={{
          mission: gemsTransaction.mission,
          userData: null,
        }}
        completedOn={gemsTransaction.createdAt}
        isHistory
      />
    </Modal>
  )
}

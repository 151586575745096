import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  setAmount,
  setBridgeState,
  setChainIn,
  setChainOut,
  setRecipient,
  setToken,
  switchChains,
  resetInvalidInitialState,
} from '../../pages/Bridge/slices/bridge.slice'
import {
  getValidChains,
  isValidBridgeCombination,
  resetInvalidBridgeCombination,
  updateUrlFromState,
} from '../../utils/rtk/bridgeUrlSync'
import { configApi } from '../apis/config.api'
import type { AppDispatch, RootState } from '../configureStore'
import { selectAvailableChainsByToken } from '../selectors/bridgeConfig.selectors'

/**
 * Middleware to synchronize bridge state with the URL and validate state changes.
 */
export const bridgeListenerMiddleware = createListenerMiddleware()

bridgeListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()({
  matcher: isAnyOf(setChainIn, setChainOut, switchChains, setToken, setAmount, setRecipient, resetInvalidInitialState),
  // eslint-disable-next-line @typescript-eslint/unbound-method -- noise
  effect: (_action, { dispatch, getState }) => {
    const state = getState()
    const bridgeState = state.bridge
    const chainConfig = configApi.endpoints.getConfig.select()(state).data
    const tokenAvailableChains = selectAvailableChainsByToken(state)

    if (!isValidBridgeCombination(bridgeState, chainConfig)) {
      // If it's invalid bridge combination caused by the token change, try to set a valid combination for the new token
      if (_action.type === setToken.type || _action.type === resetInvalidInitialState.type) {
        if (bridgeState.token && tokenAvailableChains.length >= 2) {
          const { newChainIn, newChainOut } = getValidChains(tokenAvailableChains, bridgeState)

          if (newChainIn && newChainOut) {
            const newBridgeState = {
              ...bridgeState,
              chainIn: newChainIn,
              chainOut: newChainOut,
            }
            dispatch(setBridgeState(newBridgeState))
            return updateUrlFromState(newBridgeState)
          }
        }
      } else {
        // Fallback: reset to default if no valid chains are found
        return resetInvalidBridgeCombination(dispatch, 'bridgeListenerMiddleware', bridgeState, chainConfig)
      }
    }

    // Update the URL if the state is valid
    return updateUrlFromState(bridgeState)
  },
})

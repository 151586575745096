import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import InboxIcon from '../../../../assets/icons/inbox.svg?react'
import WalletIcon from '../../../../assets/icons/wallet.svg?react'
import { translate } from '../../../../intl/i18n'

type Props = {
  isConnected: boolean
}

export const BridgePortfolioPlaceholders = ({ isConnected }: Props) => {
  if (!isConnected) {
    return (
      <PlaceholderContainer>
        <WalletIcon />
        <Text size={TextSize.S} color="textDisabled">
          {translate('portfolio.connect_wallet_to_view')}
        </Text>
      </PlaceholderContainer>
    )
  }

  return (
    <PlaceholderContainer>
      <InboxIcon />
      <Text size={TextSize.S} color="textDisabled">
        {translate('portfolio.no_funds')}
      </Text>
    </PlaceholderContainer>
  )
}

const PlaceholderContainer = styled.div`
  display: flex;
  height: 226px;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

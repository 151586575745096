import styled from 'styled-components'
import { Title, TitleSize } from '@rhinofi/dvf-shared-ui'
import type { PerkAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../intl/i18n'
import { doesPerkHaveStockProperties } from '../../../../utils/doesPerkHaveStockProperties'

type Props = {
  perk: PerkAPIDocument
}

export const NationPerkModalStockClaimed = ({ perk }: Props) => {
  const stockTypePerk = doesPerkHaveStockProperties(perk)
  const noMaxStock = stockTypePerk && perk.maxStock === 0

  if (!stockTypePerk || noMaxStock) {
    return null
  }

  return (
    <FlexContainer $gap="8px" $direction="column">
      <ProgressBarContainer>
        <ProgressBar $filledPercentage={(100 * perk.currentStock) / perk.maxStock} />
      </ProgressBarContainer>
      <Title size={TitleSize.XXS}>
        {perk.currentStock === 1
          ? translate('nation.voucher_left_count', {
              '%max': perk.maxStock,
            })
          : translate('nation.vouchers_left_count', {
              '%left': perk.currentStock,
              '%max': perk.maxStock,
            })}
      </Title>
    </FlexContainer>
  )
}

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
  height: 8px;
  overflow: hidden;
  background: ${({ theme }) => theme.elevationL2};
`

export const ProgressBar = styled.div<{ $filledPercentage: number }>`
  position: absolute;
  left: 0;
  height: 8px;
  background: ${({ theme }) => theme.brand};
  width: ${({ $filledPercentage }) => $filledPercentage}%;
`

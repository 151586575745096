import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import { parseUnits } from 'ethers'
import { DVFDepositContract__factory } from '../../../contracts/DVFDepositContract'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import type { BridgeContractCallProps } from './bridge.contract'

const log = makeLog(LogFeature.BRIDGE_WIDGET)

export const callEvmBridgeContract = async ({
  ethersSigner,
  amount,
  amountWithDecimals,
  commitmentId,
  tokenAddress,
  bridgeContractAddress,
  isNativeToken,
  callback,
}: BridgeContractCallProps) => {
  const depositContract = DVFDepositContract__factory.connect(bridgeContractAddress, ethersSigner)
  if (!isNativeToken) {
    const tx = await depositContract.depositWithId(tokenAddress, amountWithDecimals, BigInt(`0x${commitmentId}`))
    log('Transaction sent:', tx)
    const receipt = await tx.wait()
    log('Transaction confirmed:', receipt)
    return receipt
  }

  const tx = await depositContract.depositNativeWithId(BigInt(`0x${commitmentId}`), {
    value: parseUnits(bigDecimalToNumericString(amount), 'ether'),
  })
  callback?.(tx.hash)
  log('Native Transaction sent:', tx)
  const receipt = await tx.wait()

  return receipt
}

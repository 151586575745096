import { Query } from '@rhinofi/effect-rtk'
import { createApiFromEffectTag } from '../../../store/appRuntime'
import { TokenDistributionClient } from './TokenDistributionClient'

export const { api: tokenDistributionApi, ApiErrorPhantom } = createApiFromEffectTag(
  TokenDistributionClient,
  { reducerPath: 'tokenDistributionApi' },
  {
    tokenDistributionUserEligibility: {
      type: Query,
    },
    tokenDistributionUserSignature: {
      type: Query,
    },
    tokenDistributionGet: {
      type: Query,
    },
  },
)

export type TokenDistributionApiError = typeof ApiErrorPhantom

export const { useTokenDistributionGetQuery } = tokenDistributionApi
export const useTokenDistributionEligibilityQuery = tokenDistributionApi.useTokenDistributionUserEligibilityQuery
export const useLazyTokenDistributionSignatureQuery = tokenDistributionApi.useLazyTokenDistributionUserSignatureQuery

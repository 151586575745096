import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { clearModal } from '../../../../../store/actions/modalActions/clearModal'
import { selectAddress } from '../../../../../store/selectors/user.selectors'
import { Modal } from '../../../../../components/common/Modal/Modal'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { useNationLocalStorage } from '../../../hooks/useNationLocalStorage'
import { useUpdateLastSeenTransactionsMutation } from '../../../services/nation.api'
import { useGemsTransactions } from '../../../hooks/useGemsTransactions'
import { NATION_STORAGE_KEYS } from '../../../constants/nationGeneral.constants'
import { NationOnboardingModalSteps } from './NationOnboardingModalSteps/NationOnboardingModalSteps'
import { NationOnboardingModalStepsBar } from './NationOnboardingModalStepsBar'
import { modalSteps } from './NationOnboardingModal.steps'

export const NationOnboardingModal = () => {
  const dispatch = useAppDispatch()
  const address = useAppSelector(selectAddress)
  const { addAddress } = useNationLocalStorage({ storageKey: NATION_STORAGE_KEYS.ONBOARDING })
  const [modalStepIndex, setModalStepIndex] = useState(0)
  const [updateLastSeenTransactionsMutation] = useUpdateLastSeenTransactionsMutation()

  const { data: gemsTxs } = useGemsTransactions({ input: { type: 'EARNED', latest: true } })

  const completedMissions = useMemo(() => {
    const allMissions =
      gemsTxs?.items.map((tx) => ('mission' in tx ? tx.mission : null)).filter((mission) => mission != null) ?? []
    const customMissions = allMissions.filter((mission) => mission?.type === 'CUSTOM')
    const missions = customMissions.length ? customMissions : allMissions
    const totalGemsEarned = missions.reduce((acc, mission) => acc + mission.gemsAwarded, 0) ?? 0

    return { missions, totalGemsEarned }
  }, [gemsTxs])

  const handleOnClose = () => {
    if (address) {
      addAddress(address)
    }
    if (gemsTxs && '0' in gemsTxs.items) {
      void updateLastSeenTransactionsMutation({
        cursor: gemsTxs.items[0].timestamp,
      })
    }
    clearModal(dispatch)()
  }

  const handleStepChange = (stepIndex: number) => {
    setModalStepIndex(stepIndex)
  }

  const handleNextStep = () => {
    if (modalStepIndex === modalSteps.length - 1) {
      handleOnClose()
    } else {
      setModalStepIndex(modalStepIndex + 1)
    }
  }

  return (
    <Modal isVisible onClose={handleOnClose} width="95vw" maxWidth="956px" closeOnOverlayClick={false}>
      <ModalContentWrapper $direction="column" $alignItems="center">
        <NationOnboardingModalStepsBar
          totalSteps={modalSteps.length}
          currentStep={modalStepIndex}
          onStepChange={handleStepChange}
        />

        <NationOnboardingModalSteps
          modalStepIndex={modalStepIndex}
          data={completedMissions}
          onPrevStep={() => handleStepChange(modalStepIndex - 1)}
          onNextStep={handleNextStep}
        />
      </ModalContentWrapper>
    </Modal>
  )
}

const ModalContentWrapper = styled(FlexContainer)`
  height: 499px;
  @media ${respondBelow('xs')} {
    min-height: 528px;
    height: 100%;
    max-height: calc(100vh - 140px);
  }
`

import { getTokenDistributionsConfig } from './getTokenDistributionsConfig'

export const getDefaultTokenDistribution = (): { chain: string; distributionId: number } => {
  const config = getTokenDistributionsConfig()

  const chains = Object.keys(config)
  const defaultChain = chains?.[0]

  if (!defaultChain || !config[defaultChain]?.[0]) {
    throw new Error('No token distribution configured.')
  }

  return {
    chain: defaultChain,
    distributionId: config[defaultChain][0],
  }
}

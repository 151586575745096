import { useState, useCallback } from 'react'
import { Title, TitleSize, Button, TypographyAlign } from '@rhinofi/dvf-shared-ui'
import type { VoucherAPIDocument, PerkAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { FlexContainer } from '../../../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../../../intl/i18n'
import { Copy } from '../../../../../../../components/common/Helpers/Copy'
import { NoUnderlineAnchor } from '../../../../../../../components/common/Helpers/LinkHelpers'
import { BrandBorder } from '../../../../../../../components/BrandBorder'
import { createPerkHeapId } from '../../../../../utils/createPerkHeapId'
import { ActionContainer, CodeContainer, ClickContainer } from './NationPerkModalVoucherCode.styled'

type Props = {
  voucher: VoucherAPIDocument | null
  perk: PerkAPIDocument
}

export const NationPerkModalVoucherCode = ({ voucher, perk }: Props) => {
  const [revealed, setRevealed] = useState(false)

  const hasVoucher = voucher !== null
  const canRevealCode = hasVoucher && !revealed

  const onCodeClickCallback = useCallback(() => {
    if (!canRevealCode) {
      return
    }

    setRevealed(true)
  }, [canRevealCode])

  const perkId = createPerkHeapId(perk)

  return (
    <FlexContainer $gap="16px">
      {hasVoucher ? (
        <>
          <ClickContainer $clickable={canRevealCode} onClick={onCodeClickCallback}>
            <BrandBorder $turnedOn>
              <CodeContainer $hasBorder={false} $padding="6px">
                <Title size={TitleSize.XXS} align={TypographyAlign.Center}>
                  {revealed ? voucher.code : translate('nation.click_to_reveal_code')}
                </Title>
              </CodeContainer>
            </BrandBorder>
          </ClickContainer>

          <ActionContainer>
            {voucher.link ? (
              <NoUnderlineAnchor
                id={`perk-voucher-external-link-${perkId}`}
                href={voucher.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button id="perk-voucher-link">{translate('helpers.use')}</Button>
              </NoUnderlineAnchor>
            ) : (
              <Copy text={voucher.code} hideIcon showTop copiedText={translate('nation.code_copied')}>
                <Button id={`perk-voucher-code-copy-${perkId}`}>{translate('nation.copy_code')}</Button>
              </Copy>
            )}
          </ActionContainer>
        </>
      ) : (
        <CodeContainer>
          <Title size={TitleSize.XXS} align={TypographyAlign.Center}>
            {translate('nation.your_code_will_appear_here')}
          </Title>
        </CodeContainer>
      )}
    </FlexContainer>
  )
}

import type { PublicQuoteQuerySchema } from '@rhinofi/bridge-api-spec'
import type { FormikErrors } from 'formik'
import { useMemo } from 'react'
import { useAppSelector } from '../../../hooks'
import { selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { selectBridge } from '../slices/bridge.slice'
import type { BridgeFormValues } from '../types/bridge-widget.types'
import { usePublicQuote } from './usePublicQuote'
import { useUserQuote } from './useUserQuote'

type UseQuoteInput = {
  mode?: PublicQuoteQuerySchema['mode']
  gasBoostAmountNative?: string
  errors: FormikErrors<BridgeFormValues> | undefined
}

export const useQuote = ({ mode = 'receive', gasBoostAmountNative, errors }: UseQuoteInput = { errors: undefined }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const { isOtherAddress, recipient } = useAppSelector(selectBridge)

  const shouldUsePublicQuote = useMemo(() => {
    const hasRecipientError = errors?.recipient !== undefined
    const isRecipientEmpty = isOtherAddress && recipient === ''
    return !isAuthenticated || isRecipientEmpty || hasRecipientError
  }, [isAuthenticated, isOtherAddress, recipient, errors?.recipient])

  const publicQuote = usePublicQuote({ mode, gasBoostAmountNative, skip: !shouldUsePublicQuote })
  const userQuote = useUserQuote({ mode, gasBoostAmountNative, skip: shouldUsePublicQuote })

  return shouldUsePublicQuote ? publicQuote : userQuote
}

import { Text, TokenIcon, TextSize } from '@rhinofi/dvf-shared-ui'
import { useAppSelector } from '../../../hooks/useAppSelector'
import {
  selectSecondaryWalletAddress,
  selectSecondaryWalletChain,
} from '../../../store/selectors/secondaryWallet.selectors'
import { shortenAddress } from '../../../services/helperService/shortenAddress'
import { FlexContainer } from '../../common/Wrappers/Containers'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { defaultThreshold } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'

export const NonEVMConnected = () => {
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)
  const chain = useAppSelector(selectSecondaryWalletChain)
  const { width } = useIsMobile()

  if (!secondaryWalletAddress || width < defaultThreshold.xl) {
    return null
  }

  return (
    <FlexContainer $gap="8px" className="low-priority-display">
      <Text color="strokeDivider">| </Text>
      <TokenIcon token={chain} customSize="20px" />
      <Text size={TextSize.S}>{shortenAddress(secondaryWalletAddress, false, 5)}</Text>
    </FlexContainer>
  )
}

import { Schema } from 'effect'
import { ethers } from 'ethers'
import { getNFTContractConfig } from './nftContractHelpers'
import type { EVMNFTType } from '../types/mintNFT.types'
import { ERC1155SingleNFTConfigSchema, NFTContractTypeSchema } from '../types/mintNFT.schemas'
import { getChainConfig } from '../../../utils/getChainConfig'
import { RhinoERC1155__factory } from '../../../contracts/RhinoERC1155'
import { NFTMinter__factory } from '../../../contracts/NFTMinter'
import { NFTReward__factory } from '../../../contracts/NFTReward'
import { RhinoFiWrappedNFT__factory } from '../../../contracts/RhinoFiWrappedNFT'
import type { BridgeConfig } from '../../../contexts'

export const canMintNFT = async ({
  chain,
  nftType,
  user,
  config,
}: {
  chain: string
  nftType: EVMNFTType
  user: string
  config: BridgeConfig
}): Promise<boolean> => {
  const contractConfig = getNFTContractConfig(chain, nftType)
  const contractAddress = contractConfig.address

  const { rpc, networkId, name } = getChainConfig(config)({ chain })
  const network = new ethers.Network(name, networkId)
  const rpcProvider = new ethers.JsonRpcProvider(rpc, network, { staticNetwork: true })

  if (contractConfig.type === NFTContractTypeSchema.enums.RhinoERC1155) {
    const { tokenId } = Schema.decodeUnknownSync(ERC1155SingleNFTConfigSchema)(contractConfig)
    const contract = RhinoERC1155__factory.connect(contractAddress, rpcProvider)

    const userMintedTimes = await contract.balanceOf(user, tokenId)
    const { maxBalancePerUser } = await contract.tokens(tokenId)

    return userMintedTimes < maxBalancePerUser
  }

  const contractFactoriesPerType = {
    NFTMinter: NFTMinter__factory,
    NFTReward: NFTReward__factory,
    RhinoFiWrappedNFT: RhinoFiWrappedNFT__factory,
  }

  const contractFactoryForType = contractFactoriesPerType[contractConfig.type]

  if (!contractFactoryForType) {
    throw new Error(`No factory available for type: ${contractConfig.type}`)
  }

  const contract = contractFactoryForType.connect(contractAddress, rpcProvider)
  const userTokenIds = await contract.getUserTokenIds(user)

  return userTokenIds.length < 1
}

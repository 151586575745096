import type { FFlag } from '../constants/types'
// import { MilestoneCampaignID } from '../pages/MilestoneCampaign/types/milestoneCampaign.types'

export const configEnv = 'production'

export const VITE_HEAP_ANALYTICS_ID = '3649556247'

export const tradingApi = 'https://api.rhino.fi'

export const etherscanApiUrl = 'https://api.etherscan.io'
export const geoblockingApiUrl = 'https://api.rhino.fi/gl'

export const multiCallDoNotAggregate = ['BINANCE']

export const network = 1

// Referencing tokens supporting https://eips.ethereum.org/EIPS/eip-2612
// for which implementation can vary slighly (hence the need for a config)
// For now, we need the "version" field for each token which can be different
// depending on the token - although specification doesn't explain underlying logic
// Adding new tokens here will make the UI switch to the "permit" model
// (vs. the legacy separate "approve" transaction) when allowance is not set.
export const tokenPermitConfig = {
  ETHEREUM: {
    DVF: {
      version: '1',
    },
    // AAVE: {
    //   version: '1',
    //   nonceGetter: (dvf: DvfClientInstance, tokenAddress: string, ownerAddress: string) =>
    //     dvf.contract.getPermitNonceWithUnderscoreForAddress(tokenAddress, ownerAddress),
    // },
    USDC: {
      version: '2',
    },
  },
}

export const restrictedCountries = ['CU', 'PK', 'KP', 'IR', 'SY', 'VE', 'IQ', 'LK', 'TT', 'TN', 'VU', 'YE', 'BA', 'UG']

export const sentryUrl = 'https://04a752a90685428089ad446361ff75b5@o417796.ingest.sentry.io/5381732'

export const sentryProject = 'dvf-portal-production'

export const appURL = 'app.rhino.fi'

export const cloudFlareWebAnalyticsScript = true

export const heapApiUrl = 'https://heapanalytics.com/api/track'
export const heapAppId = 3649556247

export const FEATURE_FLAGS: Record<FFlag, boolean> = {
  BRIDGE_PORTFOLIO_TAB: true,
  BRIDGE_REWARDS_CAMPAIGN: false,
  CLAIM_MILESTONE_CAMPAIGN: true,
  CONTRACTS_INTERACTIONS: true,
  DEV_DELAY_STARTUP: false,
  DEV_ENABLE_SENTRY: true,
  DEV_ENCHANCE_SENTRY: true,
  DEV_HIDDEN_ACTIONS: false,
  DEV_LOG_TRACKING_EVENTS: false,
  STARKNET_ARGENT_MOBILE: false,
  WRAPPED_2023: false,
  NATION_FREE_MINT: true,
  NATION_WIDGET: true,
  ZIRCUIT_DOPE: false,
  INK_NFT: true,
  MEMECOIN_TRACKER: true,
  TOKEN_DISTRIBUTION: false,
  CHAIN_PICKER_COMBINED_BALANCES: false,
}
export const NATION_FREE_MINT_END_TIME = '2024-11-06T10:00:00.000Z'
export const NATION_WIDGET_PROMO_PERK_ID = '66dae33a0c9cf141292e29e3'

export const userflowTokenId = 'ct_3iq7mtd7gvgxpo7t4xbqjp2mmy'

export const environment = 'prd'

export const reCaptchaSiteKey = '6LfRZL4qAAAAAHnQxmG-i0iZjzffTH5mXwgFZ2BI'

export const zknsApi = 'https://omniapi.zkns.app/domain-resolver'
export const starknetApi = 'https://api.starknet.id'

export const activityTrackers: [string, ...string[]] = ['SCROLL', 'LINEA', 'TAIKO', 'BASE', 'ZKEVM']

export const CHAINS_WITH_DUNE_DATA = ['BASE', 'SCROLL', 'ZKSYNC']

export const projectsRecommendationsUrl = 'https://splendorous-selkie-a951a2.netlify.app/prod-json.json'

export const enabledNFTMinters = ['ZIRCUIT', 'LINEA', 'SCROLL', 'ZKEVM', 'TAIKO', 'BASE', 'INK']

export const nftsContracts = {
  ZKSYNC: {
    hunter: {
      type: 'NFTMinter',
      address: '0x812dE7B8cC9dC7ad5Bc929d3337BFB617Dcc7949',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x5a8694AC23114aE97fdA5828839888f340c93aA9',
    },
    wrapped2023: {
      type: 'RhinoFiWrappedNFT',
      address: '0x3720e4EBcc953e5b754029F3d0B382607a7dF898',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0x05794967eEDf78f2A7BE931e6c329d839379a597',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0x8Ef0b6A7bfe84e4DA55d7c70e5F556F135527B7C',
    },
  },
  INK: {
    hunter: {
      type: 'RhinoERC1155',
      address: '0x1Df2De291F909baA50C1456C87C71Edf9Fb199D5',
      tokenId: 1,
    },
  },
  LINEA: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0332fCE92897679E97620A097aa515F5d7CF4034',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
    },
  },
  SCROLL: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0332fCE92897679E97620A097aa515F5d7CF4034',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
    },
    ALPHAKEY: {
      type: 'NFTReward',
      address: '0x16539E3CDc43c5b8e6De0511Aa81F6FA7248A5Eb',
    },
    BETAKEY: {
      type: 'RhinoERC1155',
      address: '0xCe39f0499bf040F351f2e0C3F2c1AE81eA612c47',
      tokenId: 1,
      reducedFeesContract: '0x16539E3CDc43c5b8e6De0511Aa81F6FA7248A5Eb',
    },
  },
  ZKEVM: {
    hunter: {
      type: 'NFTMinter',
      address: '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x5752d091d99d8A05CD972DE070008de750341251',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0xEcE6806C469591A8665e27139E798CaD0c4D1ACD',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0x93B5D7050768a82d505dFc25764625Aa545e2145',
    },
  },
  MANTA: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0332fCE92897679E97620A097aa515F5d7CF4034',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
    },
  },
  BLAST: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0332fCE92897679E97620A097aa515F5d7CF4034',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
    },
  },
  TAIKO: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0dbBD1bB03Ed63AE2beA0Ce892567884dffb70a5',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x9F142e45deC67330a95711fFEB8E62fBfce6bB7b',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0xc0294AA9eFf33C369fa93EbcEBb86aEC6A0b8080',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0xf4C2696097c6B195a85802CEB3f45fDE4B2d41C3',
    },
  },
  ZIRCUIT: {
    hunter: {
      type: 'NFTMinter',
      address: '0x93ddFeFE47F5AcFb454e1e52e7a520857D98cF8A',
    },
  },
  BASE: {
    hunter: {
      type: 'RhinoERC1155',
      address: '0x65F154D1e8cF617Aa3CB0983500F27252267eE81',
      tokenId: 1,
    },
    TOP30: {
      type: 'RhinoERC1155',
      address: '0x65F154D1e8cF617Aa3CB0983500F27252267eE81',
      tokenId: 2,
    },
  },
}

export const nftsThresholds = {
  minRanking: 0.7,
  minVolume: 1, // ETH
  minTxCount: 25,
}

export const chainbaseApi = 'https://api.chainbase.online/v1/account/nfts'
export const chainbaseApiKey = '2YtDOftRHb56xrsHy38QgImUx4b'

export const turnstileSiteKey = '0x4AAAAAAANzc6wYxGiDCSHY'

export const airdropEligibilityCriteria = {
  distinctMonths: [2, 6, 9],
  txVolume: [10_000, 50_000, 250_000],
  txCount: [4, 10, 25, 100],
}

export const GM_CONTRACT_FEE_DEPLOY_WEI = 335000000000000 // 0.000335 ETH = $1 USD (2976.13 $ Per Ether)
export const GM_CONTRACT_FEE_GM_WEI = 33500000000000 // 0.0000335 = 0.1$ USD (2976.13 $ Per Ether)
export const CONTRACTS_INTERACTIONS_CHAINS_CONFIG = {
  INK: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  TAIKO: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  SCROLL: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  ZKSYNC: {
    feeReceiver: '0x4F170ae33F4e8950a969Ca495544f91254eEA64A',
  },
  LINEA: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  ZKEVM: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  BASE: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
}

export const ENABLED_CI_CHAINS = ['SCROLL', 'TAIKO', 'LINEA', 'ZKEVM', 'BASE', 'INK']

export const ACTIVITY_TRACKER_CHAIN_EXPLORER_CONFIG_MAPPING = {
  ZKSYNC: {
    url: 'https://block-explorer-api.mainnet.zksync.io/api',
    apiKeys: [],
  },
  SCROLL: {
    url: 'https://api.scrollscan.com/api',
    apiKeys: [
      'QY8GXBS6JS6SZK3UZGA8NNEZNG4VXY3EKH',
      'T3FP647QXSNY3NXAFZ5N8D5YV73KNGNQH6',
      'V1146R9WFANII3UYZHGGYFXGSZ2M3G4XFX',
    ],
  },
  LINEA: {
    url: 'https://api.lineascan.build/api',
    apiKeys: [
      'I9288NCYFK93BZ3C3RAVR7DP7TNF4MT9KS',
      'IJTRDSQG19PA62HZ8W2K5F8ADY918EU1NA',
      'HPAQXCBU7GBQR235HP7ND4CICEIQ8MFNMW',
    ],
  },
  BLAST: {
    url: 'https://api.blastscan.io/api',
    apiKeys: [
      'IIX1D348KFXG9N42Q4HKXSW1CSABBJ5ZYW',
      'WSQSS1DR6ZBIMEZ3WINA36E2N985PPIK29',
      '75GDGG4UE938UNCW9B3C5PDN4IS82GFQGC',
    ],
  },
  TAIKO: {
    url: 'https://api.taikoscan.io/api',
    apiKeys: [
      'W6WBWKDJG43H9SUY9GGQIVADW5BPG6ASBC',
      'HN9A5Y5YIGPF1TNFHKDVIKCVFZNFGC7X5N',
      '7STPGF5IN32TWW2ZEPVIEGGF1T59W6YK3S',
    ],
  },
}

export const ACTIVITY_TRACKER_PROJECTS_CONTRACTS = {
  RHINOFI: {
    ZKSYNC: [
      '0x812dE7B8cC9dC7ad5Bc929d3337BFB617Dcc7949',
      '0x5a8694AC23114aE97fdA5828839888f340c93aA9',
      '0x3720e4EBcc953e5b754029F3d0B382607a7dF898',
      '0x05794967eEDf78f2A7BE931e6c329d839379a597',
      '0x8Ef0b6A7bfe84e4DA55d7c70e5F556F135527B7C',
      '0x1fa66e2B38d0cC496ec51F81c3e05E6A6708986F',
    ],
    SCROLL: [
      '0x0332fCE92897679E97620A097aa515F5d7CF4034',
      '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
      '0x87627c7e586441eef9ee3c28b66662e897513f33',
    ],
    LINEA: [
      '0x0332fCE92897679E97620A097aa515F5d7CF4034',
      '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
      '0xcf68a2721394dcf5dcf66f6265c1819720f24528',
    ],
    BLAST: [
      '0x5e023c31e1d3dcd08a1b3e8c96f6ef8aa8fcacd1',
      '0x0332fCE92897679E97620A097aa515F5d7CF4034',
      '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
    ],
    TAIKO: [
      '0x1df2de291f909baa50c1456c87c71edf9fb199d5',
      '0x0dbBD1bB03Ed63AE2beA0Ce892567884dffb70a5',
      '0x9F142e45deC67330a95711fFEB8E62fBfce6bB7b',
      '0xc0294AA9eFf33C369fa93EbcEBb86aEC6A0b8080',
      '0xf4C2696097c6B195a85802CEB3f45fDE4B2d41C3',
    ],
  },
  SYNCSWAP: {
    ZKSYNC: [
      '0x621425a1Ef6abE91058E9712575dcc4258F8d091',
      '0xbB05918E9B4bA9Fe2c8384d223f0844867909Ffb',
      '0xf2DAd89f2788a8CD54625C60b55cD3d2D0ACa7Cb',
      '0x5b9f21d407F35b10CbfDDca17D5D84b129356ea3',
      '0x2da10A1e27bF85cEdD8FFb1AbBe97e53391C0295',
      '0xFdFE03bAE6B8113Ee1002d2bE453Fb71CA5783d3',
      '0xFdFE03bAE6B8113Ee1002d2bE453Fb71CA5783d3',
      '0x0a34FBDf37C246C0B401da5f00ABd6529d906193',
      '0x81251524898774F5F2FCaE7E7ae86112Cb5C317f',
      '0x81251524898774F5F2FCaE7E7ae86112Cb5C317f',
      '0x20b28B1e4665FFf290650586ad76E977EAb90c5D',
      '0xC6DaC3A53D5d6dE9D1D05AA6e28B8e9E41722601',
    ],
    SCROLL: [
      '0x7160570BB153Edd0Ea1775EC2b2Ac9b65F1aB61B',
      '0x608Cb7C3168427091F5994A45Baf12083964B4A3',
      '0x37BAc764494c8db4e54BDE72f6965beA9fa0AC2d',
      '0xE4CF807E351b56720B17A59094179e7Ed9dD3727',
      '0x80e38291e06339d10AAB483C65695D004dBD5C69',
      '0x12AF3Ec993EC5d5bD789b3e989c9E95A2F6c586D',
      '0xa2A09f15c2ec6aF1b8f9413c148334b231410bd8',
    ],
    LINEA: [
      '0x7160570BB153Edd0Ea1775EC2b2Ac9b65F1aB61B',
      '0x608Cb7C3168427091F5994A45Baf12083964B4A3',
      '0x37BAc764494c8db4e54BDE72f6965beA9fa0AC2d',
      '0xE4CF807E351b56720B17A59094179e7Ed9dD3727',
      '0x80e38291e06339d10AAB483C65695D004dBD5C69',
      '0x12AF3Ec993EC5d5bD789b3e989c9E95A2F6c586D',
    ],
  },
  MUTEIO: {
    ZKSYNC: [
      '0x8B791913eB07C32779a16750e3868aA8495F5964',
      '0x40be1cBa6C5B47cDF9da7f963B6F761F4C60627D',
      '0x99864455cAb18354a9D2e41c66A9B6E869ba218C',
      '0x1dD55B7DEd83Be6A308E64b0711493fE6E38E2D2',
      '0x96c2Cf9edbEA24ce659EfBC9a6e3942b7895b5e8',
    ],
  },
  MAVERICK: {
    ZKSYNC: [
      '0x2C1a605f843A2E18b7d7772f0Ce23c236acCF7f5',
      '0xFd54762D435A490405DDa0fBc92b7168934e8525',
      '0x852639EE9dd090d30271832332501e87D287106C',
      '0x57D47F505EdaA8Ae1eFD807A860A79A28bE06449',
      '0x17132CE52D40248F5077f4F51C6E3BDf7682749F',
      '0x0e70CA6F0F1a96aBAA4BFB2CD4aC113aF3d4a5a3',
      '0x39E098A153Ad69834a9Dac32f0FCa92066aD03f4',
      '0x787c09494Ec8Bcb24DcAf8659E7d5D69979eE508',
      '0x7EDcB053d4598a145DdaF5260cf89A32263a2807',
    ],
  },
  ZKNS: {
    ZKSYNC: ['0x068DC5A04AD0e6bAbd6b4782b8D13c2fB4107BdA', '0xAE23B6E7f91DDeBD3B70d74d20583E3e674Bd94f'],
  },
  DERIVIO: {
    ZKSYNC: [
      '0x240D5645bFFAF6f8Bc3586e459A4155F270BCb3b',
      '0x104ACF59009759296CD7127B52cFC671d7954222',
      '0x878779D32D3FCac4543c5f1EdE6b94b061933c77',
      '0xfc00dAC251711508D4dD7b0C310e913575988838',
      '0x142cF97Ac1a40dFEBCCa488607Ea99D0eABDfe8c',
    ],
  },
  ERALEND: {
    ZKSYNC: [
      '0x7c033119b37Ff830C1817361b9AAb64E6Af6C465',
      '0xC955d5fa053d88E7338317cc6589635cD5B2cf09',
      '0x8F409cF3697ed7BcE56cfE55DEc1Fc38656535a3',
      '0x90973213E2a230227BD7CCAfB30391F4a52439ee',
      '0x22D8b71599e14F20a49a397b88c1C878c86F5579',
      '0x74b23Aa73466eF3206a87d9438B717153a9947A4',
      '0x7B163Bc36c6F50244138780b2443376cE65ffb74',
    ],
  },
  AMBIENT: {
    SCROLL: [
      '0xaaaaAAAACB71BF2C8CaE522EA5fa455571A74106',
      '0x70b161F2f0A18Bd1865021F25f9e895021E9DC4f',
      '0x62223e90605845Cf5CC6DAE6E0de4CDA130d6DDf',
      '0xc2c301759B5e0C385a38e678014868A33E2F3ae3',
      '0xfB5f26851E03449A0403Ca945eBB4201415fd1fc',
      '0xED5535C6237f72BD9b4fDEAa3b6D8d9998b4C4e4',
      '0x1E0cc2fbEb09e320223A380357978d651ed652bC',
      '0xDb0eE1193C4D05eb644efb2a1db13275b8F5994f',
      '0x81956099675d25363d17B983125dD99269A9f26F',
      '0x51D3BA9CA9a120dA0BCf8b487Bd42878758f7916',
    ],
  },
  KYBER: {
    ZKSYNC: [
      '0x3F95eF3f2eAca871858dbE20A93c01daF6C2e923',
      '0x6ee46Cb7cD2f15Ee1ec9534cf29a5b51C83283e6',
      '0x937f4f2FF1889b79dAa08debfCA5C237a07A5208',
    ],
    SCROLL: [
      '0xC7a590291e07B9fe9E64b86c58fD8fC764308C4A',
      '0xe222fBE074A436145b255442D919E4E3A6c6a480',
      '0xD8ac7f696Ae99CD7B689726Cf03c5711Dd8DcDb4',
      '0x049BeffDB026D6D1D64cFc8fE29Bd12142967B09',
      '0x58f1d0F9bFf9D695010C92FB93d100CeF5113f3E',
      '0x98565FcAD2080C5c19C3136fa367cE371cD40bD6',
      '0xF9c2b5746c946EF883ab2660BbbB1f10A5bdeAb4',
      '0x4d47fd5a29904Dae0Ef51b1c450C9750F15D7856',
      '0x8Fd8Cb948965d9305999D767A02bf79833EADbB3',
      '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
      '0x2475039bF2ECDCe2EE4C82954e64bE9674f43546',
      '0x7D5ba536ab244aAA1EA42aB88428847F25E3E676',
      '0x3D6AfE2fB73fFEd2E3dD00c501A174554e147a43',
      '0x6AFeb9EDd6Cf44fA8E89b1eee28284e6dD7705C8',
      '0x608ef9A3BffE206B86c3108218003b3cfBf99c84',
      '0x30C5322E4e08AD500c348007f92f120ab4E2b79e',
      '0xf0096e5B4AAfeEA1DF557264091569ba125c1172',
      '0x2abe8750e4a65584d7452316356128c936273e0d',
      '0x5649b4dd00780e99bab7abb4a3d581ea1aeb23d0',
    ],
    LINEA: [
      '0xe222fBE074A436145b255442D919E4E3A6c6a480',
      '0xD8ac7f696Ae99CD7B689726Cf03c5711Dd8DcDb4',
      '0x049BeffDB026D6D1D64cFc8fE29Bd12142967B09',
      '0xF9c2b5746c946EF883ab2660BbbB1f10A5bdeAb4',
      '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
      '0x2475039bF2ECDCe2EE4C82954e64bE9674f43546',
      '0x7D5ba536ab244aAA1EA42aB88428847F25E3E676',
      '0x6AFeb9EDd6Cf44fA8E89b1eee28284e6dD7705C8',
      '0x3D6AfE2fB73fFEd2E3dD00c501A174554e147a43',
      '0x6AFeb9EDd6Cf44fA8E89b1eee28284e6dD7705C8',
      '0xcab2FA2eeab7065B45CBcF6E3936dDE2506b4f6C',
      '0x3b2F62d42DB19B30588648bf1c184865D4C3B1D6',
      '0x5649B4DD00780e99Bab7Abb4A3d581Ea1aEB23D0',
      '0x1c758aF0688502e49140230F6b0EBd376d429be5',
      '0x2abE8750e4a65584d7452316356128C936273e0D',
    ],
  },
  XY: {
    ZKSYNC: [
      '0x30E63157bD0bA74C814B786F6eA2ed9549507b46',
      '0xe4e156167cc9C7AC4AbD8d39d203a5495F775547',
      '0x75167284361c8D61Be7E4402f4953e2b112233cb',
      '0x935283A00FBF8E40fd2f8C432A488F6ADDC8dB67',
    ],
    SCROLL: [
      '0x22bf2A9fcAab9dc96526097318f459eF74277042',
      '0x2f6Ccc4a900Ee42f822892b8c024aAA08af89701',
      '0x296A85243923A16799Da7849C39318ff51B75Ed7',
      '0x9d90CFa17f3AFceE2505B3e9D75113e6f5c9E843',
      '0x778C974568e376146dbC64fF12aD55B2d1c4133f',
      '0x1B0789910027c3CC58af2391de7228d973c5C46E',
      '0xF526EFc174b512e66243Cb52524C1BE720144e8d',
      '0x3689D3B912d4D73FfcAad3a80861e7caF2d4F049',
      '0x0241fb446d6793866245b936F2C3418F818bDcD3',
      '0x9fE77412aA5c6Ba67fF3095bBc534884F9a61a38',
      '0x73Ce60416035B8D7019f6399778c14ccf5C9c7A1',
      '0x50a64d05bB8618D8d96A83CbBb12b3044ec3489A',
    ],
    LINEA: [
      '0xc693C8AAD9745588e95995fef4570d6DcEF98000',
      '0x73Ce60416035B8D7019f6399778c14ccf5C9c7A1',
      '0xA5Cb30E5d30A9843B6481fFd8D8D35DDED3a3251',
      '0x9d90CFa17f3AFceE2505B3e9D75113e6f5c9E843',
    ],
    BLAST: [
      '0x43A86823EBBe2ECF9A384aDfD989E26A30626458',
      '0x73Ce60416035B8D7019f6399778c14ccf5C9c7A1',
      '0xFa77c2DecCB21ACb9Bf196408Bf6aD5973D07762',
    ],
  },
  ZEBRA: {
    SCROLL: ['0x0122960d6e391478bfe8fb2408ba412d5600f621', '0xca9fbef94d7cc8662f4711a8612ff9dc1526e650'],
  },
  IZUMI: {
    SCROLL: [
      '0x2db0afd0045f3518c77ec6591a542e326befd3d7',
      '0xaa596e921f7a75a05277fdb3c7f88428905d3da3',
      '0x1502d025bfa624469892289d45c0352997251728',
    ],
    TAIKO: ['0x04830cfCED9772b8ACbAF76Cfc7A630Ad82c9148', '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF'],
  },
  GRIZZLY: {},
  UNISWAP: {},
  LAYER_BANK: {
    SCROLL: [
      '0x2A00647F45047f05BDed961Eb8ECABc42780e604',
      '0x4e3A9D352Cc89B18DFDDc6aF1b578fcB2cB6D0bA',
      '0xD387327E3eCE634f34B045Ed506Ed1554733d09F',
      '0xF1F897601A525F57c5EA751a1F3ec5f9ADAc0321',
      '0x20112c6128550e13600D52200A5f185c6aaE4e42',
      '0x615B3FA9e6546Cd95864ca6C8A7f66B9A6545778',
      '0xEC53c830f4444a8A56455c6836b5D2aA794289Aa',
      '0x760bd7Fc100F217678D1b521404D2E93Db7Bec5F',
      '0x18941f10B29a7F6d5290F3B1D5fcC7ae9f6f8DF4',
      '0x274C3795dadfEbf562932992bF241ae087e0a98C',
      '0x0D8F8e271DD3f2fC58e5716d3Ff7041dBe3F0688',
      '0xB6966083c7b68175B4BF77511608AEe9A80d2Ca4',
      '0x119F312c25a1cEb9e6708660A1D47D4034BB8441',
    ],
    LINEA: [
      '0xB97F21D1f2508fF5c73E7B5AF02847640B1ff75d',
      '0x57d8311842F41ec811352F5d68616Fd51551a391',
      '0x5D06067f86946620C326713b846DdC8B97470957',
      '0xEA01659B66A682d41f4718bB62134Ea8109fBfC6',
      '0x009a0b7C38B542208936F1179151CD08E2943833',
      '0x4F5F443fEC450fD64Dce57CCacE8f5ad10b4028f',
      '0xC690549E0215192D1fFB527BB3ca4D4Ba638Cad2',
      '0x709bE039a87e5C6F1A085dE0d167fa0A4e7477FC',
      '0xc7D8489DaE3D2EbEF075b1dB2257E2c231C9D231',
      '0x2aD69A0Cf272B9941c7dDcaDa7B0273E9046C4B0',
      '0xEa0F73296a6147FB56bAE29306Aae0FFAfF9De5F',
      '0xE33520c74bac3c537BfEEe0F65e80471F3d564b9',
      '0x14C5A2b562754eD412708a103FB9cB65B1b16494',
      '0xaa596e921f7a75a05277fdb3c7f88428905d3da3',
      '0x43eac5bfea14531b8de0b334e123ea98325de866',
    ],
  },
  SKYDROME: {
    SCROLL: [
      '0x74B8738862E4814C6E6D6e0202F8386685ca7B9D',
      '0x03290A52BA3164639067622E20B90857eADed299',
      '0xC9508CaC5cE23227Cd8Ecd60f3F25007e0a66E12',
      '0x91F3eBc0a4Ca08c295C95ca3CdE5954a862cC13B',
      '0x0Aa1fAF2B59d424c1dC5Ac17C4b5290D023d1f8c',
      '0x5BaFC7f9c0CBEbe1FaE9Fc6C39E9BBe497Fa6C22',
      '0x654d1A48EDc66a9Bb9D873517B567B6890571c9d',
      '0x2Fc62C39b4137213DC4C0b35a34B292dffAA2208',
      '0xa533488a22E904ed1616A19Ea2359E0428Fff51b',
      '0x1E043521C6B9853E4C9E8c69a93C267D7ffc3b6b',
      '0x2516212168034b18a0155FfbE59f2f0063fFfBD9',
      '0xAA111C62cDEEf205f70E6722D1E22274274ec12F',
      '0x74CAE2839919f0493E7f3a53A284C42197dF9616',
    ],
  },
  SNS: {
    SCROLL: ['0x9592af1844e7d267d20eeb1a7f0f3da514918462'],
  },
  PANCAKE_SWAP: {
    ZKSYNC: [
      '0x5aEaF2883FBf30f3D62471154eDa3C0c1b05942d',
      '0xD70C70AD87aa8D45b8D59600342FB3AEe76E3c68',
      '0xa815e2eD7f7d5B0c49fda367F249232a1B9D2883',
      '0xf8b59f3c3Ab33200ec80a8A58b2aA5F5D2a8944C',
      '0x4c615E78c5fCA1Ad31e4d66eb0D8688d84307463',
    ],
    LINEA: [
      '0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb',
      '0x02a84c1b3BBD7401a5f7fa98a384EBC70bB5749E',
      '0x1b81D678ffb9C0263b24A97847620C99d213eB14',
      '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
      '0x678Aa4bF4E210cf2166753e054d5b7c31cc7fa86',
      '0x22E2f236065B780FA33EC8C4E58b99ebc8B55c57',
    ],
  },
  DMAIL: {
    ZKSYNC: ['0x981F198286E40F9979274E0876636E9144B8FB8E'],
    LINEA: ['0xD1A3abf42f9E66BE86cfDEa8c5C2c74f041c5e14'],
  },
  ECHO_DEX: {
    LINEA: [
      '0x9201f3b9DfAB7C13Cd659ac5695D12D605B5F1e6',
      '0xc66149996d0263C0B42D3bC05e50Db88658106cE',
      '0x3F0CA22aF602B42cdDB4E9153F525Dc9dE12eaE7',
      '0x00DFEB5761F17aE83E9D3249d23F09eAb355f1F9',
      '0xCCE9d3f392c135Dc038b147ca73ec496f7f89D93',
      '0xf82537fb6c56a3b50092d3951f84f5f6c835b4f5',
      '0xe4f5dc6cab4b23e124d3a73a2cfee32dc070f72d',
      '0x0cf6b45b9e5400e20782a0c6b7e217814207f109',
      '0x433f9C64fafB532c3B71b32fFaF6A5796e58Db5D',
    ],
  },
  HORIZON_DEX: {
    LINEA: [
      '0x272E156Df8DA513C69cB41cC7A99185D53F926Bb',
      '0x438670D41D5118003B2F42cc0466fbadd760DbF4',
      '0xA749Ad81913CdC19881EBEb64631dF72be708335',
      '0x79756d90A882a1621eC35723849daC785694D93a',
    ],
  },
  WOOFI: {
    LINEA: [
      '0x4c4AF8DBc524681930a27b2F1Af5bcC8062E6fB7',
      '0xca10e8825fa9f1db0651cd48a9097997dbf7615d',
      '0xf5d215d9c84778f85746d15762daf39b9e83a2d6',
      '0x0208D735576B3D974024237393F4617285bf0563',
      '0xb130a49065178465931d4f887056328cea5d723f',
      '0xa2101fd320d06e0a744e4fe90ef8a20ecd027001',
      '0x15bA47E540E1B197E30D16A93B0c568B251629c2',
      '0xac8951A442fe70342f9597044B7b7657D5ad55ec',
      '0x88a19967013ca274c296bfa433eb1bac4d47b0dc',
    ],
  },
  LNS: {
    LINEA: ['0xda4C3eB39707aD82EA7a31afD42BDF850fED8f41'],
  },
  DYSON: {
    LINEA: ['0xa33E48EF82e697143208254FDe53Bf624f2C87E4'],
    BLAST: [
      '0x0BC788aDF6EDE55c9B9f112A52AAa57F2d35874A',
      '0xd7CDE8aA87BC5af7A2de2ECBf0F5862E1880bC40',
      '0x9CBD81b43ba263ca894178366Cfb89A246D1159C',
      '0x8813B3EEB279A43Ac89e502e6fbe0ec89170c088',
      '0xADa6e69781399990d42bEcB1a9427955FFA73Bdc',
      '0x746a40964c406B0c402a98Cf60081d22621227fd',
    ],
  },
  MENDI: {
    LINEA: [
      '0xe3CDa0A0896b70F0eBC6A1848096529AA7AEe9eE',
      '0x1b4d3b0421dDc1eB216D230Bc01527422Fb93103',
      '0x1a11669Ecf91692440Da95CC8A12DE80B1C3D9e3',
      '0x2938b0932eF3fd7a58a9FC077398CAD981495741',
      '0xCcBea2d7e074744ab46e28a043F85038bCcfFec2',
      '0x62c31a75334E566Aa049aB24a2FF13aC3a5c02d0',
      '0x3b9B9364Bf69761d308145371c38D9b558013d40',
      '0xAd7f33984bed10518012013D4aB0458D37FEE6F3',
      '0x333D8b480BDB25eA7Be4Dd87EEB359988CE1b30D',
      '0xf669C3C03D9fdF4339e19214A749E52616300E89',
      '0x1f27f81C1D13Dd96A3b75d42e3d5d92b709869AA',
      '0x9be5e24F05bBAfC28Da814bD59284878b388a40f',
      '0xCeEd853798ff1c95cEB4dC48f68394eb7A86A782',
      '0xd0E67CE5E72beBA9D0986479Ea4E4021120cf794',
      '0x150b1e51738CdF0cCfe472594C62d7D6074921CA',
      '0xcf8deDCdC62317beAEdfBee3C77C08425F284486',
      '0xea57474c20C4Bb3f0Fea4a5D2996c1e636b7Eb52',
    ],
  },
  VELOCORE: {
    ZKSYNC: [
      '0xf5E67261CB357eDb6C7719fEFAFaaB280cB5E2A6',
      '0x99bBE51be7cCe6C8b84883148fD3D12aCe5787F2',
      '0xd511c5BeDAFf22390c6DA217d33186a67e111213',
      '0x1576B8D0BE0C721Ea653970e40F7A6D4b0b0ABf4',
      '0xF29Eb540eEba673f8Fb6131a7C7403C8e4C3f143',
      '0xB2CEF7f2eCF1f4f0154D129C6e111d81f68e6d03',
      '0xbdE345771Eb0c6adEBc54F41A169ff6311fE096F',
      '0xD7107a47B4fBBc585b25BbEe3777B2EDC11D156C',
      '0xe09A60FAe6d77658b9767A70e2f361b46Dd3f16A',
    ],
    LINEA: [
      '0x1d0188c4B276A09366D05d6Be06aF61a73bC7535',
      '0xaa18cdb16a4dd88a59f4c2f45b5c91d009549e06',
      '0xcc22f6aa610d1b2a0e89ef228079cb3e1831b1d1',
      '0xaec06345b26451bda999d83b361beaad6ea93f87',
      '0xbe6c6a389b82306e88d74d1692b67285a9db9a47',
      '0x73fa525c642baaaecb304614fa38614845649ef8',
      '0x25cbd11558c369b089852baaec9bbb1b66ebf89c',
      '0x0978112d4ea277ad7fbf9f89268deeddeb743996',
      '0x111a6d7f5ddb85776f1b6a6deabe552815559f9e',
      '0x6ecc3ddf76e42dd2ff681dc926eb885d8651ee54',
      '0xaa45ae6c1687eece17ceee098f6efed64e6ad2bd',
      '0x3303f1faa895f0e17863454b3b7a2a453a5cfae5',
      '0x08657a62f65d62d0190df72c6ad0fde0b8e54a20',
      '0xe192e2a21873eb4ca4ddfc18fd15c8bbc93ac93f',
      '0x71c29c4c7b714fd4190dfb0a2fc599dbb0cb6568',
      '0xb30e7a2e6f7389ca5ddc714da4c991b7a1dcc88e',
      '0x3f006b0493ff32b33be2809367f5f6722cb84a7b',
    ],
  },
  SUSHI: {
    SCROLL: [
      '0x0389879e0156033202C44BF784ac18fC02edeE4f',
      '0x734583f62bb6ace3c9ba9bd5a53143ca2ce8c55a',
      '0x33d91116e0370970444B0281AB117e161fEbFcdD',
    ],
    LINEA: [
      '0x80c7dd17b01855a6d2347444a0fcc36136a314de',
      '0xb1e835dc2785b52265711e17fccb0fd018226a6e',
      '0x46b3fdf7b5cde91ac049936bf0bdb12c5d22202e',
    ],
  },
  XFAI: {
    LINEA: [
      '0x8C56017B172226fE024dEa197748FC1eaccC82B1',
      '0xD538be6e9026C13D130C9e17d509E69C8Bb0eF33',
      '0x91612287fd53bEDD253a89e3d9813423ea20AAEC',
    ],
  },
  LYVE: {
    LINEA: [
      '0xb20116eE399f15647BB1eEf9A74f6ef3b58bc951',
      '0xAa098708068B836D52833E656597C1A0DD2bB654',
      '0x55C42B42661c77809E31f2D06Db1637De8CF7979',
      '0xAC67A80B0099C55fB17275A9795F66E070F27dC4',
    ],
  },
  KURAMA: {
    SCROLL: ['0x2a7e3f8906d10a64941065a1a69bf37e47ccb642', '0x3077fb318AbAf79DCB94e0C756395f095A37d29f'],
  },
  PAPYRUS: {
    SCROLL: [
      '0x29ACA061b49753765A3DBC130DBF16D4477bFd3F',
      '0x9508Ea41e47672f0A737CfcC286d79eE85f8d0BC ',
      '0xf30C4A86574CeCDc4B094833f218e26db15Ff5c9 ',
      '0x5ce0ab95D8aE9a528e9A574b76BDE22DD6F83bc5 ',
      '0x32f8EDa92CA82290ddab8f1381068f0eA7290E21',
      '0xCE21EE87F69C0982bF5597E953FfDc13f33178EE ',
      '0x12D9263c8d0DEDafdA7581d909E9aa02e7D7a1D9 ',
      '0x723Ee11B166E61A9afbbAE1c1e400c4Ca92296D5 ',
      '0xcb463e993418a93A2230f2Ea0634b4633778220B',
      '0x2039514e046B57146B9b8bEfb38359b2296940a9 ',
      '0x4cB8d40F54F96d5ab08d076Fa50564F8e9Ed04FB ',
      '0xeF8617da52742052AB79f8c74110D61f8A3000Eb',
      '0x4478c2a15E6D5EbA5F7681eC4d504035B3a7d5dB  ',
      '0x5B13393013e0711E52CfA5049EC447fb79db132b ',
      '0x053EC655A21DE72507fA3FDB3c0224F9FA797E4C ',
      '0xF177d4FAdac0D10522ce52605e4ca7718b61e7D8',
    ],
  },
  COG: {
    SCROLL: [
      '0x63FdAFA50C09c49F594f47EA7194b721291ec50f',
      '0x20b3a538aA525Cf5F8aF25052AE849471d96138B',
      '0x43187A6052A4BF10912CDe2c2f94953e39FcE8c7',
      '0x5c121db888aD212670017080047Ed16CE99a2a96',
      '0x04BB9Bca2F8955051966B6dA5398AD1B3a832762',
    ],
  },
  AAVE: {
    SCROLL: ['0x11fCfe756c05AD438e312a7fd934381537D3cFfe', '0xFF75A4B698E3Ec95E608ac0f22A03B8368E05F5D'],
  },
  POOL_SHARK: {
    SCROLL: ['0xebf57cb31ed38e6ccb53fb71ba246ea549c42e51', '0xf04bf4e3e8157ba5b91bfda16e21be770e7ac790'],
  },
  TENK: {
    SCROLL: ['0xa35a5c4920538cf926c92bdb3169a8434d720bfd'],
  },
  ODOS: {
    ZKSYNC: ['0xA269031037B4D5fa3F771c401D19E57def6Cb491', '0x4bBa932E9792A2b917D47830C93a9BC79320E4f7'],
  },
  FULCROM: {
    ZKSYNC: [
      '0x850Fe8be964cC5Feb3dd00CfE1364590B45e3926',
      '0x99819F0e0927718f5FbC73d3327FF7691D0243d6',
      '0xf2220Ae74866FF181B5922613F94d92E84f2491f',
      '0x873534b54BE5a0C900798B0F775b2f0eB238e89f',
      '0x4d9193D87C57c0839157F8EfDE0A6Aa8f4118a33',
      '0x76dF63db845027965b7f2DA9acbD5994F3524c16',
      '0x0CF43637c3040Cf27a0b66d559e2dafaE4105992',
    ],
  },
  VESYNC: {
    ZKSYNC: [
      '0x6C31035D62541ceba2Ac587ea09891d1645D6D07',
      '0x1925AB9F9bcdB9E2D2861cc7C4c157645126D9d9',
      '0x8d70979e3248EBe52061d2deDab0Cf3351FF033F',
    ],
  },
  ORBIT: {
    BLAST: [
      '0x1E18C3cb491D908241D0db14b081B51be7B6e652',
      '0x42E12D42b3d6C4A74a88A61063856756Ea2DB357',
      '0xfA1FDCF4682B72e56e3b32FF1ABA7AfCD5e1C7A8',
      '0x0872b71efc37cb8dde22b2118de3d800427fdba0',
    ],
  },
  SPACEBAR: {
    BLAST: [
      '0xe91a42e3078c6ad358417299e4300683de87f971',
      '0x5ff315aa82a8b2b435f866d20ecd46959999bdcd',
      '0xe1784da2b8f42c31fb729e870a4a8064703555c2',
    ],
  },
  PAC: {
    BLAST: [
      '0xd2499b3c8611e36ca89a70fda2a72c49ee19eaa8',
      '0x0Ea92DEAF7141f405f00BFAad76A8aC74E155C00',
      '0xAd49EE1956704F1ec97CE7A9850A3608Bf0bECc3',
      '0xfde98ab7a6602ad55462297d952ce25b58743140',
    ],
  },
  JUICE: {
    BLAST: [
      '0x4A1d9220e11a47d8Ab22Ccd82DA616740CF0920a',
      '0x105e285f1a2370D325046fed1424D4e73F6Fa2B0',
      '0x0ff045444ccbbc9999c81412f3faba6a77284f8c',
      '0x4A355D57fc1A5eEB33C0a19539744A2144220027',
      '0x8034b01555487C26D4e21F4E33b7A30fbc90d181',
      '0x72E4ce9b7cC5d9C017F64ad58e512C253a11d30a',
      '0x44f33bC796f7d3df55040cd3C631628B560715C2',
    ],
  },
  THRUSTER: {
    BLAST: [
      '0xb4A7D971D0ADea1c73198C97d7ab3f9CE4aaFA13',
      '0x98994a9A7a2570367554589189dC9772241650f6',
      '0x44889b52b71E60De6ed7dE82E2939fcc52fB2B4E',
      '0x434575EaEa081b735C985FA9bf63CD7b87e227F9',
      '0x337827814155ECBf24D20231fCA4444F530C0555',
      '0x12c69BFA3fb3CbA75a1DEFA6e976B87E233fc7df',
      '0xD2f1a205959605550471762e7dEa1A034EdbF477',
    ],
  },
  MONOSWAP: {
    BLAST: [
      '0x859374eA6dF8289d883fEd4E688a83381276521d',
      '0xE4Dd30f7a2808580C9185e975bd7770A842923c6',
      '0x13060D08Ea8Eb04A5Cf554B59b138E84c1C07CDE',
    ],
  },
  RING: {
    BLAST: [
      '0x7001F706ACB6440d17cBFaD63Fa50a22D51696fF',
      '0x6A7c92Bddb8D695cfDFf5D47487A456Cc46e0ee5',
      '0xce69eca56e1ace442ebd591918223acfed60a940',
    ],
  },
  SYN_FUTURES: {
    BLAST: ['0x6A372dBc1968f4a07cf2ce352f410962A972c257', '0xB85b77f32DBDb7e4895b288e70770C90E232C751'],
  },
  ROGUEX: {
    BLAST: [
      '0x37e33B250985FAf4DBdc607FA7454CA76B883A2a',
      '0xdD84404e9741fB1Ba2f52906B25126fAE22b4243',
      '0x8e7a5187117F3dfbae028ac26db00A75aE41F095',
      '0x662267CaA600d9f284356075C3504E78408A819B',
    ],
  },
  ABRACADABRA: {
    BLAST: [
      '0xC8f5Eb8A632f9600D1c7BC91e97dAD5f8B1e3748',
      '0xa64b73699cc7334810e382a4c09caec53636ab96',
      '0x76DA31D7C9CbEAE102aff34D3398bC450c8374c1',
      '0xcA8A205a579e06Cb1bE137EA3A5E5698C091f018',
      '0x630FC1758De85C566Bdec1D75A894794E1819d7E',
    ],
  },
  MANGROVE: {
    BLAST: ['0xb1a49C54192Ea59B233200eA38aB56650Dfb448C'],
  },
  CAMBRIA: {
    BLAST: [
      '0xc15568330926e2a6f1519992b0364ca00faf6a7a',
      '0xc5DB40980054628AD901846164c6B3418d92DABE',
      '0xC9438f95AA8d9ee1b5edEa15c7fa4B2CAC723dcE',
    ],
  },
  FANTASY: {},
  BLAST_NS: {
    BLAST: ['0x4a5792115486a56ce073183e7ba9f605cab61c23', '0x0611a1da11ac5ec442aea65af47bae90a6456d4a'],
  },
  BIG_INT: {
    ZKSYNC: ['0x9d8Fb238e51653314815C3e14bF008aa8Fe9304E'],
  },
  HENJIN: {
    TAIKO: ['0x07Bc9a408B385C7Aa8De2783795759512fE24356', '0xcFf128C67bCDc5a7c7D3F24c638e59AA0d4e112b'],
  },
  RITSU: {
    TAIKO: [
      '0xeF4a016F3E54c4520220adE7a496842ECbF83E09',
      '0x424Fab7bfA3E3Dd0e5BB96771fFAa72fe566200e',
      '0x7160570BB153Edd0Ea1775EC2b2Ac9b65F1aB61B',
    ],
  },
  IMAGINAIRYNFTS: {
    TAIKO: ['0xb99E5534d42500eB1d5820fBA3Bb8416cCB76396'],
  },
  TAIKO_SWAP: {
    TAIKO: ['0xF078BD74C62a2F643fd9630ECBCfe1C3c28f4734'],
  },
}

export const BRIDGE_REWARD_START = '2024-05-09T12:00:00.000Z'

export const ALPHA_KEY_END = '2024-07-19T23:59:59.000Z'

// export const enabledMilestoneCampaigns = [MilestoneCampaignID.SCROLL]

export const LEGACY_PORTAL_URL = 'https://app-legacy.rhino.fi/portfolio/funds'

export const TOKEN_DISTRIBUTIONS = {}

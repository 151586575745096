import userflow from 'userflow.js'
import type { TurnstileData } from '../constants/types'
import { isMobileDevice } from '../utils'
import type { HeapEvent } from '../constants/heapEvents'
import { heapEvents, heapStatuses } from '../constants/heapEvents'
import { userflowEvents } from '../constants/userflowEvents'
import { trackUserflowEvent } from './apiService/trackUserflowEvent'
import { LogFeature, makeLog } from '../utils/makeLog'
import { envConfig } from '../env/envConfig'
import { Schema } from 'effect'
import type { AddressableEvent } from '../constants/addressableEvents'

const VersionApiSchema = Schema.Struct({
  version: Schema.String,
})

// // Misc
export const logAppVersions = async () => {
  try {
    const data = await fetch(`${envConfig.tradingApi}/v1/trading/apiVersion`)
    const result = await data.json()
    const { version } = Schema.decodeUnknownSync(VersionApiSchema)(result)
    // eslint-disable-next-line no-console -- Log required for QA
    console.log(
      `%c Frontend version (Vite): ${import.meta.env.VITE_REACT_APP_VERSION}`,
      'background: #000; color: #fff; font-size: 18px;',
    )
    // eslint-disable-next-line no-console -- Log required for QA
    console.log(`%c Backend version: ${version}`, 'background: #000; color: #fff; font-size: 18px;')
  } catch {
    // eslint-disable-next-line no-console -- Log required for QA
    console.log(
      '%c Frontend v. (Vite)' + import.meta.env.VITE_REACT_APP_VERSION,
      'background: #000; color: #fff; font-size: 18px;',
    )
  }
}

export const prepareUserMetaTurnstile = (
  token: string,
  errorCode: number,
): {
  meta: {
    turnstile: TurnstileData
  }
} => {
  if (errorCode === -1) {
    return {
      meta: {
        turnstile: { type: 'REGULAR', errorCode: -1, token },
      },
    }
  }

  return {
    meta: {
      turnstile: { type: 'BOT', errorCode },
    },
  }
}

const logHeap = makeLog(LogFeature.HEAP_TRACKING)
export const trackHeapEvent = (event: HeapEvent, data: Record<string, unknown> | string = {}) => {
  try {
    logHeap('Heap Event:', event, data)
    if (window.heap) {
      window.heap.track(event, data)
    }
  } catch (error) {
    console.error("Couldn't send tracked event to Heap.", error)
  }
}

// Identify Heap user session
export const identifyHeap = (address: string, walletType: string, isAutoConnected: boolean) => {
  try {
    if (window.heap) {
      window.heap.identify(address)
      window.heap.addUserProperties({ walletType })
      trackHeapEvent(heapEvents.connectWallet, {
        status: heapStatuses.success,
        walletType,
        isAutoConnected,
      })
    }
  } catch (error) {
    console.error("Couldn't identify Heap user session.", error)
  }
}

export const addUserPropertiesHeap = (data: Record<string, unknown>) => {
  try {
    if (window.heap) {
      window.heap.addUserProperties(data)
    }
  } catch (error) {
    console.error("Couldn't add user properties to Heap.", error)
  }
}

// Identify Userflow user session
export const identifyUserflow = (address: string, walletType: string) => {
  try {
    void userflow.identify(address, {
      walletType,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- Legacy
      device_type: isMobileDevice() ? 'mobile' : 'desktop',
    })
    trackUserflowEvent(userflowEvents.connectWallet)
  } catch (error) {
    console.error("Couldn't identify Userflow user session.", error)
  }
}

const logAddressable = makeLog(LogFeature.ADDRESSABLE_TRACKING)
export const trackAddressableEvent = (
  event: AddressableEvent,
  isConversion: boolean,
  data: Record<string, string | number>,
) => {
  try {
    if (window.__adrsbl) {
      logAddressable('Addressable event:', event, data)
      const dataArray = Object.entries(data).map(([key, value]) => ({
        name: key,
        value,
      }))
      window.__adrsbl.run(event, isConversion, dataArray)
    }
  } catch (error) {
    console.error("Couldn't send event to Addressable", error)
  }
}

import styled from 'styled-components'
import { useAppSelector } from '../../hooks'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useLazyIsUserVerifiedQuery } from '../../services/userVerificationApi'
import { clearModal } from '../../store/actions/modalActions/clearModal'
import { selectAddress } from '../../store/selectors/user.selectors'
import { setUserVerification } from '../../store/slices/userVerification.slice'
import { Modal } from '../common/Modal/Modal'
import { UserVerificationModalContent } from './UserVerificationModalContent'

// eslint-disable-next-line react-refresh/only-export-components -- Legacy
export enum VerificationType {
  email = 'email',
  phone = 'phone',
}

type Props = {
  verificationType: VerificationType
  onComplete?: () => void
  initialStep?: number
  autoFocus?: boolean
}

export const UserVerificationModal = ({ verificationType, onComplete, initialStep = 0, autoFocus }: Props) => {
  const dispatch = useAppDispatch()
  const address = useAppSelector(selectAddress)
  const [isVerifiedQuery] = useLazyIsUserVerifiedQuery()

  return (
    <Modal
      width="400px"
      isVisible
      onClose={() => {
        clearModal(dispatch)()
      }}
    >
      <UserVerificationModalContent
        {...(autoFocus ? { autoFocus } : {})}
        initialStep={initialStep}
        verificationType={verificationType}
        onLastStepSubmitSuccess={async () => {
          if (address) {
            const userVerificationData = await isVerifiedQuery({ user: address }).unwrap()
            if (userVerificationData) {
              dispatch(setUserVerification(userVerificationData))
            }
          }

          if (onComplete) {
            onComplete()
          }
          clearModal(dispatch)()
        }}
      />
    </Modal>
  )
}

export const Step = styled.div<{ $active: boolean }>`
  height: 5px;
  width: 100%;
  border-radius: 2px;
  margin: 0px 5px;
  background-color: ${({ $active, theme }) => ($active ? theme.secondary : `${theme.secondary}60`)};
`

import { useMemo } from 'react'
import { useGetBetaKeyCounterQuery, useHasAccessToFeatureQuery } from '../../pages/Nation/services/gatedFeatures.api'
import { useAppSelector } from '../useAppSelector'
import { selectAddress, selectIsAuthenticated } from '../../store/selectors/user.selectors'
import { NationUserStatus } from '../../pages/Nation/types/nation.types'
import { selectJustMintedPassportNFT } from '../../pages/Nation/selectors/nation.selectors'
import { NATION_ACCESS_NFT_NETWORK, NATION_ACCESS_NFT_TYPE } from '../../pages/Nation/constants/nationGeneral.constants'
import { useConfig } from '../useConfig'
import { useUserMintedNFTs } from '../../pages/Chain/hooks/useUserMintedNFTs'

export const useNationStatus = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const justMintedPassportNFT = useAppSelector(selectJustMintedPassportNFT)
  const address = useAppSelector(selectAddress) || ''

  const {
    currentData: hasAccessToRhinoNation,
    isFetching: isFetchingHasAccessToRhinoNation,
    error: errorHashAccessToRhinoNation,
  } = useHasAccessToFeatureQuery({ feature: 'perks', user: address }, { skip: !address || !isAuthenticated })

  // it can take up to 30 sec to dvf-chain-events to detect the NFT mint
  // this logic allows to check directly on the contract if the user has minted the NFT
  const { hasMintedNFT, isFetching: isFetchingUserMintedNFTs, error: errorUserMinterNFTs } = useUserMintedNFTs()
  const hasMintedGatedNFT = hasMintedNFT(NATION_ACCESS_NFT_TYPE, NATION_ACCESS_NFT_NETWORK)
  const { config } = useConfig()
  const {
    data: betaKeyCounter,
    isFetching: isFetchingBetaKeyCounter,
    error: errorBetaKeyCounter,
  } = useGetBetaKeyCounterQuery(
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- skip makes sure this is defined when called
      config: config!,
    },
    {
      skip: !isAuthenticated || !config,
    },
  )

  const status = useMemo(() => {
    if (isFetchingUserMintedNFTs || isFetchingHasAccessToRhinoNation) {
      return NationUserStatus.Loading
    }

    if (hasMintedGatedNFT || justMintedPassportNFT) {
      return hasAccessToRhinoNation ? NationUserStatus.Joined : NationUserStatus.MintedNFT
    }

    return NationUserStatus.NotJoined
  }, [
    justMintedPassportNFT,
    hasAccessToRhinoNation,
    hasMintedGatedNFT,
    isFetchingUserMintedNFTs,
    isFetchingHasAccessToRhinoNation,
  ])

  return {
    status,
    hasAccessToRhinoNation,
    isFetchingHasAccessToRhinoNation,
    errorHashAccessToRhinoNation,
    betaKeyCounter,
    isFetchingBetaKeyCounter,
    errorBetaKeyCounter,
    isFetchingUserMintedNFTs,
    errorUserMinterNFTs,
  }
}

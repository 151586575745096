// import { Buffer } from 'buffer'
import { LogFeature, makeLog } from '../../utils/makeLog'
import type { BaseQueryApi } from '@reduxjs/toolkit/query/react'
import type { RootState } from '../../store/configureStore'
import type { AuthDataType } from './auth.types'

const log = makeLog(LogFeature.AUTH)
// TODO NOTICKET-1 This is just for old auth, and new services are going to use the new auth, so this should be removed
// But we keep it just in case we need to use it on old services that haven't been migrated to the new auth
// const makeEcRecoverHeader = (authData: Pick<AuthData, 'nonce'>) => {
//   const bufferStarkAuthData = Buffer.from(JSON.stringify(authData))
//   // return 'EcRecover ' + bufferStarkAuthData.toString('base64')
//   return 'EcRecover ' + bufferStarkAuthData.toString('base64')
// }

export const addAuthorizationHeader = ({
  headers,
  endpoint,
  authData,
}: {
  headers: Headers
  endpoint: string
  authData: AuthDataType
}) => {
  log('Authorized call to', endpoint, authData)
  // const token = makeEcRecoverHeader({
  //   signature: authData.signature,
  //   nonce: authData.nonce,
  // })
  // headers.set('authorization', token)
  return headers
}

export const prepareHeaders =
  (endpointsWithAuth: string[]) => (headers: Headers, api: Pick<BaseQueryApi, 'endpoint' | 'getState'>) => {
    if (endpointsWithAuth.includes(api.endpoint)) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know its RootState
      const authData = (api.getState() as RootState).user.auth.data
      if (!authData) {
        throw new Error('Auth data is missing')
      }

      headers.set('Authorization', authData.jwt)
      // addAuthorizationHeader({
      //   headers,
      //   endpoint: api.endpoint,
      //   authData,
      // })
    }
  }

// export const getAuthorizationHeadersEffect = async () => ({
//   authorization: await getAuthorizationToken(),
// })

import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Title, TitleSize, Text, TextSize, TypographyAlign, Button, ButtonVariant } from '@rhinofi/dvf-shared-ui'
import { Modal } from '../../../components/common/Modal/Modal'
import { useAppDispatch } from '../../../hooks'
import { clearModal } from '../../../store/actions/modalActions/clearModal'
import { translate } from '../../../intl/i18n'
import { FlexContainer } from '../../../components/common/Wrappers/Containers'
import TwitterXIcon from '../../../assets/icons/twitter_x.svg?react'
import TelegramIcon from '../../../assets/icons/telegram.svg?react'
import earnCardIllustration from '../assets/earn_card_illustration.png'
import { makeRouteTo } from '../../../router'
import { envConfig } from '../../../env/envConfig'
import { shareOnX } from '../../../utils/shareOnX'
import { Copy } from '../../../components/common/Helpers/Copy'
import { shareOnTelegram } from '../../../utils/shareOnTelegram'
import { useGmFeeLabel } from '../../../hooks/contractsInteractions/useGmFeeLabel'
import { CHAIN_TWITTER_HANDLE_MAPPING } from '../constants/contractsInteractions.constants'
import { useChainKeyToName } from '../../../hooks/useChainKeyToName'

type Props = {
  contractAddress: string
  chain: string
}

export const ContractShareModal = ({ contractAddress, chain }: Props) => {
  const dispatch = useAppDispatch()
  const chainName = useChainKeyToName(chain)

  const closeModal = useCallback(() => clearModal(dispatch)(), [dispatch])

  const shareLink = useMemo(() => {
    const localPart = makeRouteTo.chainDeployContracts({ subRoute: 'friend', chain })
    return `${envConfig.appURL}${localPart}?contractAddress=${contractAddress}`
  }, [contractAddress, chain])

  const gmFeeLabel = useGmFeeLabel({
    chain,
    feeAmount: envConfig.GM_CONTRACT_FEE_GM_WEI,
  })

  const shareOnTwitterCallback = useCallback(
    () =>
      shareOnX(
        translate('contracts_interactions.twitter_share', {
          '%link': shareLink,
          '%chainTwitterHandle': CHAIN_TWITTER_HANDLE_MAPPING[chain] || '',
        }),
      ),
    [shareLink, chain],
  )
  const shareOnTelegramCallback = useCallback(
    () =>
      shareOnTelegram(
        translate('contracts_interactions.telegram_share', {
          '%link': shareLink,
          '%chain': chainName,
        }),
      ),
    [shareLink, chainName],
  )

  return (
    <Modal isVisible width="414px" onClose={closeModal} variant="info">
      <FlexContainer $direction="column" $gap="16px" $alignItems="stretch">
        <ModalTitle size={TitleSize.XXS} bold>
          {translate('contracts_interactions.share_and_earn')}
        </ModalTitle>

        <ImageContainer>
          <img src={earnCardIllustration} alt="" />
        </ImageContainer>

        <Text align={TypographyAlign.Center}>
          {translate('contracts_interactions.share_description', {
            '%gmFeeLabel': gmFeeLabel,
          })}
        </Text>

        <Copy text={shareLink} showTop hideIcon copiedText={translate('global.link_copied')}>
          <Button id="contract-share-modal-copy-link" variant={ButtonVariant.secondary} onClick={() => {}} fullWidth>
            {translate('global.copy_link')}
          </Button>
        </Copy>

        <Text size={TextSize.XS} align={TypographyAlign.Center}>
          {translate('contracts_interactions.share_directly_on')}
        </Text>

        <FlexContainer $gap="22px" $justifyContent="center">
          <IconWrapper onClick={shareOnTwitterCallback} id="contract-share-modal-twitter">
            <TwitterXIcon />
          </IconWrapper>

          <IconWrapper onClick={shareOnTelegramCallback} id="contract-share-modal-telegram">
            <TelegramIcon />
          </IconWrapper>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  )
}

const ModalTitle = styled(Title)`
  margin-right: 48px;
`

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;

  svg path {
    fill: ${({ theme }) => theme.secondary};
  }
`

const ImageContainer = styled.div`
  margin: 0 auto;
`

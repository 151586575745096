/* eslint-disable @typescript-eslint/no-unsafe-member-access -- parses random events */
/* eslint-disable @typescript-eslint/consistent-type-assertions -- parses random events */
import * as Sentry from '@sentry/react'
import type { EventHint } from '@sentry/react'
import { envConfig } from '../../env/envConfig'
import { F_FLAGS_TYPES } from '../../constants/types'
import { isFeatureEnabled } from './isFeatureEnabled'

type CaptureContextWithExtra = EventHint['captureContext'] & { extra: unknown }

const { sentryProject, sentryUrl } = envConfig
const ignorables = [
  // ignore TON SDK errors
  'TON_CONNECT_SDK_ERROR',
  // ignore denied signatures
  'User denied transaction signature',
  // ignore known expected errors
  `PENDING_DEPOSITS_ABOVE_LIMIT`,
  'OPERATION_UNAVAILABLE_AT_THIS_TIME',
  ...(envConfig.configEnv === 'dev' || envConfig.configEnv === 'stg' ? ['YIELD_ORDER_BOOKS_ERROR'] : []),

  // common errors logged in the backend. Left here to not lose them in logs
  // 'CROSSCHAIN_SWAP_NO_CONFIG_FOR_CHAIN',
  // 'NO_BRIDGE_CONTRACT_BALANCE_FOR_TOKEN_AND_CHAIN',
  // 'WITHDRAWAL_AMOUNT_BELOW_FEE',
  // 'NATIVE_TOKEN_COST_ABOVE_RECEIVE_AMOUNT',
  // 'TRADING_KEY_NOT_REGISTERED',
]
export const setUpSentry = () => {
  if (import.meta.env.MODE !== 'development' && isFeatureEnabled(F_FLAGS_TYPES.DEV_ENABLE_SENTRY) && sentryUrl) {
    Sentry.init({
      dsn: sentryUrl,
      release: `${sentryProject}@${import.meta.env.VITE_REACT_APP_VERSION}`,
      beforeSend(event, hint) {
        // Modify the event here
        if ((event as any).privateKey) {
          delete (event as any).privateKey
        } else if ((event as any).starkPrivateKey) {
          delete (event as any).starkPrivateKey
        } else if ((event as any).tradingKey) {
          delete (event as any).tradingKey
        }

        try {
          const serialized = JSON.stringify(event.extra?.['__serialized__'] || {})
          // cast because of the extra property. We pass it in reportToSentry, but the types don't reflect that
          const context =
            (hint?.captureContext as CaptureContextWithExtra | undefined)?.extra || hint?.originalException || {}
          const serializedContext = JSON.stringify(context)

          if (ignorables.some((toIgnore) => serialized.includes(toIgnore) || serializedContext.includes(toIgnore))) {
            return null
          }

          // if context has a body, attach it to the event
          if (context && (context as any)?.body) {
            event.extra = {
              ...event.extra,
              errorBody: (context as any)?.body,
            }
          }
        } catch {
          return event
        }

        return event
      },
      denyUrls: [
        // chromium extensions
        'chrome-extension://',
        // safari extensions
        'safari-extension://',
        // firefox extensions
        'moz-extension://',
        // cloudflare beacon.min.js script
        'beacon.min.js/',
        // userflow internal errors
        '/legacy/userflow.js',
        // extension injected code
        `injected_script_ContentScripts`,
        // extension
        `@webkit-masked-url:(//hidden/)`,
      ],
      ignoreErrors: [
        'ResizeObserver',
        'NetworkError',
        'Network Error',
        'MetaMask Message Signature',
        'Failed to fetch',
        'widgetReady',
        'The nonce used is too old',
        'shadowRoot',
        'requestDevice',
        'wrong passphrase',
        'userAgent',
        'This account is associated with a Ledger connection',
        'safari-extension',
        'chrome-extension',
        'NOT_ENOUGH_AVAILABLE_BALANCE',
        '"statusCode":422',
        '"statusCode":503',
        '"statusCode":500',
        'Transaction was not mined within 50 blocks',
        'User rejected the transaction',
        'User denied transaction',
        'User rejected the request',
        'did it run Out of Gas',
        'header not found',
        'must match the active chainId',
        'USER_MUST_REGISTER',
        'No user registered for the ETH address',
        "expected expression, got '<'",
        '[ethjs-rpc] rpc error with payload',
        'User canceled', // don't report user cancels
        'ChunkLoadError',
        'Talisman extension has not been configured yet. Please continue with onboarding.',
        // REMOVE when https://darcs.atlassian.net/browse/BRIDGES-1430 is fixed
        'Cannot animate between AnimatedValue and AnimatedString',
        // REMOVE when https://darcs.atlassian.net/browse/BRIDGES-1533 is fixed
        "This contract object doesn't have address set yet, please set an address first.",
        // userflow not loading error, comes from userflow client lib
        'Could not load Userflow.js',
        // timeout from awaiting transaction receipt
        'TIMEOUT_TRANSACTION_RECEIPT',
        // coming from an adblock
        'Cannot redefine property: googletag',
        // coming from extensions
        `can't redefine non-configurable property "solana"`,
        `can't redefine non-configurable property "ethereum"`,
        'Cannot redefine property: ethereum',
        // happening a lot on iOS with onMediaMute? which doesn't exist in our code or google
        `Can't find variable: bytecode`,
        // metamask connection issues
        'MetaMask is having trouble connecting to the network',
        // "An error occurs when one or many Wallet connect requests are neither accepted nor rejected, but the app is closed, quits or exited."
        // https://github.com/orgs/WalletConnect/discussions/3291
        /No matching key. keychain:.+/g,
        // sentry bug https://github.com/getsentry/sentry-javascript/issues/6547
        `Permission denied to access property "apply"`,
        // firefox on ios bugs https://bugzilla.mozilla.org/show_bug.cgi?id=1394296
        /.+window\.__firefox__\.reader.+/g,
        `ReferenceError: Can't find variable: __firefox__`,
        // errors from TON sdk
        /.+TON_CONNECT_SDK_ERROR.+/g,
      ],
    })
  }
}

import { ThemeProvider } from 'styled-components'
import { useAppSelector } from '../hooks'
import { selectSelectedTheme } from '../store/selectors/portal.selectors'
import { themes } from '../theme'
import type { ReactNode } from 'react'

type RhinoThemeProviderProps = {
  children: ReactNode
}

export const RhinoThemeProvider = ({ children }: RhinoThemeProviderProps) => {
  const selectedTheme = useAppSelector(selectSelectedTheme)
  return <ThemeProvider theme={themes[selectedTheme]}>{children}</ThemeProvider>
}

import { TokenIcon, Icon, IconSizes } from '@rhinofi/dvf-shared-ui'
import type { NationWidgetMissionsFilterItemState } from '../../../types/nationWidgetMissionsFilterItem.types'
import { NationMissionIcon } from '../../NationMissionIcon'

type Props<I> = {
  item: I
}

export const NationWidgetMissionsFilterItemIcon = <
  I extends NationWidgetMissionsFilterItemState & { isSelected: boolean },
>({
  item: { key, isSelected, icon },
}: Props<I>) => {
  if (key === 'ALL') {
    return <Icon size={isSelected ? IconSizes.Small : IconSizes.Medium} id={icon.id} />
  }

  if (icon.type === 'chain') {
    return <TokenIcon size={isSelected ? 'small' : 'medium'} token={icon.id} />
  }

  if (icon.type === 'nation-feature') {
    return <NationMissionIcon type={icon.id} size={isSelected ? IconSizes.Small : IconSizes.Medium} />
  }

  return null
}

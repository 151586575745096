import type { MissionId, MissionFrequency } from '@rhinofi/rhino-nation-api-spec'
import { isMissionFullyCompleted } from '../../utils/isMissionFullyCompleted'
import { useMissionsWithUserData } from '../../hooks/useMissionsWithUserData'
import { NationMissionModalContent } from './NationMissionModalContent/NationMissionModalContent'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { clearModal } from '../../../../store/actions/modalActions/clearModal'
import { Modal } from '../../../../components/common/Modal/Modal'

type Props = {
  missionId: MissionId
  frequency?: MissionFrequency
}

export const NationActiveMissionModal = ({ missionId, frequency }: Props) => {
  const dispatch = useAppDispatch()

  const { data } = useMissionsWithUserData({ frequency })

  const missionWithUserData = data?.items.find(({ mission: { _id } }) => _id.id === missionId.id)

  const isCompleted = missionWithUserData ? isMissionFullyCompleted(missionWithUserData) : false

  if (!missionWithUserData) {
    return null
  }

  return (
    <Modal variant={isCompleted ? 'success' : undefined} isVisible onClose={() => clearModal(dispatch)()} width="480px">
      <NationMissionModalContent data={missionWithUserData} completedOn={missionWithUserData.userData?.updatedAt} />
    </Modal>
  )
}

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../../store/configureStore'
import { NETWORKS } from '../../../constants/types'

export type BridgeState = {
  chainIn: string
  chainOut: string
  token: string
  amount: string
  recipient: string
  isOtherAddress: boolean
  gasBoostEnabled: boolean
}

export const initialState: BridgeState = {
  chainIn: NETWORKS.ARBITRUM,
  chainOut: NETWORKS.BERACHAIN,
  token: 'USDC',
  amount: '',
  recipient: '',
  isOtherAddress: false,
  gasBoostEnabled: false,
}

export const bridgeSlice = createSlice({
  name: 'bridge',
  initialState,
  reducers: {
    setChainIn(state, action: PayloadAction<string>) {
      state.chainIn = action.payload
    },
    setChainOut(state, action: PayloadAction<string>) {
      state.chainOut = action.payload
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setAmount(state, action: PayloadAction<string>) {
      state.amount = action.payload
    },
    setRecipient(state, action: PayloadAction<string>) {
      state.recipient = action.payload
    },
    setIsOtherAddress(state, action: PayloadAction<boolean>) {
      state.isOtherAddress = action.payload
    },
    setGasBoostEnabled(state, action: PayloadAction<boolean>) {
      state.gasBoostEnabled = action.payload
    },
    setBridgeState(state, action: PayloadAction<BridgeState>) {
      Object.assign(state, action.payload)
    },
    switchChains(state) {
      ;[state.chainIn, state.chainOut] = [state.chainOut, state.chainIn]
    },
    resetBridge(state) {
      Object.assign(state, initialState)
    },
    // Empty on purpose in order to be caught by the bridgeListenerMiddleware middleware
    resetInvalidInitialState() {},
  },
})

export const {
  setChainIn,
  setChainOut,
  setToken,
  setAmount,
  setRecipient,
  setIsOtherAddress,
  setGasBoostEnabled,
  setBridgeState,
  switchChains,
  resetBridge,
  resetInvalidInitialState,
} = bridgeSlice.actions

// Selectors
export const selectBridge = (state: RootState) => state.bridge

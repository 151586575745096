import type { GemsTransactionAPIDocument, MissionFrequency, MissionId } from '@rhinofi/rhino-nation-api-spec'
import type { modalKeys } from '../../../constants/modalKeys'
import type { AppDispatch } from '../../configureStore'
import type { UserContractCodec, GmSuccessType } from '../../../pages/Chain/types/contractsInteractions.types'
import { showModal as showModalAction } from '../../slices/modal.slice'
import type { NationActivePerkModalState } from '../../../pages/Nation/types/nationActivePerkModalState.types'
import type { TxStatus } from '../../../services/txService/txService.types'
import type { NFTType } from '../../../pages/Chain/types/mintNFT.types'

export type ModalShowAction = {
  type: 'MODAL_SHOW'
  payload:
    | {
        modalShown: typeof modalKeys.userVerificationEmail
        modalPayload: {
          onComplete: () => void
          initialStep?: number
          autoFocus?: boolean
        }
      }
    | {
        modalShown: typeof modalKeys.userVerificationPhone
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.yieldAlerts
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.newUserEmail
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.claimSuccess
        modalPayload: {
          claimedAmount: string
          claimedToken: string
        }
      }
    | {
        modalShown: typeof modalKeys.verify
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.recentBridgeTx
        modalPayload: {
          bridgeId: string
        }
      }
    | {
        modalShown: typeof modalKeys.nftMint
        modalPayload: {
          chain: string
          type: NFTType
          txHash?: string
          status: TxStatus
        }
      }
    | {
        modalShown: typeof modalKeys.viewNftMinted
        modalPayload: {
          chain: string
          type: NFTType
        }
      }
    | {
        modalShown: typeof modalKeys.freeBridge
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.reconnectModal
        modalPayload: {
          connectedChain: string
          chainToConnect: string
          resolve: (result: boolean) => boolean
        }
      }
    | {
        modalShown: typeof modalKeys.noWallet
        modalPayload: {
          chain: string
        }
      }
    | {
        modalShown: typeof modalKeys.spendingCap
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.contractShare
        modalPayload: { contractAddress: string; chain: string }
      }
    | {
        modalShown: typeof modalKeys.contractDetails
        modalPayload: { data: UserContractCodec }
      }
    | {
        modalShown: typeof modalKeys.contractGM
        modalPayload: {
          txStatus: TxStatus
          txHash?: string | undefined
          chain: string
          successVariant: GmSuccessType
          retryFunc?: () => void | undefined
        }
      }
    | {
        modalShown: typeof modalKeys.contractDeploy
        modalPayload: {
          txStatus: TxStatus
          txHash?: string | undefined
          chain: string
          contractAddress?: string | undefined | null
          retryFunc?: (() => void) | undefined
        }
      }
    | {
        modalShown: typeof modalKeys.referralCodeVerification
        modalPayload: {
          isSuccess: boolean
        }
      }
    | {
        modalShown: typeof modalKeys.connectToParadex
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.nationOnboarding
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.nationActiveMission
        modalPayload: {
          missionId: MissionId
          frequency?: MissionFrequency
        }
      }
    | {
        modalShown: typeof modalKeys.nationCompletedMission
        modalPayload: {
          gemsTransaction: GemsTransactionAPIDocument
        }
      }
    | {
        modalShown: typeof modalKeys.nationActivePerk
        modalPayload: NationActivePerkModalState
      }
    | {
        modalShown: typeof modalKeys.nationClaimedPerk
        modalPayload: {
          gemsTransaction: GemsTransactionAPIDocument
        }
      }
    // TODO DOPE-424 - Add campaigns
    // | {
    //     modalShown: typeof modalKeys.claimMilestoneReward
    //     modalPayload: {
    //       chain: string
    //       status: TxStatus
    //       txHash?: string
    //       amount: string
    //       token: string
    //     }
    //   }
    | {
        modalShown: typeof modalKeys.connectWallet
        modalPayload: undefined
      }
}

export const showModal =
  (dispatch: AppDispatch) =>
  <T extends ModalShowAction['payload']['modalShown']>(
    modalShown: T,
    modalPayload?: Extract<ModalShowAction['payload'], { modalShown: T }>['modalPayload'],
  ) => {
    // Can't figure out a way to make this better:
    // showModal() accepts only matching shown and payload, f.e.:
    // valid:
    // showModal(dispatch)(xchainSuccessModalKey, { tokenFrom: 'test', tokenTo: 'test' })
    // invalid:
    // showModal(dispatch)(xchainSuccessModalKey, { bar: 'test', foo: 'test' })
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- explaination above
    dispatch(showModalAction({ modalShown, modalPayload } as ModalShowAction['payload']))
  }

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envConfig } from '../env/envConfig'
import type { TokenPricesState } from '../store/slices/types/TokenPricesState'
import type { RootState } from '../store/configureStore'
import { getTokenPrice } from './helperService/getTokenPrice'
import { createSelector } from 'reselect'
import { type MemeTokenList } from '@rhinofi/usd-prices-api-spec'

// Define the API service
export const usdPricesApi = createApi({
  reducerPath: 'usdPricesApi',
  baseQuery: fetchBaseQuery({ baseUrl: envConfig.tradingApi }),
  endpoints: (builder) => ({
    getUsdtPrices: builder.query<TokenPricesState, void>({
      query: () => '/usd-prices/getUsdPrices',
    }),
    getMemeCoins: builder.query<MemeTokenList, void>({
      query: () => '/usd-prices/meme-tokens',
    }),
  }),
})

export const selectMarketData = usdPricesApi.endpoints.getUsdtPrices.select()
export const selectTokenPrices = createSelector(selectMarketData, (result) => {
  if (!result?.data) {
    return {}
  }
  return result.data
})
export const selectTokenUsdPrice = (token: string) => (state: RootState) => getTokenPrice(state.tokenPrices, token)

// Export hooks for usage in functional components
export const { useGetUsdtPricesQuery, useGetMemeCoinsQuery } = usdPricesApi

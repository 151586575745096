import { Button, ButtonSizes, Text } from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import styled from 'styled-components'

import { useAppSelector } from '../../../hooks'
import { useWallet } from '../../../hooks/useWallet'
import { translate } from '../../../intl/i18n'
import { getAuthData } from '../../../services/auth/getAuthData'
import { isAuthDataValid } from '../../../services/auth/isAuthDataValid'
import { useAuth } from '../../../services/auth/useAuth'
import { selectAddress, selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { Modal } from '../../common/Modal/Modal'
import { AuthenticationSteps } from './AuthenticationSteps/AuthenticationSteps'

export const AuthenticationModal = () => {
  const walletAddress = useAppSelector(selectAddress)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const wallet = useWallet()

  const { requestAuth, authRejected, authRequested } = useAuth()

  const isVisible = !isAuthenticated && !!walletAddress

  if (!isVisible) {
    return null
  }
  const savedAuthData = getAuthData(walletAddress)
  const shouldShow = !(savedAuthData && isAuthDataValid(savedAuthData))

  if (!shouldShow) {
    return null
  }

  return (
    <Modal
      isVisible={isVisible}
      width="446px"
      onClose={() => wallet.disconnect()}
      title={translate('onboarding.authentication_title')}
    >
      <AuthenticationSteps />
      {authRejected && (
        <ErrorWrapper>
          <Text color="errorMain" size={TextSize.S}>
            Something went wrong
          </Text>
        </ErrorWrapper>
      )}
      <Button
        fullWidth
        disabled={authRequested}
        size={ButtonSizes.Large}
        onClick={() => requestAuth({ triggerSignature: true })}
        id="authentication-action"
      >
        {authRejected ? translate('global.try_again') : translate('onboarding.link_your_wallet')}
      </Button>
    </Modal>
  )
}

const ErrorWrapper = styled.div`
  margin-bottom: 8px;
`

import { useState } from 'react'
import { useDeepCompareEffect } from '../../../hooks/useDeepCompareEffect'

export const useDebouncedValues = <T>(values: T, delay = 300) => {
  const [debouncedValues, setDebouncedValues] = useState<T>(values)

  useDeepCompareEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValues(values)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [values, delay])

  return debouncedValues
}

import { useMemo } from 'react'
import { Text, TextSize, TokenIcon, IconSizes } from '@rhinofi/dvf-shared-ui'
import type { MissionAPIDocument } from '@rhinofi/rhino-nation-api-spec'
import { getMissionStatsItems } from '../../../utils/getMissionStatsItems'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { getNftNameForMissionNFTType } from '../../../../Chain/helpers/getNFTMetaData'
import { safeGetChainName } from '../../../../../utils/getChainName'
import { useConfig } from '../../../../../hooks'

type Props = {
  mission: MissionAPIDocument
}

export const NationMissionModalStats = ({ mission }: Props) => {
  const items = useMemo(() => getMissionStatsItems({ mission }).filter(({ show }) => show), [mission])
  const { config } = useConfig()

  if (items.length === 0) {
    return null
  }

  return (
    <FlexContainer $gap="8px" $direction="column" $alignItems="stretch">
      {items.map((item) => {
        const { id, label, type, value } = item

        return (
          <FlexContainer key={id} $gap="8px">
            {label !== '' && (
              <Text color="brandA1" size={TextSize.XS}>
                {label}
              </Text>
            )}

            {type === 'TEXT' && <Text size={TextSize.XS}>{value}</Text>}

            {type === 'TOKEN' && (
              <FlexContainer $gap="8px">
                <TokenIcon token={value} size={IconSizes.Small} />
                <Text size={TextSize.XS}>{value}</Text>
              </FlexContainer>
            )}

            {type === 'CHAIN' && (
              <FlexContainer $gap="8px">
                <TokenIcon token={value} size={IconSizes.Small} />
                <Text size={TextSize.XS}>{safeGetChainName(config)(value)}</Text>
              </FlexContainer>
            )}

            {type === 'NFT' && value !== null && (
              <FlexContainer $gap="8px">
                <Text size={TextSize.XS}>
                  {getNftNameForMissionNFTType({
                    nftType: value,
                    chain: item.chain,
                    config,
                  })}
                </Text>
              </FlexContainer>
            )}
          </FlexContainer>
        )
      })}
    </FlexContainer>
  )
}

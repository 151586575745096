import type { FFlag } from '../constants/types'
// import { MilestoneCampaignID } from '../pages/MilestoneCampaign/types/milestoneCampaign.types'

export const configEnv = 'dev'

export const VITE_HEAP_ANALYTICS_ID = '2345095902'

export const tradingApi = 'https://api.rhinofi.dev'

export const etherscanApiUrl = 'https://api-sepolia.etherscan.io'

export const geoblockingApiUrl = 'https://api.rhinofi.dev/gl'

export const multiCallDoNotAggregate = ['BINANCE']

export const network = 11155111

// Referencing tokens supporting https://eips.ethereum.org/EIPS/eip-2612
// for which implementation can vary slighly (hence the need for a config)
// For now, we need the "version" field for each token which can be different
// depending on the token - although specification doesn't explain underlying logic
// Adding new tokens here will make the UI switch to the "permit" model
// (vs. the legacy separate "approve" transaction) when allowance is not set.
export const tokenPermitConfig = {
  ETHEREUM: {
    xDVF: {
      name: 'xDVF Token',
      version: '1',
    },
  },
}

export const restrictedCountries = ['CU', 'PK', 'KP', 'IR', 'SY', 'VE', 'IQ', 'LK', 'TT', 'TN', 'VU', 'YE', 'BA', 'UG']

export const sentryUrl = 'https://cf112bea78354c75b37211d59a09f78c@o417796.ingest.sentry.io/5338072'

export const sentryProject = 'deversifi-dev'

export const appURL = 'app.rhinofi.dev'

export const cloudFlareWebAnalyticsScript = false

export const heapApiUrl = 'https://heapanalytics.com/api/track'
export const heapAppId = 2345095902

export const FEATURE_FLAGS: Record<FFlag, boolean> = {
  BRIDGE_PORTFOLIO_TAB: true,
  BRIDGE_REWARDS_CAMPAIGN: false,
  CLAIM_MILESTONE_CAMPAIGN: true,
  CONTRACTS_INTERACTIONS: true,
  DEV_DELAY_STARTUP: false,
  DEV_ENABLE_SENTRY: true,
  DEV_ENCHANCE_SENTRY: true,
  DEV_HIDDEN_ACTIONS: false,
  DEV_LOG_TRACKING_EVENTS: false,
  STARKNET_ARGENT_MOBILE: false,
  WRAPPED_2023: true,
  NATION_FREE_MINT: true,
  NATION_WIDGET: true,
  ZIRCUIT_DOPE: false,
  INK_NFT: true,
  MEMECOIN_TRACKER: true,
  TOKEN_DISTRIBUTION: true,
  CHAIN_PICKER_COMBINED_BALANCES: true,
}
export const NATION_FREE_MINT_END_TIME = '2024-11-06T10:00:00.000Z'
export const NATION_WIDGET_PROMO_PERK_ID = '66d5ec1f2e71bd8502c9d84a'

export const userflowTokenId = 'ct_hwizbabsknae7huh46f43nkebi'

export const environment = 'dev'

export const reCaptchaSiteKey = '6LdfUboqAAAAANZzqL_ynNJC4F1NwMKu0Xfvz8Td'

export const zknsApi = 'https://omniapi.zkns.app/domain-resolver'
export const starknetApi = 'https://api.starknet.id'

export const activityTrackers: [string, ...string[]] = ['SCROLL', 'TAIKO', 'BASE', 'ZKEVM']

export const CHAINS_WITH_DUNE_DATA = ['BASE', 'SCROLL', 'ZKSYNC']

export const minimumClaimablePoolAmount = 1

export const projectsRecommendationsUrl = 'https://splendorous-selkie-a951a2.netlify.app/test-json.json'

export const enabledNFTMinters = ['ZIRCUIT', 'LINEA', 'SCROLL', 'ZKEVM', 'TAIKO', 'BASE', 'INK']

export const nftsContracts = {
  ZKSYNC: {
    hunter: {
      type: 'NFTMinter',
      address: '0xb5aD07c2A1e3FA7c4B4E9741f585C3b06B3BBd6F',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x02CeFbcDe203b80864fdeFbB79fC42f8691c777C',
    },
    wrapped2023: {
      type: 'RhinoFiWrappedNFT',
      address: '0x494951b5F995E424F5d7775C902CbcA50AbdCD86',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0xcD2587deA6Ba1B61E233a182766F1183a6967f47',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0x6Ee159A1525928a276c173ad69b490B730757e9f',
    },
  },
  INK: {
    hunter: {
      type: 'RhinoERC1155',
      address: '0xa77c93dB8Ce0A8Fb3ACE3b0ea33bdD41415E6fA0',
      tokenId: 1,
    },
  },
  LINEA: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0600D7B0da8620A4Cf0bDbf58F1B9F4cceEC143f',
    },
  },
  SCROLL: {
    hunter: {
      type: 'NFTMinter',
      address: '0x0600D7B0da8620A4Cf0bDbf58F1B9F4cceEC143f',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0xa77c93dB8Ce0A8Fb3ACE3b0ea33bdD41415E6fA0',
    },
    ALPHAKEY: {
      type: 'NFTReward',
      address: '0x5F063Ee9AFdb4c297FcE97a37cEF7a074c6Ff5b4',
    },
    BETAKEY: {
      type: 'RhinoERC1155',
      address: '0x11426255D66C206A40d01434e135152b16a68eb9',
      tokenId: 1,
      reducedFeesContract: '0x5F063Ee9AFdb4c297FcE97a37cEF7a074c6Ff5b4',
    },
  },
  ZKEVM: {
    hunter: {
      type: 'NFTMinter',
      address: '0x3baF6f6112c204689AA77938B8F3ddf0ed53Fc43',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0xB483bb969Ba130539d1d1Ce59f6eE58790cd3bA0',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0x9fF2799Fb07C7Ff7f60FC5c42217c8a9120CBd8B',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0xe3cA55955f1B09D6c332D49aCa32D199736dC68F',
    },
  },
  MANTA: {
    hunter: {
      type: 'NFTMinter',
      address: '0x6aA0295Acc8B3a43916c29424a49b0ef0C7ADdCA',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x3baF6f6112c204689AA77938B8F3ddf0ed53Fc43',
    },
  },
  BLAST: {
    hunter: {
      type: 'NFTMinter',
      address: '0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x5752d091d99d8A05CD972DE070008de750341251',
    },
  },
  TAIKO: {
    hunter: {
      type: 'NFTMinter',
      address: '0xB483bb969Ba130539d1d1Ce59f6eE58790cd3bA0',
    },
    TOP30: {
      type: 'NFTReward',
      address: '0x215cfcf325FA579eA584928d300Ca44a96fF4dEA',
    },
    VOLUME: {
      type: 'NFTReward',
      address: '0xC73c425150Eadc38b0cB9Ae1C16dB11172bA1f04',
    },
    TXCOUNT: {
      type: 'NFTReward',
      address: '0xC24cd97Ba960Ec83c7E5C96b2683feBb1cFF2931',
    },
  },
  ZIRCUIT: {
    hunter: {
      type: 'NFTMinter',
      address: '0xc0F6246b99b5db0C9d237884d8E55fEa0cAc7Adf',
    },
  },
  BASE: {
    hunter: {
      type: 'RhinoERC1155',
      address: '0xd089dA617B3da93BBA5a179227592A1a5290cB1A',
      tokenId: 1,
    },
    TOP30: {
      type: 'RhinoERC1155',
      address: '0xd089dA617B3da93BBA5a179227592A1a5290cB1A',
      tokenId: 2,
    },
  },
}

export const nftsThresholds = {
  minRanking: 0.7,
  minVolume: 0.2, // ETH
  minTxCount: 10,
}

export const chainbaseApi = 'https://api.chainbase.online/v1/account/nfts'
export const chainbaseApiKey = '2YtDOftRHb56xrsHy38QgImUx4b'

export const turnstileSiteKey = '1x00000000000000000000AA'

export const airdropEligibilityCriteria = {
  distinctMonths: [2, 3, 4],
  txVolume: [50, 100, 150],
  txCount: [4, 10, 25, 30],
}

export const GM_CONTRACT_FEE_DEPLOY_WEI = 335000000000000 // 0.000335 ETH = $1 USD (2976.13 $ Per Ether)
export const GM_CONTRACT_FEE_GM_WEI = 33500000000000 // 0.0000335 = 0.1$ USD (2976.13 $ Per Ether)
export const CONTRACTS_INTERACTIONS_CHAINS_CONFIG = {
  INK: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  TAIKO: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  SCROLL: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  ZKSYNC: {
    feeReceiver: '0x4F170ae33F4e8950a969Ca495544f91254eEA64A',
  },
  LINEA: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  ZKEVM: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
  BASE: {
    feeReceiver: '0xC451b0191351ce308FdFd779D73814c910FC5eCB',
  },
}

export const ENABLED_CI_CHAINS = ['SCROLL', 'TAIKO', 'LINEA', 'ZKEVM', 'BASE', 'INK']

export const ACTIVITY_TRACKER_CHAIN_EXPLORER_CONFIG_MAPPING = {
  ZKSYNC: {
    url: 'https://block-explorer-api.sepolia.zksync.dev/api',
    apiKeys: [],
  },
  SCROLL: {
    url: 'https://api-sepolia.scrollscan.com/api',
    apiKeys: [],
  },
  LINEA: {
    url: 'https://api-testnet.lineascan.build/api',
    apiKeys: [],
  },
  BLAST: {
    url: 'https://api-sepolia.blastscan.io/api',
    apiKeys: [],
  },
  TAIKO: {
    url: 'https://api-testnet.taikoscan.io/api',
    apiKeys: [],
  },
}

export const ACTIVITY_TRACKER_PROJECTS_CONTRACTS = {
  RHINOFI: {
    ZKSYNC: [
      '0xb5aD07c2A1e3FA7c4B4E9741f585C3b06B3BBd6F',
      '0x02CeFbcDe203b80864fdeFbB79fC42f8691c777C',
      '0x494951b5F995E424F5d7775C902CbcA50AbdCD86',
      '0xcD2587deA6Ba1B61E233a182766F1183a6967f47',
      '0x6Ee159A1525928a276c173ad69b490B730757e9f',
      '0xA31b3D8641BcB876dA28A375d0f44761633AB8Fe',
    ],
    SCROLL: ['0x0600D7B0da8620A4Cf0bDbf58F1B9F4cceEC143f', '0xa77c93dB8Ce0A8Fb3ACE3b0ea33bdD41415E6fA0'],
    LINEA: ['0x0600D7B0da8620A4Cf0bDbf58F1B9F4cceEC143f', '0xa77c93dB8Ce0A8Fb3ACE3b0ea33bdD41415E6fA0'],
    BLAST: ['0x4Be1136247CDecA3775a7A32Aff6a621c1c149eB', '0x5752d091d99d8A05CD972DE070008de750341251'],
    TAIKO: [
      '0x65f154d1e8cf617aa3cb0983500f27252267ee81',
      '0xB483bb969Ba130539d1d1Ce59f6eE58790cd3bA0',
      '0x215cfcf325FA579eA584928d300Ca44a96fF4dEA',
      '0xC73c425150Eadc38b0cB9Ae1C16dB11172bA1f04',
      '0xC24cd97Ba960Ec83c7E5C96b2683feBb1cFF2931',
    ],
  },
  SYNCSWAP: {
    ZKSYNC: ['0x856DA67bfCD0669624bFAAFc3b728FC870c70305'],
    SCROLL: ['0x856DA67bfCD0669624bFAAFc3b728FC870c70305'],
    LINEA: ['0x856DA67bfCD0669624bFAAFc3b728FC870c70305'],
  },
  MUTEIO: {
    ZKSYNC: ['0x96c2Cf9edbEA24ce659EfBC9a6e3942b7895b5e8', '0x7aB62F39B074cb376c3AA4a37dFd0606b566fbfe'],
  },
  MAVERICK: {
    ZKSYNC: ['0x9085bae210e7a9fC35f2dcE4c9D72213b4Db53d0'],
  },
  ZKNS: {
    ZKSYNC: ['0x213e2cbF26Bb8822C1057c6721d99807f8BC7531'],
  },
  DERIVIO: {
    ZKSYNC: ['0x5f42930D145764794f0a106D9f1e032888558d03'],
  },
  ERALEND: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  AMBIENT: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  KYBER: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  XY: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ZEBRA: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  IZUMI: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  GRIZZLY: {},
  UNISWAP: {},
  LAYER_BANK: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SKYDROME: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SNS: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  PANCAKE_SWAP: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  DMAIL: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ECHO_DEX: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  HORIZON_DEX: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  WOOFI: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  LNS: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  DYSON: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  MENDI: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  VELOCORE: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SUSHI: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  XFAI: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  LYVE: {
    LINEA: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  KURAMA: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  PAPYRUS: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  COG: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  AAVE: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  POOL_SHARK: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  TENK: {
    SCROLL: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ODOS: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  FULCROM: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  VESYNC: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ORBIT: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SPACEBAR: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  PAC: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  JUICE: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  THRUSTER: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  MONOSWAP: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  RING: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  SYN_FUTURES: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ROGUEX: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  ABRACADABRA: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  MANGROVE: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  CAMBRIA: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  FANTASY: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  BLAST_NS: {
    BLAST: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  BIG_INT: {
    ZKSYNC: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  HENJIN: {
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  RITSU: {
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  IMAGINAIRYNFTS: {
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
  TAIKO_SWAP: {
    TAIKO: ['0xBB5c309A3a9347c0135B93CbD53D394Aa84345E5'],
  },
}

export const BRIDGE_REWARD_START = '2024-05-01T00:00:00.000Z'

export const ALPHA_KEY_END = '2024-07-17T23:59:59.000Z'

// export const enabledMilestoneCampaigns = [MilestoneCampaignID.SCROLL, MilestoneCampaignID.TAIKO]

export const LEGACY_PORTAL_URL = 'https://app-legacy.rhinofi.dev/portfolio/funds'

export const TOKEN_DISTRIBUTIONS = {
  TAIKO: [1],
}

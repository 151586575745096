import type { GetAllActiveMissionsResponse, GetActiveMissionsUserDataResponse } from '@rhinofi/rhino-nation-api-spec'
import type { UserVerification } from '../../../store/slices/types/MigrationStampedeState'

export const shouldHideEmailMission = ({
  userVerification,
  missions,
  missionsUserData,
}: {
  userVerification?: UserVerification | undefined
  missions: GetAllActiveMissionsResponse
  missionsUserData: GetActiveMissionsUserDataResponse
}) => {
  // Check if there are any active email missions (should only be one, but covering if there are many by accident)
  const emailMissions = missions.items.filter(({ type }) => type === 'ENTER_EMAIL')

  if (emailMissions.length > 0) {
    // Check if the user has completed any of the email missions
    const hasCompletedAnyEmailMission = emailMissions.reduce((acc, mission) => {
      if (acc === true) {
        return acc
      }

      const foundUserDataForEmailMission = missionsUserData.items.find(({ _id }) => _id.missionId === mission._id.id)
      return !!foundUserDataForEmailMission
    }, false)

    // If the user already has an email and he hasn't completed any of the active email missions, hide it
    if (userVerification?.email && !hasCompletedAnyEmailMission) {
      return true
    }
  }

  return false
}

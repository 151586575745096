import { Routes, Route } from 'react-router-dom'
import { BridgePage } from './pages/Bridge/BridgePage'
import { publicRoutes } from './router'
import { useChainBalances } from './services/ethereum/useChainBalances'
import { HeaderLayout } from './components/HeaderLayout/HeaderLayout'
import { usePollEndpoints } from './hooks/usePollEndpoints'
import { ResizeContextProvider } from './contexts'
import { AppRedirects } from './components/App/AppRedirects'
import { Main } from './Root.styled'
import { GlobalModals } from './components/GlobalModals'
import { lazy, Suspense } from 'react'
import { AppLoader } from './components/common/Helpers/AppLoader'
import { Social } from './components/Social/Social'
import { NationMissionsNotifications } from './pages/Nation/components/NationContent/NationMissionsNotifications/NationMissionsNotifications'
import { BottomCenterComms } from './components/BottomCenterComms/BottomCenterComms'
import { isFeatureEnabled } from './services/helperService/isFeatureEnabled'
import { F_FLAGS_TYPES } from './constants/types'
import { useQueryParamsTracker } from './hooks/useQueryParamsTracker'

const isMemecoinTrackerEnabled = isFeatureEnabled(F_FLAGS_TYPES.MEMECOIN_TRACKER)

const NationRoutes = lazy(() =>
  import('./pages/Nation/NationRoutes').then((module) => ({ default: module.NationRoutes })),
)

const ChainRoutes = lazy(() => import('./pages/Chain/ChainRoutes').then((module) => ({ default: module.ChainRoutes })))

const HistoryPage = lazy(() =>
  import('./pages/History/HistoryPage').then((module) => ({ default: module.HistoryPage })),
)

const MemecoinTracker = lazy(() =>
  import('./pages/MemecoinTracker/MemecoinTracker').then((module) => ({ default: module.MemecoinTracker })),
)

export const MainApp = () => {
  useChainBalances()
  usePollEndpoints()
  useQueryParamsTracker()

  return (
    <ResizeContextProvider>
      <Main>
        <HeaderLayout>
          <Suspense fallback={<AppLoader showOnPage source="suspense-mainapp" />}>
            <Routes>
              <Route path={publicRoutes.bridge} element={<BridgePage />} />
              <Route path={`${publicRoutes.nation}/*`} element={<NationRoutes />} />
              <Route path={`${publicRoutes.chainFeature}/*`} element={<ChainRoutes />} />
              <Route path={publicRoutes.history} element={<HistoryPage />} />
              {isMemecoinTrackerEnabled && <Route path={publicRoutes.memecoinTracker} element={<MemecoinTracker />} />}
              {AppRedirects()}
            </Routes>
          </Suspense>
        </HeaderLayout>

        <GlobalModals />
        <Social />
        <NationMissionsNotifications />
        <BottomCenterComms showOnMobile={false} />
      </Main>
    </ResizeContextProvider>
  )
}

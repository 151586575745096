import { useEffect } from 'react'
import styled from 'styled-components'
import { Button, Text, Title, Spacing, TitleSize, TypographyAlign, ButtonSizes } from '@rhinofi/dvf-shared-ui'
import { Modal } from '../../common/Modal/Modal'
import { clearModal } from '../../../store/actions/modalActions/clearModal'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { NETWORKS } from '../../../constants/types'
import { ParadexWalletOptions } from '../../../services/secondaryWallet/connectors/connectToParadex'
import {
  selectSecondaryWalletChain,
  selectSecondaryWalletStatus,
} from '../../../store/selectors/secondaryWallet.selectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { PerpetualLoader } from '../../common/Helpers/PerpetualLoader'
import { translate } from '../../../intl/i18n'
import paradexLogo from './assets/paradex.svg'
import { connectSecondaryWallet } from '../../../store/slices/secondaryWallet.slice'

import { useWallet } from '../../../hooks/useWallet'

export const ConnectToParadexModal = () => {
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectSecondaryWalletStatus)
  const connectedChain = useAppSelector(selectSecondaryWalletChain)
  const wallet = useWallet()

  useEffect(() => {
    if (status === 'fulfilled' && connectedChain === NETWORKS.PARADEX) {
      clearModal(dispatch)()
    }
  }, [status, dispatch, connectedChain])

  return (
    <Modal isVisible onClose={() => clearModal(dispatch)()} width="360px">
      <Title size={TitleSize.XXS}>{translate('bridge.connect_to_paradex')}</Title>
      {status === 'pending' ? (
        <>
          <Spacing size="16" />
          <Text>{translate('bridge.signature_description')}</Text>
          <Spacing size="16" />
          <PerpetualLoader />
          <Spacing size="16" />
          <Text align={TypographyAlign.Center}>{translate('global.please_approve_in_wallet')}</Text>
        </>
      ) : (
        <>
          <ParadexLogoWrapper>
            <img className="paradex-logo" src={paradexLogo} alt="" />
          </ParadexLogoWrapper>
          <Text align={TypographyAlign.Center} bold color="textSecondary">
            {translate('bridge.connect_with')}
          </Text>
          <Spacing size="16" />
          <Button
            id="connect-evm-paradex"
            fullWidth
            size={ButtonSizes.Large}
            onClick={() => {
              if (!wallet?.connectionKit || !wallet.ethersSigner) {
                return
              }
              void dispatch(
                connectSecondaryWallet({
                  chain: NETWORKS.PARADEX,
                  walletOption: ParadexWalletOptions.EVM,
                  ethersSigner: wallet.ethersSigner,
                }),
              )
            }}
          >
            {translate('bridge.existing_wallet')}
          </Button>
          <Spacing size="16" />
          <Button
            id="connect-evm-paradex"
            fullWidth
            size={ButtonSizes.Large}
            onClick={() =>
              dispatch(
                connectSecondaryWallet({
                  chain: NETWORKS.PARADEX,
                  walletOption: ParadexWalletOptions.STARKNET,
                  ethersSigner: wallet?.ethersSigner,
                }),
              )
            }
          >
            {translate('bridge.starknet_wallet')}
          </Button>
        </>
      )}
    </Modal>
  )
}

const ParadexLogoWrapper = styled.div`
  background-color: ${({ theme }) => theme.elevationL1};
  text-align: center;
  width: fit-content;
  padding: 16px;
  border-radius: 4px;

  margin: 4px auto;
  .paradex-logo {
    max-height: 28px;
  }
`

import { FetchHttpClient } from '@effect/platform'
import { AuthorizationMiddleware } from '@rhinofi/authentication-api-spec'
import { apiSpec } from '@rhinofi/bridge-api-spec'
import { flattenHttpApiClient } from '@rhinofi/effect-rtk'
import { makeHttpApiClientWithAuth } from '@rhinofi/effect-utils'
import { Effect } from 'effect'
import { AppConfig } from '../AppConfig'
import { getJWTTokenFromStateEffect } from '../auth/getJWTTokenFromStateEffect'

export class BridgeClient extends Effect.Service<BridgeClient>()('BridgeClient', {
  effect: Effect.gen(function* () {
    const baseUrl = `${yield* AppConfig.tradingApi}/bridge`

    const client = yield* makeHttpApiClientWithAuth(apiSpec, {
      baseUrl,
      authTokenEffect: getJWTTokenFromStateEffect,
      middleware: AuthorizationMiddleware,
    })

    const flat = flattenHttpApiClient(client)
    return {
      ...flat,
      // Added this to allow use of the same method with both
      // Query and Mutation.
      quoteUserQuote2: flat.quoteUserQuote,
    }
  }),
  dependencies: [AppConfig.Default, FetchHttpClient.layer],
}) {}

import { Button, Input } from '@rhinofi/dvf-shared-ui'
import type { FormikProps } from 'formik'
import { Form } from 'formik'
import { useCallback } from 'react'
import { ReCaptchaPolicy } from '../../../../../components/ReCaptcha/ReCaptchaPolicy'
import {
  UserCredentialForm,
  type UserCredentialFormValues,
} from '../../../../../components/UserVerification/UserCredentialForm'
import { VerificationType } from '../../../../../components/UserVerification/UserVerificationModal'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { useAppSelector } from '../../../../../hooks'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { translate } from '../../../../../intl/i18n'
import { useLazyIsUserVerifiedQuery } from '../../../../../services/userVerificationApi'
import { clearModal } from '../../../../../store/actions/modalActions/clearModal'
import { selectAddress } from '../../../../../store/selectors/user.selectors'
import { NationCTAAccessWrapper } from '../../NationCTAAccessWrapper'
import { NationMissionModalReward } from './NationMissionModalReward'

type Props = {
  gems: number
}

const renderForm =
  ({ gems }: Props) =>
  ({ isValid, values, isSubmitting }: FormikProps<UserCredentialFormValues>) => {
    const isEmpty = !values.credential

    return (
      <Form>
        <FlexContainer $gap="16px" $alignItems="stretch" $direction="column">
          <Input
            disabled={isSubmitting}
            placeholder={translate('gated.email_placeholder')}
            id="nation-mission-email"
            name="credential"
            type="text"
          />

          <NationMissionModalReward gems={gems} />

          <div>
            <Button
              fullWidth
              disabled={(!isValid && !isEmpty) || isSubmitting}
              type="submit"
              id="nation-mission-add-email"
            >
              {translate('nation.sign_up_for_email_alerts')}
            </Button>

            <ReCaptchaPolicy />
          </div>
        </FlexContainer>
      </Form>
    )
  }

export const NationMissionModalEmailForm = ({ gems }: Props) => {
  const dispatch = useAppDispatch()
  const address = useAppSelector(selectAddress)
  const [getUserVerification] = useLazyIsUserVerifiedQuery()

  const refreshEmailVerification = useCallback(async () => {
    if (!address) {
      return
    }

    const userVerificationData = await getUserVerification({ user: address })

    if (userVerificationData) {
      clearModal(dispatch)()
    }
  }, [dispatch, address, getUserVerification])

  return (
    <NationCTAAccessWrapper>
      <UserCredentialForm
        verificationType={VerificationType.email}
        nextStep={refreshEmailVerification}
        renderForm={renderForm({ gems })}
      />
    </NationCTAAccessWrapper>
  )
}

import { useNationStatus } from '../../../hooks/nation/useNationStatus'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectAddress, selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { NationUserStatus } from '../types/nation.types'

export const useHasUserFullNationAccess = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const { status } = useNationStatus()
  const address = useAppSelector(selectAddress)

  return status === NationUserStatus.Joined && isAuthenticated && address
}

import { Icon } from '@rhinofi/dvf-shared-ui'
import { useLayoutEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { zIndices } from '../../constants/zIndex'
import { useAppSelector } from '../../hooks/useAppSelector'

import { useWallet } from '../../hooks/useWallet'
import { selectAddress } from '../../store/selectors/user.selectors'

const noScrollClass = 'no-scroll'
const crispBaseUrl = 'https://go.crisp.chat/chat/embed/?website_id=598d8062-c46b-40e7-a7cd-0bf2061390a5'

type Props = {
  showChat: boolean
  setShowChat: (show: boolean) => void
}

export const MobileSupportChat = ({ showChat, setShowChat }: Props) => {
  const wallet = useWallet()
  const address = useAppSelector(selectAddress)

  const crispFullUrl = useMemo(
    () => `${crispBaseUrl}&user_nickname=${address}-${wallet?.connectingWallet?.walletType}`,
    [wallet, address],
  )

  useLayoutEffect(() => {
    if (showChat) {
      document.body.classList.add(noScrollClass)
      return () => {
        document.body.classList.remove(noScrollClass)
      }
    }
    return
  }, [showChat])

  if (!showChat) {
    return null
  }

  return createPortal(
    <Chat $showChat={showChat}>
      <CloseWrapper onClick={() => setShowChat(false)}>
        <Icon id="x" className="closeIcon" />
      </CloseWrapper>
      <iframe title="Chat" src={crispFullUrl} />
    </Chat>,
    document.body,
  )
}

const Chat = styled.div<{ $showChat: boolean }>`
  display: ${({ $showChat: showChat }) => (showChat ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  margin: auto auto;
  box-sizing: border-box;
  width: 100vw;
  height: auto;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(15px);
  transition: background-color 0.1s linear;
  z-index: ${zIndices.mobileSupportChat};

  iframe {
    height: 90%;
    width: 90%;
    border-radius: 8px;
    border: none;
  }
`

const CloseWrapper = styled.div`
  position: absolute;
  right: calc(5% - 10px);
  top: calc(5% - 10px);
  z-index: 100;
  background: ${({ theme }) => theme.offWhite};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid rgb(29, 90, 93); // crisp color
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .closeIcon {
    color: ${({ theme }) => theme.offBlack};
  }
`

import { Button, ButtonSizes, Icon, IconSizes, Label, Spacing, Text, Title, TokenIcon } from '@rhinofi/dvf-shared-ui'
import { LabelSize, TextSize, TitleSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import { truncate } from 'lodash-es'
import moment from 'moment'
import { SmallSpinner } from '../../../../../components/common/Helpers/SmallSpinner'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { useAppSelector } from '../../../../../hooks'
import { useExplorerLink } from '../../../../../hooks/useExplorerLink'
import { translate } from '../../../../../intl/i18n'
import { formatFloat } from '../../../../../services/helperService/formatFloat'
import { isNonEVMChain } from '../../../../../services/helperService/isNonEVMChain'
import { shortenAddress } from '../../../../../services/helperService/shortenAddress'
import { selectSecondaryWalletAddress } from '../../../../../store/selectors/secondaryWallet.selectors'
import { selectAddress } from '../../../../../store/selectors/user.selectors'
import {
  PaleText,
  PendingCard,
  PendingCardItem,
  PendingSpinnerSection,
  Summary,
  SummaryItem,
} from '../../../Bridge.styled'
import { selectBridge } from '../../../slices/bridge.slice'
import { selectBridgeProgress } from '../../../slices/bridgeProgress.slice'
import { SpendingCapPending } from './SpendingCapPending'

type PendingBridgeStateProps = {
  transactionHash: string | undefined
  prettyChainInName: string
  prettyChainOutName: string
  customTitle?: string
}

const TOKEN_ICON_SIZE = '32px'

export const PendingBridgeState = ({
  customTitle,
  transactionHash,
  prettyChainInName,
  prettyChainOutName,
}: PendingBridgeStateProps) => {
  const address = useAppSelector(selectAddress)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)
  const { chainIn, chainOut, token, amount, recipient } = useAppSelector(selectBridge)
  const { type, timestamp, isTxSubmitted, isTxConfirmed } = useAppSelector(selectBridgeProgress)
  const { link } = useExplorerLink(transactionHash, chainIn)

  const from = isNonEVMChain(chainIn) ? secondaryWalletAddress : address
  const to = recipient || (isNonEVMChain(chainOut) ? secondaryWalletAddress : address)

  return type === 'approval' ? (
    <SpendingCapPending token={token} />
  ) : (
    <>
      <Title size={TitleSize.XXS}>
        {customTitle || isTxSubmitted ? translate('bridge.bridging') : translate('bridge.confirm_in_wallet')}
      </Title>
      <PendingSpinnerSection>
        <SmallSpinner size={48} />
      </PendingSpinnerSection>
      <PendingCard>
        <FlexContainer className="bridge-path-icon" $justifyContent="center">
          <Icon id="arrow-right-short" size={IconSizes.Big} />
        </FlexContainer>
        <PendingCardItem>
          <TokenIcon customSize={TOKEN_ICON_SIZE} token={chainIn} size="large" />
          <Text className="pending-chain-title" size={TextSize.S} bold>
            {truncate(prettyChainInName, { length: 15 })}
          </Text>
          {(isTxSubmitted || isTxConfirmed) && transactionHash ? (
            <FlexContainer $gap="8px" $alignItems="center">
              {isTxConfirmed ? <Icon id="check-circle-fill" className="success-bridge" /> : <SmallSpinner />}
              <Label size={LabelSize.Label}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {translate('global.view_on_explorer')}
                </a>
              </Label>
            </FlexContainer>
          ) : (
            <Text size={TextSize.XS} className="pending-text">
              {translate('helpers.pending')}
            </Text>
          )}
        </PendingCardItem>
        <PendingCardItem>
          <TokenIcon customSize={TOKEN_ICON_SIZE} token={chainOut} size="large" />
          <Text className="pending-chain-title" size={TextSize.S} bold title={prettyChainOutName}>
            {truncate(prettyChainOutName, { length: 15 })}
          </Text>
          <Text size={TextSize.XS} className="pending-text">
            {translate('helpers.pending')}
          </Text>
        </PendingCardItem>
      </PendingCard>
      <Summary $withBorder>
        <SummaryItem>
          <PaleText size={TextSize.XS}>{translate('helpers.amount')}</PaleText>
          <Text size={TextSize.S} bold>
            {formatFloat(amount)} {token}
          </Text>
        </SummaryItem>
        <SummaryItem>
          <PaleText size={TextSize.XS}>{translate('helpers.timestamp')}</PaleText>
          <PaleText size={TextSize.XS}>{timestamp ? moment(timestamp).format('DD-MM HH:mm:ss') : '...'}</PaleText>
        </SummaryItem>
        <SummaryItem>
          <PaleText size={TextSize.XS}>{translate('helpers.from')}</PaleText>
          <PaleText size={TextSize.XS}>{shortenAddress(from)}</PaleText>
        </SummaryItem>
        <SummaryItem>
          <PaleText size={TextSize.XS}>{translate('helpers.to')}</PaleText>
          <PaleText size={TextSize.XS}>{shortenAddress(to)}</PaleText>
        </SummaryItem>
      </Summary>
      <Spacing size="16" />

      <Button disabled id="#" fullWidth size={ButtonSizes.Large}>
        {isTxSubmitted ? `${translate('helpers.pending')}...` : translate('global.please_approve_in_wallet')}
      </Button>
    </>
  )
}

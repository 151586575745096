import { useMemo } from 'react'
import styled from 'styled-components'
import { Text, TextSize, Icon, IconSizes, Tooltip } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../../intl/i18n'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import type { MissionWithUserData } from '../../types/nation.types'
import { isMissionFullyCompleted } from '../../utils/isMissionFullyCompleted'
import { getMissionCompletedStats } from '../../utils/getMissionCompletedStats'

type Props = {
  data: MissionWithUserData
  isHistory?: boolean | undefined
}

export const NationMissionProgressStats = ({ data, isHistory }: Props) => {
  const { mission } = data
  const completedStats = getMissionCompletedStats(data)
  const isFullyCompleted = isMissionFullyCompleted(data)

  const showAchievementsCountLabel = isHistory ? true : mission.minTimesToAchieve > 1

  const achievementsCountLabel = useMemo(() => {
    if (!showAchievementsCountLabel) {
      return ''
    }

    if (isHistory || isFullyCompleted) {
      return `${mission.minTimesToAchieve}/${mission.minTimesToAchieve}`
    }

    return `${completedStats.timesAchievedForCurrentLevel}/${mission.minTimesToAchieve}`
  }, [isHistory, mission, isFullyCompleted, completedStats, showAchievementsCountLabel])

  const showCompletedCountLabel = useMemo(() => {
    if (isHistory) {
      return true
    }

    if (mission.maxTimesCanBeRewarded === 1) {
      return false
    }

    return mission.maxTimesCanBeRewarded && mission.maxTimesCanBeRewarded > 1 ? true : completedStats.currentLevel > 0
  }, [isHistory, mission, completedStats])

  const completedCountLabel = useMemo(() => {
    if (!showCompletedCountLabel) {
      return ''
    }

    if (isHistory) {
      return mission.maxTimesCanBeRewarded === null
        ? `${translate('achievements.completed')} 1`
        : translate('nation.completed_of', {
            '%count': mission.maxTimesCanBeRewarded,
          })
    }

    const completedCount =
      mission.maxTimesCanBeRewarded === null
        ? completedStats.currentLevel
        : `${completedStats.currentLevel}/${mission.maxTimesCanBeRewarded}`

    return `${translate('achievements.completed')} ${completedCount}`
  }, [isHistory, mission, completedStats, showCompletedCountLabel])

  return (
    <Container $justifyContent="space-between" $alignItems="stretch">
      <FlexContainer $gap="4px">
        {showAchievementsCountLabel && (
          // On purpose done like this so that the completed count label stays on the right due to
          // when the achievements part isn't due to space-between
          <>
            <Tooltip tooltipContent={<Text size={TextSize.XS}>{translate('nation.num_achieved_desc')}</Text>}>
              <InfoIcon id="info-square" size={IconSizes.XSmall} />
            </Tooltip>

            <Text size={TextSize.XS}>{achievementsCountLabel}</Text>
          </>
        )}
      </FlexContainer>

      {showCompletedCountLabel && <Text size={TextSize.XS}>{completedCountLabel}</Text>}
    </Container>
  )
}

export const Container = styled(FlexContainer)`
  padding: 0 8px;
`

const InfoIcon = styled(Icon)`
  position: relative;
  top: 1px;
  display: flex;
`

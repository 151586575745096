import { Text } from '@rhinofi/dvf-shared-ui'
import { TextSize } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import styled from 'styled-components'
import { useAppSelector } from '../../../../hooks'
import { translate } from '../../../../intl/i18n'
import { useAuth } from '../../../../services/auth/useAuth'
import { selectIsAuthenticated } from '../../../../store/selectors/user.selectors'
import { Opacify } from '../../../common/Helpers/Opacify'
import { FlexContainer } from '../../../common/Wrappers/Containers'
import { StepIcon } from './StepIcon'

export const AuthenticationSteps = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const { authRejected, authRequested } = useAuth()

  return (
    <AuthSection>
      <FlexContainer $gap="16px">
        <div>
          <StepIcon
            iconId="person-fill"
            isLoading={authRequested}
            isDone={isAuthenticated}
            isActive={!isAuthenticated}
            isError={authRejected}
          />
        </div>
        <Opacify $opacity={0.4} $active={isAuthenticated}>
          <Text size={TextSize.L} bold>
            {isAuthenticated
              ? translate('onboarding.authentication_step_1_title_success')
              : translate('onboarding.authentication_step_1_title')}
          </Text>
          <Text size={TextSize.S} color="textSecondary">
            {isAuthenticated
              ? translate('onboarding.authentication_step_1_desc_success')
              : translate('onboarding.authentication_step_1_desc')}
          </Text>
        </Opacify>
      </FlexContainer>
    </AuthSection>
  )
}

const AuthSection = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.divider};
  border-radius: 16px;
  margin-bottom: 24px;
`

import { useState, useMemo, useEffect } from 'react'
import { useAppSelector } from '../../../hooks'
import { translate } from '../../../intl/i18n'
import { selectTokenPrices } from '../../../services/usdPricesApi'
import { selectBridgeChainsConfig } from '../../../store/selectors/bridgeConfig.selectors'
import { selectBridge } from '../slices/bridge.slice'
import { SafeDecimal } from '../../../utils/SafeDecimal'

const defaultEmptyValue = {
  amountNative: '',
  maxNativeTokenCost: '',
  amountNativeTokenCost: '',
}

export type GasBoostOptions = {
  gasBoost: number
  setGasBoost: (gasBoost: number) => void
  amountNative: string
  maxNativeTokenCost: string
  amountNativeTokenCost: string
}
export const useGasBoost = () => {
  const [error, setError] = useState('')
  const [gasBoost, setGasBoost] = useState(0)

  const { token, chainOut } = useAppSelector(selectBridge)
  const tokenPrices = useAppSelector(selectTokenPrices)
  const { chainOutConfig } = useAppSelector(selectBridgeChainsConfig)
  const nativeToken = chainOutConfig?.nativeTokenName || ''

  useEffect(() => {
    if (nativeToken === token || !chainOutConfig?.gasBoostEnabled) {
      setGasBoost(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run only on token/chain change
  }, [token, chainOut, chainOutConfig])

  const { amountNative, maxNativeTokenCost, amountNativeTokenCost } = useMemo(() => {
    setError('')
    if (gasBoost === 0 || nativeToken === '') {
      return defaultEmptyValue
    }
    const priceNative = tokenPrices[nativeToken]
    const price = tokenPrices[token]
    if (!price || !priceNative) {
      setError(translate('errors.no_price_native'))
      return defaultEmptyValue
    }
    return {
      amountNative: SafeDecimal(gasBoost).div(priceNative).toString(),
      amountNativeTokenCost: SafeDecimal(gasBoost).mul(SafeDecimal(1).div(price)).toString(),
      maxNativeTokenCost: SafeDecimal(gasBoost).mul(SafeDecimal(1).div(price)).mul(1.1).toString(),
    }
  }, [gasBoost, tokenPrices, nativeToken, token])

  return {
    gasBoost,
    setGasBoost,
    amountNative,
    amountNativeTokenCost,
    maxNativeTokenCost,
    error,
  }
}

import { Button } from '@rhinofi/dvf-shared-ui'
import { useCallback } from 'react'
import { FlexContainer } from '../../../../../../components/common/Wrappers/Containers'
import type { PerkWithUserData } from '../../../../types/nation.types'
import type {
  ActivePerkModalSetRewardFunc,
  ActivePerkModalSetViewFunc,
} from '../../../../types/nationActivePerkModal.types'
import { canUserClaimPerk } from '../../../../utils/canUserClaimPerk'
import { NationPerkClaimedBoxWithState } from '../../NationPerkClaimedBoxWithState'
import { createPerkHeapId } from '../../../../utils/createPerkHeapId'
import { useGemsBalance } from '../../../../hooks/useGemsBalance'
import { NationCTAAccessWrapper } from '../../../NationCTAAccessWrapper'

type Props = {
  perkWithUserData: PerkWithUserData
  setView: ActivePerkModalSetViewFunc
  setReward: ActivePerkModalSetRewardFunc
}

export const NationPerkModalClaimButtons = ({ setView, setReward, perkWithUserData }: Props) => {
  const { currentData } = useGemsBalance()

  const canClaim = canUserClaimPerk({ perkWithUserData, userGems: currentData })

  const handleGoToClaimClickCallback = useCallback(() => {
    setReward(null)
    setView('CLAIM')
  }, [setView, setReward])

  const perkId = createPerkHeapId(perkWithUserData.perk)

  return (
    <NationCTAAccessWrapper>
      <FlexContainer $gap="16px">
        <NationPerkClaimedBoxWithState data={perkWithUserData} />

        {canClaim.subType !== 'claimedMax' && (
          <Button
            fullWidth
            id={`perk-modal-go-to-claim-${perkId}`}
            disabled={canClaim.type === 'rejected'}
            onClick={handleGoToClaimClickCallback}
          >
            {canClaim.message}
          </Button>
        )}
      </FlexContainer>
    </NationCTAAccessWrapper>
  )
}

import styled from 'styled-components'
import { Icon } from '@rhinofi/dvf-shared-ui'
import { TxStatus } from '../../../../services/txService/txService.types'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { SmallSpinner } from '../../../../components/common/Helpers/SmallSpinner'
import { useExplorerLink } from '../../../../hooks/useExplorerLink'

type Props = {
  txHash: string
  chain: string
  txStatus: TxStatus
}

export const ContractInteractionsTxModalTxStatusLink = ({ txHash, txStatus, chain }: Props) => {
  const { link } = useExplorerLink(txHash, chain, true)

  return (
    <FlexContainer $gap="4px">
      {txStatus === TxStatus.SUCCESS && <Icon id="check-circle-fill" color="accentGreen" />}
      {txStatus === TxStatus.PENDING && <SmallSpinner />}

      <ExplorerLink href={link} target="_blank" rel="noopener noreferrer">
        {`tx:${txHash.substr(0, 9)}...`}
      </ExplorerLink>
    </FlexContainer>
  )
}

const ExplorerLink = styled.a`
  color: ${({ theme }) => theme.secondary};
`

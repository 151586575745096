import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Signer } from 'ethers'
import { modalKeys } from '../../constants/modalKeys'
import { NETWORKS } from '../../constants/types'
import { checkWalletInstalled } from '../../services/secondaryWallet/checkWalletInstalled'
import { connectToParadex, ParadexWalletOptions } from '../../services/secondaryWallet/connectors/connectToParadex'
import { connectToSolana } from '../../services/secondaryWallet/connectors/connectToSolana'
import { connectToStarknet } from '../../services/secondaryWallet/connectors/connectToStarknet'
import { connectToTon } from '../../services/secondaryWallet/connectors/connectToTon'
import { connectToTron } from '../../services/secondaryWallet/connectors/connectToTron'
import type { SecondaryConnectionPayload } from '../../services/secondaryWallet/connectors/connectors.types'
import { triggerReconnectModal } from '../../services/secondaryWallet/triggerReconnectModal'
import { showModal } from '../actions/modalActions/showModal'
import { requireChain } from '../actions/user.actions'
import type { RootState } from '../configureStore'
import { selectBridgeConfigForChain } from '../selectors/bridgeConfig.selectors'
import { setWallet } from './user.slice'

export const defaultSecondaryConnectState = {
  address: '',
  networkId: '',
  domain: '',
  connectedChain: '',
}
export const connectSecondaryWallet = createAsyncThunk<
  SecondaryConnectionPayload,
  {
    chain: string
    walletOption?: string
    ethersSigner?: Signer | null | undefined
  },
  { state: RootState }
>('secondary/connect', async ({ chain, walletOption = '', ethersSigner = undefined }, { dispatch, getState }) => {
  try {
    const currentState = getState().secondaryWallet
    const chainConfig = selectBridgeConfigForChain(getState(), chain)

    const isInstalled = checkWalletInstalled(chain)
    if (!chainConfig) {
      throw new Error('No config available.')
    }
    if (!isInstalled) {
      showModal(dispatch)(modalKeys.noWallet, { chain })
      return currentState
    }
    if (!!currentState.connectedChain && currentState.connectedChain !== chain && !walletOption) {
      const canProceed = await triggerReconnectModal(dispatch, currentState.connectedChain, chain)
      if (!canProceed) {
        return currentState
      }
    }
    if (chain === NETWORKS.PARADEX && !walletOption) {
      showModal(dispatch)(modalKeys.connectToParadex)
      return currentState
    }
    switch (chain) {
      case NETWORKS.STARKNET:
        return connectToStarknet(dispatch, chainConfig.rpc)
      case NETWORKS.PARADEX: {
        if (!ethersSigner) {
          throw new Error('No ethers signer provided')
        }
        if (walletOption === ParadexWalletOptions.EVM) {
          const switchChainResult = await requireChain(dispatch)(NETWORKS.ETHEREUM)
          if (switchChainResult.done === false) {
            return currentState
          }
        }
        const starknetChainConfig = selectBridgeConfigForChain(getState(), NETWORKS.STARKNET)
        if (!starknetChainConfig) {
          throw new Error('No starknet config available')
        }
        return connectToParadex(walletOption, ethersSigner, starknetChainConfig.rpc)
      }
      case NETWORKS.TRON:
        return connectToTron(dispatch, chainConfig.networkId)
      case NETWORKS.SOLANA:
        return connectToSolana(dispatch, chainConfig.rpc, chainConfig.networkId)
      case NETWORKS.TON:
        return connectToTon(chainConfig.rpc)
      default:
        return defaultSecondaryConnectState
    }
  } catch (error) {
    console.error(error)
    return defaultSecondaryConnectState
  }
})

export const secondaryWalletSlice = createSlice({
  name: 'secondaryWallet',
  initialState: {
    status: '',
    address: '',
    networkId: '',
    domain: '',
    connectedChain: '',
  },
  reducers: {
    updateSecondaryAddress: (state, action: PayloadAction<string>) => {
      if (state.address === action.payload) {
        return
      }
      state.address = action.payload
    },
    updateSecondaryNetworkId: (state, action: PayloadAction<string>) => {
      if (state.networkId === action.payload) {
        return
      }
      state.networkId = action.payload
    },
    updateSecondaryDomain: (state, action: PayloadAction<string>) => {
      if (state.domain === action.payload) {
        return
      }
      state.domain = action.payload
    },
    disconnectSecondaryWallet: (state) => {
      state.address = ''
      state.networkId = ''
      state.domain = ''
      state.connectedChain = ''
      state.status = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectSecondaryWallet.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(connectSecondaryWallet.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(connectSecondaryWallet.fulfilled, (state, action) => {
      if (!action.payload.address) {
        state.status = ''
        return
      }
      state.address = action.payload.address
      state.networkId = action.payload.networkId
      state.domain = action.payload.domain
      state.connectedChain = action.payload.connectedChain
      state.status = 'fulfilled'
    })
    builder.addCase(setWallet, (state) => {
      // As paradex accounts are based on the primary wallet, disconnect so user can re-generate the key
      if (state.connectedChain === NETWORKS.PARADEX) {
        state.address = ''
        state.networkId = ''
        state.domain = ''
        state.connectedChain = ''
        state.status = ''
      }
    })
  },
})

export const { updateSecondaryAddress, updateSecondaryNetworkId, updateSecondaryDomain, disconnectSecondaryWallet } =
  secondaryWalletSlice.actions

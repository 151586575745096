import styled from 'styled-components'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'

export const GasBoostButtons = styled(FlexContainer)`
  gap: 4px;

  .gas-boost-button {
    flex-grow: 1;
    min-height: 36px;
  }
`

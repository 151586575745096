/* eslint-disable @typescript-eslint/no-unsafe-assignment -- error parsing  */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- error parsing  */
/* eslint-disable @typescript-eslint/no-unsafe-call -- error parsing */

import { dynamicT, translate } from '../intl/i18n'
import type { LegacyEnvConfig } from '../env/envConfig'
import { envConfig } from '../env/envConfig'
import { NETWORKS } from '../constants/types'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import moment from 'moment'

const { cloudFlareWebAnalyticsScript } = envConfig

// export const saveLastLogin = (lastLogin: { type: SupportedWallets; path?: string; address: string; name?: string }) => {
//   localStorage.setItem('last-login', JSON.stringify(lastLogin))
// }

// export const getLastLogin = () => {
//   const lastLogin = localStorage.getItem('last-login')
//   return lastLogin ? JSON.parse<{ type: string; path?: string; name?: string }>(lastLogin) : { type: 'none' }
// }

export const parseWalletError = (anyError: any) => {
  // eslint-disable-next-line functional/no-let -- Legacy
  let error = anyError.message || (typeof anyError === 'string' && anyError) || ''
  if (error.indexOf('Metamask+Ledger2') >= 0) {
    return translate('errors.metamask_ledger2')
  }
  if (error.indexOf('Metamask+Ledger') >= 0) {
    return translate('errors.metamask_ledger')
  }
  if (error.toLowerCase().indexOf('failed to fetch') >= 0) {
    return translate('errors.failed_to_fetch')
  }
  if (error.indexOf('denied transaction') >= 0) {
    return translate('errors.user_denied_tx')
  }
  if (error.indexOf('MetaMask') >= 0) {
    if (error.indexOf('\n') >= 0) {
      error = error.substr(0, error.indexOf('\n'))
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- error parsing
    return error.replace('Returned error: Error: ', '')
  }
  if (error.indexOf('0x6700') >= 0) {
    return translate('global.error_ledger_eth')
  }
  if (error.indexOf('6801') >= 0 || error.indexOf('6804') >= 0 || error.indexOf('0x6b0c') >= 0) {
    return translate('global.error_ledger_locked')
  }
  if (error.indexOf('6a80') >= 0) {
    return translate('global.error_ledger_contract_data')
  }
  if (error.indexOf('6985') >= 0) {
    return translate('global.error_ledger_confirmation_denied')
  }
  if (error === 'Sign failed') {
    return translate('global.error_ledger_timed_out')
  }
  if (error === 'Uncaught Error: Key derivation failed - possibly wrong passphrase') {
    return translate('global.error_wrong_password')
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- Legacy
  if (['_context3.t0 is not a constructor', 'e.t0 is not a constructor'].includes(error)) {
    return translate('errors.wallet_connection_cancelled')
  }
  if (error.indexOf('eth_requestAccounts') >= 0) {
    return translate('global.metamask_locked')
  }
  if (error.indexOf('User closed modal') >= 0 || error.indexOf('User denied account authorization') >= 0) {
    return ''
  }
  if (error.indexOf('wallet_requestPermissions') >= 0) {
    return translate('global.metamask_wallet_connection_pending')
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- Legacy
  return dynamicT(error)
}

export const getTransactionSlug = (chain: string) => {
  if (chain === NETWORKS.TRON) {
    return '#/transaction'
  } else if (chain === NETWORKS.TON) {
    return 'transaction'
  } else {
    return 'tx'
  }
}

export const getAccountSlug = (chain: string) => {
  if (chain === NETWORKS.STARKNET || chain === NETWORKS.PARADEX) {
    return 'contract'
  }

  if (chain === NETWORKS.SOLANA) {
    return 'account'
  }

  return 'address'
}

export const formatTargetHash = (target: string, chain: string) => {
  if (chain === NETWORKS.TON) {
    const targetParsed = target.startsWith('0x') ? target.substring(2) : target
    return targetParsed.replace(/\+/g, '-').replace(/\//g, '_')
  }
  return target
}

export const getConfigKey = <T extends keyof LegacyEnvConfig>(key: T): LegacyEnvConfig[T] => {
  const fromLocalStorage = localStorage.getItem(key)
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy
  return fromLocalStorage ? (JSON.parse(fromLocalStorage) as LegacyEnvConfig[T]) || envConfig[key] : envConfig[key]
}

export const capitalize = (value?: string) => (value ? value.replace(/\b\w/g, (letter) => letter.toUpperCase()) : '')

export const convertMtsToLabel = (mts?: string) => {
  if (!mts) {
    return ''
  }
  const date = new Date(parseInt(mts))
  const currentDate = new Date()
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const month = months[date.getMonth()]
  if (date.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
    return `${date.getDate()} ${month}`
  }
  return moment(parseInt(mts)).format('HH:mm')
}

export const timestampToHumanTime = (timestamp: undefined | number | string) =>
  moment(timestamp).format('MMMM Do YYYY, h:mm:ss a')

export const isSafariIOS = () => {
  const ua = window.navigator.userAgent
  const iOS = !!/iPad/i.exec(ua) || !!/iPhone/i.exec(ua)
  const webkit = !!/WebKit/i.exec(ua)
  return iOS && webkit && !/CriOS/i.exec(ua)
}

export const initCloudflareScrypt = () => {
  if (cloudFlareWebAnalyticsScript) {
    const script = document.createElement('script')
    // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    script.setAttribute('defer', true)
    script.setAttribute('src', 'https://static.cloudflareinsights.com/beacon.min.js')
    script.setAttribute('data-cf-beacon', '{"token": "588ccd445aa643d4b0f136a303d3a154"}')
    document.body.appendChild(script)
  }
}

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error != null && 'message' in error && typeof error.message === 'string'
}

export const getRTKErrMessage = (err: unknown) => {
  if (!err) {
    return ''
  }

  if (isFetchBaseQueryError(err)) {
    return 'error' in err ? err.error.toString() : JSON.stringify(err.data)
  } else if (isErrorWithMessage(err)) {
    return err.message
  }

  return 'Error has occurred'
}

export const errorToString = (error: unknown) => {
  if (typeof error === 'string') {
    return error
  }
  if (error instanceof Error) {
    return error.message
  }
  if (error instanceof Object) {
    return JSON.stringify(error)
  }
  return 'Unknown error'
}

export const hex2rgb = (hex: string): number[] => {
  const red = parseInt(hex.slice(1, 3), 16)
  const green = parseInt(hex.slice(3, 5), 16)
  const blue = parseInt(hex.slice(5, 7), 16)

  return [red, green, blue]
}

import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import styled from 'styled-components'
import GemIcon from '../../../../../assets/icons/gem.svg?react'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import { translate } from '../../../../../intl/i18n'
import { NATION_XP_LABEL } from '../../../constants/nationGeneral.constants'

type Props = {
  gems: number
}

export const NationMissionModalReward = ({ gems }: Props) => (
  <Container $justifyContent="space-between">
    <Text>{translate('tracker.reward')}</Text>

    <FlexContainer $gap="16px">
      <FlexContainer $gap="4px">
        <Text>+{gems}</Text>
        <GemIcon />
      </FlexContainer>

      <FlexContainer $alignItems="baseline">
        <Text>+{gems}</Text>
        <Text size={TextSize.XS} color="secondary60">
          {NATION_XP_LABEL}
        </Text>
      </FlexContainer>
    </FlexContainer>
  </Container>
)

const Container = styled(FlexContainer)`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.secondary20};
  border-radius: 8px;
`

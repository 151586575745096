import { ethers } from 'ethers'
import { useCallback } from 'react'
import { useAppSelector, useConfig } from '../../hooks'
import { useWallet } from '../../hooks/useWallet'
import { selectConnectedNetwork } from '../../store/selectors/user.selectors'
import { getChainConfig } from '../../utils/getChainConfig'
import { isNonEVMChain } from '../helperService/isNonEVMChain'

export const useGetReadSigner = () => {
  const { config } = useConfig()
  const wallet = useWallet()
  const connectedNetwork = useAppSelector(selectConnectedNetwork)

  return useCallback(
    (chain: string) => {
      if (!connectedNetwork || !config || isNonEVMChain(chain)) {
        return null
      }

      const chainConfig = getChainConfig(config)({
        chain,
      })

      try {
        // get connected network config
        const connectedChainConfig = getChainConfig(config)({
          chainId: connectedNetwork.toString(),
        })

        // if chain is connected network - return wallet singer
        if (chain === connectedChainConfig.chainKey) {
          return wallet.ethersSigner
        }

        // else return json RPC signer
        const network = new ethers.Network(chainConfig.name, chainConfig.networkId)
        return new ethers.JsonRpcProvider(chainConfig.rpc, network, { staticNetwork: true })
      } catch {
        const network = new ethers.Network(chainConfig.name, chainConfig.networkId)
        return new ethers.JsonRpcProvider(chainConfig.rpc, network, { staticNetwork: true })
      }
    },
    [config, connectedNetwork, wallet.ethersSigner],
  )
}

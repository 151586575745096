import { NationPerkClaimedBox } from './NationPerkClaimedBox/NationPerkClaimedBox'
import type { PerkWithUserData } from '../../types/nation.types'
import type { NationPerkClaimedBoxSize } from '../../types/nationPerkClaimedBox.types'

type Props = {
  data: PerkWithUserData
  size?: NationPerkClaimedBoxSize
  fullWidth?: boolean
}

export const NationPerkClaimedBoxWithState = ({ data: { perk, userData }, size, fullWidth = true }: Props) => {
  const maxClaimableTimesNormalized = perk.maxClaimableTimes || 1
  const showClaimNum = maxClaimableTimesNormalized > 1

  // Do not show this component if the user can claim an infinite amount of times
  // or if he hasn't claimed at all
  if (userData === null || perk.maxClaimableTimes === null) {
    return null
  }

  return (
    <NationPerkClaimedBox
      size={size}
      widthType={fullWidth ? 'full' : 'fit'}
      additionalText={showClaimNum ? `${userData.timesPurchased || 0}/${perk.maxClaimableTimes}` : ''}
    />
  )
}

import { Icon, Text, TextSize } from '@rhinofi/dvf-shared-ui'
import type React from 'react'
import styled from 'styled-components'

type Props = {
  title: string
  rightIcon?: string
  handleClick?: () => void
}

export const Alert = ({ title, rightIcon, handleClick }: Props) => {
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (handleClick) {
      event.stopPropagation()
      event.preventDefault()
      handleClick()
    }
  }

  const Content = () => (
    <>
      <ContentWrapper>
        <Icon id="info-circle" color="warningDark" isOnButton />
        <Text color="warningDark" bold size={TextSize.XS} noLineHeight>
          {title}
        </Text>
      </ContentWrapper>
      {rightIcon && <Icon id={rightIcon} color="warningDark" isOnButton />}
    </>
  )

  if (!handleClick) {
    return (
      <BasicWrapper>
        <Content />
      </BasicWrapper>
    )
  }

  return (
    <ButtonWrapper onClick={onClick}>
      <Content />
    </ButtonWrapper>
  )
}

const BasicWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.warningLight};
  border: 1px solid ${({ theme }) => theme.warningMain};
  border-radius: 8px;
  padding: 0px 16px;
  min-height: 48px;
  box-sizing: border-box;
`

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.warningLight};
  border: 1px solid ${({ theme }) => theme.warningMain};
  border-radius: 8px;
  padding: 0px 16px;
  min-height: 48px;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.warningDark};
  }
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

import { useEffect } from 'react'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { useAppSelector } from '../hooks/useAppSelector'
import { useWallet } from '../hooks/useWallet'
import { isWalletConnect } from '../services/wallet/helpers/isWalletConnect'
import { WalletType } from '../services/wallet/wallet.types'
import { selectContractWallet, selectWallet } from '../store/selectors/user.selectors'
import { setContractWallet } from '../store/slices/user.slice'
import { LogFeature, makeLog } from '../utils/makeLog'

const blockWalletsByName = ['Argent'].map((name) => name.toLowerCase())
const log = makeLog(LogFeature.AUTH)

export const ContractWalletBlocker = () => {
  const dispatch = useAppDispatch()
  const contractWallet = useAppSelector(selectContractWallet)
  const walletData = useAppSelector(selectWallet)

  const wallet = useWallet()

  useEffect(() => {
    const effect = async () => {
      // TODO NOTICKET-1 - Make sure if this is the correct way of getting signature address
      const signatureAddress = await wallet.ethersSigner?.getAddress()

      if (walletData && signatureAddress) {
        const { address, walletType } = walletData

        // eslint-disable-next-line functional/no-let -- Legacy
        let blockedWalletDetected = false
        if (signatureAddress && signatureAddress.toLowerCase() !== address.toLowerCase()) {
          log('Blocked wallet detected', signatureAddress, address)
          dispatch(setContractWallet({ isContractWallet: true, signingAddress: signatureAddress }))
          blockedWalletDetected = true
        } else {
          dispatch(setContractWallet({ isContractWallet: false }))
        }

        if (!blockedWalletDetected && walletType === WalletType.walletConnect) {
          const provider = wallet?.provider
          const walletName = isWalletConnect(provider, walletType) ? provider?.session?.peer?.metadata?.name : null
          log('Checking wallet name', walletName)

          if (walletName && blockWalletsByName.includes(walletName.toLowerCase())) {
            log('Blocking wallet name', walletName)
            dispatch(setContractWallet({ isContractWallet: true, signingAddress: '' }))
            blockedWalletDetected = true
          }
        }
      }
    }
    if (walletData) {
      log('Running contract wallet check', wallet, contractWallet.isContractWallet, walletData)
      effect().catch(console.error)
    }
  }, [dispatch, wallet, contractWallet.isContractWallet, walletData])

  return <></>
}

import { createSelector } from 'reselect'
import type { RootState } from '../configureStore'
import { shortenAddress } from '../../services/helperService/shortenAddress'

export const selectIsSecondaryConnected = (state: RootState) => !!state.secondaryWallet.address
export const selectSecondaryWalletAddress = (state: RootState) => state.secondaryWallet.address
export const selectShortenedSecondaryAddress = (state: RootState) =>
  shortenAddress(state.secondaryWallet.address, false)
export const selectSecondaryWalletNetworkId = (state: RootState) => state.secondaryWallet.networkId
export const selectSecondaryWalletStatus = (state: RootState) => state.secondaryWallet.status

export const selectSecondaryWalletChain = (state: RootState) => state.secondaryWallet.connectedChain
export const selectSecondaryWalletDomain = (state: RootState) => state.secondaryWallet.domain

export const selectSecondaryWalletAddressAndChain = createSelector(
  selectSecondaryWalletAddress,
  selectSecondaryWalletChain,
  (secondaryWalletAddress, connectedChain) => ({ secondaryWalletAddress, connectedChain }),
)

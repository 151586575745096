import { type MissionId } from '@rhinofi/rhino-nation-api-spec'
import { Text, TextSize } from '@rhinofi/dvf-shared-ui'
import { SkeletonCard } from '../../../../../components/common/Skeletons/Skeleton.styled'
import { FlexContainer } from '../../../../../components/common/Wrappers/Containers'
import type { MissionsWithUserData } from '../../../types/nation.types'
import { NationEmptyState } from '../NationEmptyState'
import { NationMissionRow } from './NationMissionRow/NationMissionRow'

type Props = {
  loading: boolean
  error: string
  data: MissionsWithUserData | null
  isHistory?: boolean
  rowTitleMaxWidth?: string
  openModalFunc: (missionId: MissionId) => void
  missionFixedId: string
}

export const NationMissionsList = ({
  loading,
  error,
  data,
  isHistory = false,
  openModalFunc,
  rowTitleMaxWidth,
  missionFixedId,
}: Props) => {
  if (loading) {
    return (
      <FlexContainer $direction="column" $gap="16px">
        <SkeletonCard $height="56px" />
        <SkeletonCard $height="56px" />
        <SkeletonCard $height="56px" />
        <SkeletonCard $height="56px" />
        <SkeletonCard $height="56px" />
      </FlexContainer>
    )
  }

  if (error) {
    return (
      <Text color="accentRed" size={TextSize.XS}>
        {error}
      </Text>
    )
  }

  if (!data) {
    return null
  }

  if (data.items.length === 0) {
    return <NationEmptyState />
  }

  return (
    <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
      {data.items.map((missionData) => (
        <NationMissionRow
          fixedId={missionFixedId}
          key={missionData.mission._id.id}
          data={missionData}
          isHistory={isHistory}
          openModalFunc={openModalFunc}
          titleMaxWidth={rowTitleMaxWidth}
        />
      ))}
    </FlexContainer>
  )
}

import { Button, ButtonVariant, Input, MobileInput, Spacing, Text } from '@rhinofi/dvf-shared-ui'
import type { FormikHelpers, FormikProps } from 'formik'
import { Form, Formik } from 'formik'
import { noop } from 'lodash-es'
import type React from 'react'
import { useCallback } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../hooks/useAppSelector'
import { translate } from '../../intl/i18n'
import { isAPIError } from '../../services/apiService/isAPIError'
import { useSetEmailOrPhoneMutation } from '../../services/userVerificationApi'
import { dynamicSchemaCreator } from '../../services/validation/dynamicSchemaCreator'
import { EmailValidationSchema, PhoneValidationSchema } from '../../services/validation/formValidationService'
import { selectAddress } from '../../store/selectors/user.selectors'
import { FlexContainer } from '../common/Wrappers/Containers'
import { ReCaptchaPolicy } from '../ReCaptcha/ReCaptchaPolicy'
import { useReCaptcha } from '../ReCaptcha/useReCaptcha'
import { VerificationType } from './UserVerificationModal'

export type UserCredentialFormValues = {
  credential: string
  internationalCode: {
    id: string
    name: string
  }
}

type Props = {
  verificationType: VerificationType
  nextStep: (arg: { credential: string }) => void
  noText?: boolean
  shortButton?: boolean
  maxInputWidth?: number
  buttonText?: string
  autoFocus?: boolean
  initialCredential?: string
  closeButton?: {
    onClick: () => void
    text: string
  }
  renderForm?: (formikProps: FormikProps<UserCredentialFormValues>) => React.ReactNode
  shouldSendVerificationCode?: boolean
}

export const UserCredentialForm = ({
  initialCredential = '',
  verificationType,
  nextStep,
  noText = false,
  shortButton = false,
  maxInputWidth,
  buttonText,
  autoFocus = true,
  closeButton = {
    onClick: noop,
    text: '',
  },
  renderForm,
  shouldSendVerificationCode = true,
}: Props) => {
  const { submitWithReCaptchaToken } = useReCaptcha()
  const [setEmailTrigger] = useSetEmailOrPhoneMutation()
  const address = useAppSelector(selectAddress)
  const formatError = useCallback(
    (error: unknown, setFieldError: (field: string, message: string | undefined) => void) => {
      if (isAPIError(error) && error.statusCode < 500) {
        if (verificationType === VerificationType.phone) {
          setFieldError('credential', translate('user_verification.phone_already_exists'))
        } else if (error.body.message.toLowerCase().includes('invalid email')) {
          setFieldError('credential', translate('errors.please_add_a_valid_email_address'))
        } else {
          setFieldError('credential', translate('user_verification.email_already_exists'))
        }
      } else {
        setFieldError('credential', translate('errors.unexpected_error'))
      }
    },
    [verificationType],
  )

  const submit = (
    values: UserCredentialFormValues,
    { setFieldError, setSubmitting }: FormikHelpers<UserCredentialFormValues>,
  ) => {
    submitWithReCaptchaToken(async (reCaptchaToken: string) => {
      try {
        // eslint-disable-next-line functional/no-let -- Legacy
        let credential = values.credential
        if (verificationType === VerificationType.phone) {
          credential = `+${values.internationalCode.id}${credential.replaceAll(/[-+\s]/g, '')}`
        }
        if (!initialCredential || initialCredential !== credential) {
          const result = await setEmailTrigger({
            type: verificationType,
            value: credential,
            reCaptchaToken,
            sendVerificationCode: shouldSendVerificationCode,
            user: address || '',
          })
          if (result.error && 'data' in result.error) {
            formatError(result.error.data, setFieldError)
            setSubmitting(false)
            return
          }
        }
        nextStep({ credential })
        setSubmitting(false)
      } catch (error) {
        formatError(error, setFieldError)
        setSubmitting(false)
      }
    })
  }

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        credential: initialCredential,
        internationalCode: { id: '44', name: 'UK (+44)' },
      }}
      validateOnChange={true}
      validateOnMount={true}
      validate={(values) =>
        dynamicSchemaCreator(
          values,
          verificationType === VerificationType.email ? EmailValidationSchema : PhoneValidationSchema,
        )
      }
      onSubmit={submit}
    >
      {renderForm ||
        (({ isValid }) => (
          <Form>
            <FlexContainer $alignItems="stretch" $direction="column">
              {!noText && (
                <>
                  <Spacing size="14" />
                  <Text transparency={false}>{translate(`user_verification.verify_enter_${verificationType}`)}</Text>
                </>
              )}
              <Spacing size="30" />
              <InputContainer $maxWidth={maxInputWidth}>
                {verificationType === VerificationType.email && (
                  <Input
                    id="credential"
                    name="credential"
                    type="text"
                    label={translate(`user_verification.input_label_${verificationType}`)}
                    autoFocus={autoFocus}
                  />
                )}
                {verificationType === VerificationType.phone && (
                  <MobileInput
                    inputName="credential"
                    selectName="internationalCode"
                    label={translate(`user_verification.input_label_${verificationType}`)}
                    autoFocus={autoFocus}
                  />
                )}
                <Spacing size="30" />
                <FlexContainer $gap="8px">
                  {closeButton.text ? (
                    <Button variant={ButtonVariant.secondary} id="close-form" onClick={closeButton.onClick}>
                      {closeButton.text}
                    </Button>
                  ) : null}
                  <Button
                    id="send-verification-code"
                    type="submit"
                    icon={buttonText ? '' : 'arrow-right'}
                    fullWidth={!shortButton}
                    disabled={!isValid}
                  >
                    {buttonText || translate(`user_verification.send_${verificationType}`)}
                  </Button>
                </FlexContainer>
              </InputContainer>
              <ReCaptchaPolicy />
            </FlexContainer>
          </Form>
        ))}
    </Formik>
  )
}

const InputContainer = styled.div<{ $maxWidth?: number | undefined }>`
  width: ${({ $maxWidth: maxWidth }) => maxWidth && `${maxWidth}px`};
`

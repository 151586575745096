import { createSelector } from 'reselect'
import type { RootState } from '../configureStore'

export const selectSelectedTheme = (state: RootState) => state.portal.selectedTheme
export const selectRequiredChain = (state: RootState) => state.portal.requiredChain
export const selectHasBalanceForTransaction = (state: RootState) => state.portal.hasBalanceForTransaction
export const selectBlockchainBalances = (state: RootState) => state.portal.blockchainBalance
export const selectFailedChainsObject = (state: RootState) => state.portal.failedChains
export const selectFailedChains = createSelector(selectFailedChainsObject, (failedChains) =>
  Object.keys(failedChains).filter((chain) => failedChains[chain]),
)
export const selectDefaultRoute = (state: RootState) => state.portal.defaultRoute

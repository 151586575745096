import type { ReactChild } from 'react'
import React, { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { noop } from 'lodash-es'
import { CoreCard, SecondaryCard, ModalCard, Button, ButtonVariant, ButtonSizes } from '@rhinofi/dvf-shared-ui'
import { useSpring } from '@react-spring/web'
import { clampedGentleFigma } from '../../../constants/springs'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { InformationCard } from '../Cards/InformationCard'
import { SuccessCard } from '../Cards/SuccessCard'
import {
  ModalWrapper,
  ModalOverlay,
  NeonWrapper,
  ModalTitle,
  CloseWrapper,
  ModalSimpleBar,
  MobileFullScreenCard,
  BackWrapper,
  ModalContentWrapper,
} from './Modal.styled'

type ModalProps = {
  id?: string | undefined
  isVisible: boolean
  onClose: React.MouseEventHandler<HTMLElement>
  title?: ReactChild | undefined
  width?: string | undefined
  maxWidth?: string | undefined
  maxHeight?: number | boolean | undefined
  closeOnOverlayClick?: boolean | undefined
  noFade?: boolean | undefined
  background?: string | undefined
  backgroundTransitionSpeed?: string | undefined
  renderCustomOverlay?: (({ onClick }: { onClick: () => void }) => React.ReactNode) | undefined
  variant?: 'secondary' | 'modal' | 'core' | 'info' | 'success' | '' | undefined
  noClose?: boolean | undefined
  mobileFullScreen?: boolean | undefined
  renderAboveModal?: ({ width }: { width?: string }) => React.ReactNode
  cardContainerClassName?: string | undefined
  closeButtonVariant?: ButtonVariant | undefined
  closeButtonSize?: ButtonSizes | undefined
  hideScrollbar?: boolean | undefined
  increasedPadding?: boolean | undefined
}
const noScrollClass = 'no-scroll'
export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  id = '',
  isVisible,
  children,
  onClose,
  title = '',
  width = '',
  maxWidth = '',
  maxHeight = false,
  closeOnOverlayClick = true,
  noFade = false,
  background = '',
  backgroundTransitionSpeed,
  variant = 'core',
  noClose = false,
  renderCustomOverlay,
  mobileFullScreen = false,
  renderAboveModal,
  cardContainerClassName = '',
  closeButtonVariant = ButtonVariant.close,
  closeButtonSize = ButtonSizes.Small,
  hideScrollbar = false,
  increasedPadding = false,
}) => {
  const { isMobile } = useIsMobile()

  // on mount, if visible, add no-scroll class
  useLayoutEffect(() => {
    if (isVisible) {
      document.body.classList.add(noScrollClass)
      return () => {
        document.body.classList.remove(noScrollClass)
      }
    }

    return undefined
  }, [isVisible])

  const Card = (() => {
    if (isMobile && mobileFullScreen) {
      return MobileFullScreenCard
    }
    switch (variant) {
      case 'info':
        return InformationCard
      case 'success':
        return SuccessCard
      case 'modal':
        return ModalCard
      case 'secondary':
        return SecondaryCard
      case 'core':
        return CoreCard
      default:
        return CoreCard
    }
  })()

  const content = (
    <>
      {title ? <ModalTitle>{title}</ModalTitle> : null}
      {noClose ? null : isMobile && mobileFullScreen ? (
        <BackWrapper>
          <Button variant={closeButtonVariant} id="back" onClick={onClose} icon="arrow-left-square" />
        </BackWrapper>
      ) : (
        <CloseWrapper $hasTitle={!!title}>
          <Button variant={closeButtonVariant} size={closeButtonSize} id="close-modal" onClick={onClose} icon="x-lg" />
        </CloseWrapper>
      )}
      <div>{children}</div>
    </>
  )

  const { y } = useSpring({
    config: clampedGentleFigma,
    reset: !isVisible,
    pause: !isVisible,
    from: {
      y: 'calc(50% + 50vh)',
    },
    to: {
      y: 'calc(0% + 0vh)',
    },
  })

  const onOverlayClick = closeOnOverlayClick ? onClose : noop
  const animationRef = React.useRef<HTMLDivElement>(null)
  return (
    <CSSTransition
      in={isVisible}
      timeout={100}
      appear={isVisible}
      classNames="modal-transition"
      unmountOnExit
      nodeRef={animationRef}
    >
      <>
        {createPortal(
          <ModalWrapper
            ref={animationRef}
            $hasCustomOverlay={!!renderCustomOverlay}
            $noFade={noFade}
            id={id}
            $mobileFullScreen={mobileFullScreen}
          >
            {renderCustomOverlay ? (
              renderCustomOverlay({
                onClick: onOverlayClick,
              })
            ) : (
              <ModalOverlay onClick={onOverlayClick} />
            )}
            <NeonWrapper
              style={{ y }}
              $width={width}
              $maxWidth={maxWidth}
              $background={background}
              $maxHeight={Boolean(maxHeight)}
              $mobileFullScreen={mobileFullScreen}
              $hideScrollbar={hideScrollbar}
              {...(backgroundTransitionSpeed ? { $backgroundTransitionSpeed: backgroundTransitionSpeed } : {})}
            >
              {renderAboveModal?.({ width })}
              <Card
                className={
                  cardContainerClassName ? `modal-card-wrapper ${cardContainerClassName}` : 'modal-card-wrapper'
                }
                widget={increasedPadding}
              >
                {maxHeight ? (
                  <ModalSimpleBar autoHide $maxHeight={maxHeight}>
                    <div style={{ overflowX: 'hidden' }}>{content}</div>
                  </ModalSimpleBar>
                ) : (
                  <ModalContentWrapper>{content}</ModalContentWrapper>
                )}
              </Card>
            </NeonWrapper>
          </ModalWrapper>,
          document.body,
        )}
      </>
    </CSSTransition>
  )
}

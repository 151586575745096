import { translate } from '../../../intl/i18n'
import type { NFTType } from '../types/mintNFT.types'
import { isActivityTrackerEnabledForChain } from './isActivityTrackerEnabledForChain'

export const getNFTMintModalSuccessText = (type: NFTType, chain: string, chainName: string) => {
  if (type === 'wrapped2023') {
    return translate('tracker.nft_minted_desc_wrapped')
  }

  if (type === 'BETAKEY') {
    return translate('gated.success_mint_desc')
  }

  const activityTrackerExistsForChain = isActivityTrackerEnabledForChain(chain)

  return translate(activityTrackerExistsForChain ? 'tracker.nft_minted_desc' : 'tracker.nft_minted_desc_no_trackers', {
    '%chain': chainName,
  })
}

import styled from 'styled-components'
import { respondBelow } from '@rhinofi/dvf-shared-ui/lib/utils/respondTo'
import nationEmptyStateIllustration from '../../assets/empty_state_illustration.png'

export const NationEmptyState = () => (
  <Image alt="meditating futuristic ninja" src={nationEmptyStateIllustration}></Image>
)

const Image = styled.img`
  max-width: 440px;

  @media ${respondBelow('xxxs')} {
    max-width: 100%;
  }
`

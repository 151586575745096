import { Either, Schema } from 'effect'

const ForcedChainsSchema = Schema.compose(Schema.split(','), Schema.Array(Schema.Lowercase))

const values = localStorage.getItem('FORCED_CHAINS') || ''
const parsed = Schema.decodeUnknownEither(ForcedChainsSchema)(values)

if (Either.isLeft(parsed)) {
  console.warn('Error parsing FORCED_CHAINS', parsed.left)
}

export const isChainForceEnabled = (chain: string) => {
  if (Either.isRight(parsed)) {
    return parsed.right.includes(chain.toLowerCase())
  }
  return false
}

import * as Schema from 'effect/Schema'
import type React from 'react'
import type { PropsWithChildren } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { WalletContext } from '../contexts/WalletContext'
import { useAppDispatch, useAppSelector } from '../hooks'
import { trackUserLoginEvent } from '../services/tracking/tracking'
import { useEIP6963Providers } from '../services/wallet/eip6963/useEIP6963Providers'
import { KitType } from '../services/wallet/EvmConnectionKit'
import { getLastLogin } from '../services/wallet/helpers/getLastLogin'
import { saveLastLogin } from '../services/wallet/helpers/saveLastLogin'
import { useConnectionKits } from '../services/wallet/hooks/useConnectionKits'
import { LastWalletSchema } from '../services/wallet/schema'
import type { SupportedWallet } from '../services/wallet/wallet.types'
import { WalletType, type WalletTypeProps } from '../services/wallet/wallet.types'
import { selectWallet } from '../store/selectors/user.selectors'
import { setLastLoginRead, setNetwork, setWallet } from '../store/slices/user.slice'
import { LogFeature, makeLog } from '../utils/makeLog'

const log = makeLog(LogFeature.WALLET_CONTEXT)

export const WalletProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const wallet = useAppSelector(selectWallet)
  const dispatch = useAppDispatch()

  const [connectingWallet, setIsConnectingWallet] = useState<{
    isConnecting: boolean
    name?: string | undefined
    walletType?: SupportedWallet
  }>({ isConnecting: false })
  const [connectionError, setConnectionError] = useState<string>('')

  const eip6963Providers = useEIP6963Providers()
  const { connectionKit, getConnectionKit } = useConnectionKits({ eip6963Providers })

  useEffect(() => {
    switch (wallet?.walletType) {
      case WalletType.walletConnect: {
        saveLastLogin({ type: wallet.walletType, address: wallet.address })
        break
      }
      case WalletType.eip6963: {
        saveLastLogin({ type: wallet.walletType, address: wallet.address, name: wallet.name })
        break
      }
    }
  }, [wallet])

  const disconnect = useCallback(async () => {
    if (!connectionKit) {
      log('No connection kit available for disconnect')
      return
    }
    const { disconnected } = await connectionKit.disconnect()
    if (disconnected) {
      log('Disconnected')
      dispatch(setWallet(null))
    } else {
      log('Disconnect failed')
    }
  }, [connectionKit, dispatch])

  const updateWallet = useCallback(
    (address: string, type: string, walletType: SupportedWallet, name: string) => {
      switch (type) {
        case KitType.eip1193: {
          log('Connected to EIP6963 provider', name)
          dispatch(
            setWallet({
              walletType,
              address,
              name,
            }),
          )
          break
        }
      }
    },
    [dispatch],
  )

  const connect = useCallback(
    async (connectProps: WalletTypeProps, meta?: { isAutoConnect?: boolean }) => {
      try {
        setConnectionError('')
        setIsConnectingWallet({
          isConnecting: true,
          walletType: connectProps.walletType,
          name: 'name' in connectProps ? connectProps.name : connectProps.walletType,
        })
        const kitToUse = await getConnectionKit(connectProps)
        if (!kitToUse) {
          log('Tried to connect with no kit')
          return
        }

        const result = await kitToUse.connect({
          onAccountChange: (address) => {
            updateWallet(
              address,
              kitToUse.type,
              connectProps.walletType,
              'name' in connectProps ? connectProps.name : connectProps.walletType,
            )
          },
          onNetworkChange: (networkId) => {
            dispatch(setNetwork({ network: parseInt(networkId) }))
          },
        })
        if (result?.address) {
          updateWallet(
            result.address,
            kitToUse.type,
            connectProps.walletType,
            'name' in connectProps ? connectProps.name : connectProps.walletType,
          )
          trackUserLoginEvent({
            address: result.address,
            walletType: connectProps.walletType,
            name: 'name' in connectProps ? connectProps.name : connectProps.walletType,
            connectionKit: kitToUse,
            isAutoConnected: meta?.isAutoConnect,
          })
        } else {
          dispatch(setWallet(null))
        }
        setIsConnectingWallet({ isConnecting: false })
      } catch (error) {
        log('Error connecting', error)
        if (error instanceof Error) {
          setConnectionError('message' in error ? error.message.toString() : '')
        }
        setIsConnectingWallet({ isConnecting: false })
      }
    },
    [dispatch, getConnectionKit, updateWallet],
  )

  const walletContextProviderValue = useMemo(() => {
    return {
      getProvider: () => connectionKit?.getProvider(),
      provider: connectionKit?.getProvider(),
      connectionKit,
      getEthersSigner: () => connectionKit?.getSigner(),
      ethersSigner: connectionKit?.getSigner(),
      eip6963Providers,
      connect,
      disconnect,
      connectingWallet,
      connectionError,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run only on address change as well
  }, [connectionKit, eip6963Providers, connect, disconnect, connectingWallet, connectionError, wallet?.address])

  useEffect(() => {
    const effect = async () => {
      const lastWallet = getLastLogin()
      try {
        const parsed = Schema.decodeUnknownSync(LastWalletSchema)(lastWallet)
        switch (parsed.type) {
          case WalletType.injected: {
            log('Attempting to reconnect to injected wallet')
            await connect({ walletType: WalletType.injected }, { isAutoConnect: true })
            break
          }
          case WalletType.walletConnect: {
            log('Attempting to reconnect to walletConnect')
            await connect({ walletType: WalletType.walletConnect }, { isAutoConnect: true })
            break
          }
          case WalletType.eip6963: {
            // check if the provider is still available
            const provider = eip6963Providers.find((candidate) => candidate.info.name === parsed.name)

            if (!provider) {
              log('Attempting to reconnect to eip6863 - no provider')
              return
            }
            log('Attempting to reconnect to eip6863 - provider found')
            await connect({ walletType: WalletType.eip6963, name: parsed.name }, { isAutoConnect: true })
            break
          }
        }
      } catch {
        //
      } finally {
        dispatch(setLastLoginRead(true))
      }
    }

    void effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run only once or if providers get loaded
  }, [eip6963Providers])

  return <WalletContext.Provider value={walletContextProviderValue}>{children}</WalletContext.Provider>
}

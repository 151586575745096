import type { PublicQuoteResponseSchema } from '@rhinofi/bridge-api-spec'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import { BigDecimal } from 'effect'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { NO_APPROVAL_CHAINS } from '../../../constants/types'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useWallet } from '../../../hooks/useWallet'
import { useGetReadSigner } from '../../../services/ethereum/useGetReadSigner'
import { requireChain } from '../../../store/actions/user.actions'
import {
  selectBridgeChainsConfig,
  selectBridgeChainsTokenConfig,
} from '../../../store/selectors/bridgeConfig.selectors'
import { selectSecondaryWalletAddress } from '../../../store/selectors/secondaryWallet.selectors'
import { selectAddress } from '../../../store/selectors/user.selectors'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { callApprovalContract } from '../services/callApprovalContract'
import { callCheckAllowance } from '../services/callCheckAllowance'
import { selectBridge } from '../slices/bridge.slice'

export const useBridgeAllowance = ({
  payAmount,
}: {
  payAmount: PublicQuoteResponseSchema['payAmount'] | undefined
}) => {
  const dispatch = useAppDispatch()
  const getReadSigner = useGetReadSigner()

  const bridgeState = useAppSelector(selectBridge)
  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)
  const { chainInTokenConfig } = useAppSelector(selectBridgeChainsTokenConfig)
  const walletAddress = useAppSelector(selectAddress)
  const secondaryWalletAddress = useAppSelector(selectSecondaryWalletAddress)

  const [allowance, setAllowance] = useState<number>(0)
  const [isFetchingAllowance, setIsFetchingAllowance] = useState<boolean>(false)
  const [isApproving, setIsApproving] = useState<boolean>(false)

  const { ethersSigner } = useWallet()

  const checkAllowance = useCallback(async () => {
    if (
      NO_APPROVAL_CHAINS.includes(bridgeState.chainIn) ||
      !chainInTokenConfig ||
      !chainInConfig ||
      !walletAddress ||
      !ethersSigner
    ) {
      setAllowance(0)
      return
    }
    const isNativeToken = bridgeState.token === chainInConfig.nativeTokenName
    if (isNativeToken) {
      setAllowance(0)
      return
    }
    setIsFetchingAllowance(true)
    try {
      const tokenAddress = chainInTokenConfig.address
      const bridgeContractAddress = chainInConfig.contractAddress
      const contractAllowance = await callCheckAllowance({
        chain: bridgeState.chainIn,
        tokenAddress,
        ethersSigner: getReadSigner(bridgeState.chainIn),
        walletAddress,
        bridgeContractAddress,
        decimals: chainInTokenConfig.decimals,
        secondaryWalletAddress,
      })
      setAllowance(parseFloat(contractAllowance))
      setIsFetchingAllowance(false)
    } catch (error) {
      console.error(error)
      setIsFetchingAllowance(false)
    }
  }, [
    bridgeState.chainIn,
    bridgeState.token,
    chainInTokenConfig,
    chainInConfig,
    walletAddress,
    ethersSigner,
    getReadSigner,
    secondaryWalletAddress,
  ])

  useEffect(() => {
    void checkAllowance()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on select param change
  }, [walletAddress, chainInConfig, bridgeState.token, ethersSigner, secondaryWalletAddress])

  const approve = useCallback(async () => {
    try {
      if (!chainInConfig || !chainInTokenConfig || !payAmount) {
        throw new Error('No config found for chain')
      }
      setIsApproving(true)
      const switchChainResult = await requireChain(dispatch)(bridgeState.chainIn)
      if (switchChainResult.done === false) {
        setIsApproving(false)
        return
      }
      const tokenAddress = chainInTokenConfig.address
      const decimals = chainInTokenConfig.decimals
      const bridgeContractAddress = chainInConfig.contractAddress
      const amount = bigDecimalToNumericString(payAmount)
      const amountWithDecimals = SafeDecimal(amount)
        .times(10 ** decimals)
        .toFixed()
      await callApprovalContract({
        chain: bridgeState.chainIn,
        bridgeContractAddress,
        tokenAddress,
        amount,
        amountWithDecimals,
        ethersSigner,
      })
      setAllowance(parseFloat(amount))
      setIsApproving(false)
    } catch (error) {
      console.error(error)
      setIsApproving(false)
    }
  }, [payAmount, bridgeState.chainIn, chainInConfig, chainInTokenConfig, dispatch, ethersSigner])

  const shouldApprove = useMemo(() => {
    if (NO_APPROVAL_CHAINS.includes(bridgeState.chainIn)) {
      return false
    }
    if (chainInConfig) {
      const isNativeToken = bridgeState.token === chainInConfig.nativeTokenName
      if (isNativeToken) {
        return false
      }
    }
    const _payAmount = payAmount ? BigDecimal.format(payAmount) : '0'
    return !!payAmount && allowance < parseFloat(_payAmount)
  }, [bridgeState.chainIn, bridgeState.token, chainInConfig, payAmount, allowance])

  return {
    approve,
    shouldApprove,
    checkAllowance,
    isApproving,
    isFetchingAllowance,
  }
}

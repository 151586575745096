import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { updateSecondaryAddress } from '../../../../store/slices/secondaryWallet.slice'
import { Schema } from 'effect'

// eslint-disable-next-line functional/no-let -- singleton pattern
export let tronListenerFunction: (event: MessageEvent) => void | undefined

const TronEventSchema = Schema.Struct({
  message: Schema.Struct({
    action: Schema.String,
    data: Schema.Struct({
      address: Schema.String,
    }),
  }),
})

export const createTronListener = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  tronListenerFunction = (event: MessageEvent) => {
    try {
      const data = Schema.decodeUnknownSync(TronEventSchema)(event.data)
      if (data.message && data.message.action === 'accountsChanged') {
        const changedAddress = data.message.data.address
        dispatch(updateSecondaryAddress(changedAddress))
      }
      // eslint-disable-next-line no-unused-vars -- catch
    } catch (error) {
      // no error handling needed
    }
  }
  window.addEventListener('message', tronListenerFunction)
}

import { Button, ButtonVariant, Icon, IconSizes, Input, Spacing, Text, Title } from '@rhinofi/dvf-shared-ui'
import { TitleSize, TypographyAlign } from '@rhinofi/dvf-shared-ui/lib/types/formats'
import type { FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { clearModal } from '../../store/actions/modalActions/clearModal'
import { useABTest } from '../../hooks/useABTest'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { translate } from '../../intl/i18n'
import { chainRoutes, publicRoutes } from '../../router'
import { ABTestExperimentId } from '../../services/ABTestService'
import { subscribeSendInBlueEmail } from '../../services/sendInBlue/subscribeSendInBlueEmail'
import { dynamicSchemaCreator } from '../../services/validation/dynamicSchemaCreator'
import { EmailValidationSchema } from '../../services/validation/formValidationService'
import { ProgressCircle } from '../ProgressCircle/ProgressCircle'
import { Modal } from '../common/Modal/Modal'
import { FlexContainer } from '../common/Wrappers/Containers'
import mailIcon from '../common/assets/mail.svg'

type FormValues = {
  credential: string
}

const FORM_ID = 16

export const NewUserEmailSubscribe = () => {
  const showModal = useABTest(ABTestExperimentId.ONBOARDING_EMAIL) === '0'
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [step, setStep] = useState(0)

  const showSuccessDepositVariant = useMemo(
    () =>
      !pathname.includes(publicRoutes.bridge) &&
      !pathname.includes(publicRoutes.deployContractsOld) &&
      !pathname.includes(publicRoutes.nation) &&
      !pathname.includes(chainRoutes.deploy),
    [pathname],
  )

  useEffect(() => {
    if (!showModal) {
      clearModal(dispatch)()
    }
  }, [showModal, dispatch])

  const submit = async (values: FormValues, { setFieldError }: FormikHelpers<FormValues>) => {
    try {
      await subscribeSendInBlueEmail(FORM_ID, values.credential)
      setStep(1)
    } catch (error: unknown) {
      if (error !== null && typeof error === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string -- Legacy
        setFieldError('credential', error?.toString())
      }
    }
  }

  const finish = () => {
    clearModal(dispatch)()
    navigate(publicRoutes.bridge)
  }

  return (
    <Modal isVisible={true} width="446px" onClose={() => clearModal(dispatch)()}>
      {step === 0 && (
        <>
          <Title size={TitleSize.XXS}>{translate('yield.email_alerts')}</Title>
          <Spacing size="8" />
          <Text>{translate('onboarding.receive_email_alerts')}</Text>
          <Spacing size="34" />
          <Text align={TypographyAlign.Center}>
            <img src={mailIcon} alt="email" />
          </Text>
          <Spacing size="34" />
          <Formik
            initialValues={{
              credential: '',
            }}
            validateOnChange={true}
            validateOnMount={true}
            validate={(values) => dynamicSchemaCreator(values, EmailValidationSchema)}
            onSubmit={submit}
          >
            <Form>
              <Input
                id="credential"
                name="credential"
                type="text"
                label={translate('helpers.email')}
                autoFocus={true}
              />
              <Spacing size="8" />
              <FlexContainer $gap="16px" $justifyContent="flex-end">
                <Button
                  type="button"
                  id="skip-email-pre-deposit"
                  variant={ButtonVariant.text}
                  onClick={() => setStep(1)}
                >
                  {translate('helpers.skip').toLowerCase()}
                </Button>
                <Button type="submit" id="next">
                  {translate('tutorial.nextbtn')}
                </Button>
              </FlexContainer>
            </Form>
          </Formik>
        </>
      )}
      {step === 1 && !showSuccessDepositVariant && (
        <FlexContainer $fullWidth $direction="column" $gap="24px">
          <Title size={TitleSize.XXS} align={TypographyAlign.Center}>
            {translate('onboarding.wallet_connected')}
          </Title>
          <ProgressCircle
            fillPercent={100}
            isDone
            isError={false}
            showRhinoLogo={false}
            customFillColor={theme.successMain}
          />
          <Text align={TypographyAlign.Center}>{translate('global.ready_to_bridge')}</Text>
          <Button onClick={() => clearModal(dispatch)()} id="done-bridge" fullWidth>
            {translate('global.done')}
          </Button>
        </FlexContainer>
      )}
      {step === 1 && showSuccessDepositVariant && (
        <>
          <Title size={TitleSize.XXS} align={TypographyAlign.Left}>
            {translate('onboarding.account_set_up')}
          </Title>
          <Spacing size="8" />
          <Text align={TypographyAlign.Left}>{translate('onboarding.account_set_up_desc')}</Text>
          <Spacing size="34" />
          <Text align={TypographyAlign.Center}>
            <Icon id="rocket" size={IconSizes.ExtraLarge} />
          </Text>
          <Spacing size="34" />
          <Button onClick={finish} id="make-your-first-deposit" fullWidth>
            {translate('onboarding.make_first_deposit')}
          </Button>
        </>
      )}
    </Modal>
  )
}

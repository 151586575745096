import type {
  BridgeConfigEntrySchema,
  BridgeTokenEntry,
  PublicQuoteResponseSchema,
  QuoteFeeSchema,
} from '@rhinofi/bridge-api-spec'
import { bigDecimalToNumericString } from '@rhinofi/effect-utils'
import type { FormikProps } from 'formik'
import { isEmpty } from 'lodash-es'
import type { RefObject } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { NETWORKS } from '../../../constants/types'
import { useAppSelector } from '../../../hooks'
import { getTokenPrice } from '../../../services/helperService/getTokenPrice'
import { selectTokenPrices } from '../../../services/usdPricesApi'
import { dynamicAsyncSchemaCreator } from '../../../services/validation/dynamicAsyncSchemaCreator'
import {
  selectBridgeChainsConfig,
  selectBridgeChainsTokenConfig,
} from '../../../store/selectors/bridgeConfig.selectors'
import { selectBlockchainBalances } from '../../../store/selectors/portal.selectors'
import { selectIsAuthenticated } from '../../../store/selectors/user.selectors'
import { LogFeature, makeLog } from '../../../utils/makeLog'
import { SafeDecimal } from '../../../utils/SafeDecimal'
import { BridgeValidationSchema } from '../schemas/bridge-widget.schemas'
import { selectBridge } from '../slices/bridge.slice'
import type { BridgeFormValues } from '../types/bridge-widget.types'

const log = makeLog(LogFeature.BRIDGE_VALIDATION)

type UseBridgeValidationInput = {
  // Deposit
  depositLimit: BridgeTokenEntry['maxDepositLimit']
  maxBridgeableAmount: string
  gasSafeGuard: BridgeConfigEntrySchema['nativeTokenSafeguard'] | undefined
  // Withdrawal
  withdrawalQuote: QuoteFeeSchema | undefined
  withdrawalLimit: BridgeTokenEntry['maxWithdrawLimit']
  nativeTokenCost: string | undefined
  payAmount: PublicQuoteResponseSchema['payAmount'] | undefined
  bridgeFormRef: RefObject<FormikProps<BridgeFormValues>>
}

export const useBridgeValidation = ({
  depositLimit,
  maxBridgeableAmount,
  gasSafeGuard,
  withdrawalQuote,
  withdrawalLimit,
  nativeTokenCost,
  payAmount,
  bridgeFormRef,
}: UseBridgeValidationInput) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const tokenPrices = useAppSelector(selectTokenPrices)
  const blockchainBalance = useAppSelector(selectBlockchainBalances)
  const { chainIn, amount, token } = useAppSelector(selectBridge)
  const { chainInConfig } = useAppSelector(selectBridgeChainsConfig)
  const { chainInTokenConfig } = useAppSelector(selectBridgeChainsTokenConfig)

  const needsMoreNativeForTx = useMemo(() => {
    if (chainIn === NETWORKS.PARADEX) {
      return false
    }
    const nativeTokenBalance = chainInConfig?.nativeTokenName
      ? blockchainBalance?.[chainIn]?.[chainInConfig?.nativeTokenName]?.balance
      : '0'

    return nativeTokenBalance === '0' || SafeDecimal(nativeTokenBalance).lt(gasSafeGuard ?? 0)
  }, [blockchainBalance, chainIn, chainInConfig?.nativeTokenName, gasSafeGuard])

  const bridgeValidation = useCallback(
    async (values: BridgeFormValues) => {
      if (amount === '0' || amount === '') {
        log('early validation exit')
        return {}
      }

      const bridgeValidationResult = await dynamicAsyncSchemaCreator(values, BridgeValidationSchema, {
        // Base amount validator args
        usdPrice: getTokenPrice(tokenPrices, token),
        balance: isAuthenticated ? parseFloat(blockchainBalance?.[chainIn]?.[token]?.balance || '0') : Number.MAX_VALUE,
        payAmount: payAmount ? bigDecimalToNumericString(payAmount) : `0`,
        // Bridge amount validator args
        // General
        decimals: chainInTokenConfig?.decimals,
        chain: chainIn,
        token,
        nativeToken: chainInConfig?.nativeTokenName,
        isOtherAddress: values.isOtherAddress,
        // Deposit
        depositLimit,
        needsMoreNativeForTx: isAuthenticated ? needsMoreNativeForTx : false,
        safeMax: isAuthenticated ? maxBridgeableAmount : Number.MAX_VALUE,
        safeguardAmount: gasSafeGuard ? +gasSafeGuard : 0,
        // Withdrawal
        feeAmount: withdrawalQuote ? bigDecimalToNumericString(withdrawalQuote.fee) : `0`,
        withdrawalLimit,
        nativeTokenCost: nativeTokenCost ? nativeTokenCost : `0`,
      })

      log('bridgeValidationResult', bridgeValidationResult)
      return bridgeValidationResult
    },
    [
      amount,
      tokenPrices,
      token,
      isAuthenticated,
      blockchainBalance,
      chainIn,
      chainInConfig?.nativeTokenName,
      chainInTokenConfig?.decimals,
      depositLimit,
      needsMoreNativeForTx,
      maxBridgeableAmount,
      gasSafeGuard,
      withdrawalQuote,
      withdrawalLimit,
      nativeTokenCost,
      payAmount,
    ],
  )

  // validate on non-form validation parameters change
  useEffect(() => {
    const validate = async () => {
      if (bridgeFormRef.current) {
        const validationResult = await bridgeValidation(bridgeFormRef.current.values)
        if (!isEmpty(validationResult) || !isEmpty(bridgeFormRef.current.errors)) {
          bridgeFormRef.current.setErrors(validationResult)
          void bridgeFormRef.current.validateForm()
        }
      }
    }
    void validate()
  }, [bridgeValidation, bridgeFormRef])

  return {
    bridgeValidation,
  }
}

import { useCallback } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonVariant, Icon, Text } from '@rhinofi/dvf-shared-ui'
import { translate } from '../../../../intl/i18n'
import { FlexContainer } from '../../../../components/common/Wrappers/Containers'
import { useAppDispatch } from '../../../../hooks'
import { clearModal } from '../../../../store/actions/modalActions/clearModal'
import { makeRouteTo } from '../../../../router'
import type { GmSuccessType } from '../../types/contractsInteractions.types'
import { ContractsInteractionsDeployButton } from '../ContractsInteractionsDeployButton'

type Props = {
  variant: GmSuccessType
}

export const ContractGMModalSuccess = ({ variant = 'regular' }: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isRegularVariant = variant === 'regular'

  const seeMoreContractsCallback = useCallback(() => {
    navigate(makeRouteTo.chainDeployContracts({ subRoute: 'interactions' }))
    clearModal(dispatch)()
  }, [dispatch, navigate])

  const onDeployLinkClickCallback = useCallback(() => {
    clearModal(dispatch)()
  }, [dispatch])

  return (
    <FlexContainer $gap="16px" $direction="column" $alignItems="stretch">
      <Text>{translate('contracts_interactions.your_successfully_sent_a_gm')}</Text>

      <FlexContainer $direction="column" $alignItems="stretch">
        <Text bold>{translate('contracts_interactions.whats_next')}</Text>
        <Text>
          {isRegularVariant
            ? translate('contracts_interactions.gm_success_desc')
            : translate('contracts_interactions.gm_success_desc_friend')}
        </Text>
      </FlexContainer>

      {isRegularVariant ? (
        <CustomButton
          id="contracts-interactions-gm-modal-success-see-more"
          onClick={seeMoreContractsCallback}
          icon={<CustomIcon id="box-arrow-up-right" />}
        >
          {translate('contracts_interactions.see_more_contracts')}
        </CustomButton>
      ) : (
        <>
          <ContractsInteractionsDeployButton
            typeVariant="link"
            numDeployedContracts={0}
            onLinkClick={onDeployLinkClickCallback}
          />

          <CustomButton
            id="contracts-interactions-gm-modal-success-see-more-friend"
            onClick={seeMoreContractsCallback}
            icon={<CustomIcon id="box-arrow-up-right" />}
            variant={ButtonVariant.secondary}
          >
            {translate('contracts_interactions.see_more')}
          </CustomButton>
        </>
      )}
    </FlexContainer>
  )
}

const CustomButton = styled(Button)`
  gap: 12px;
`

const CustomIcon = styled(Icon)`
  font-size: 15px;
`

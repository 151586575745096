import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, prepareAutoBatched } from '@reduxjs/toolkit'
import type { PortalReducerState, RequiredChain, ThemeName } from './types/PortalReducerState'
import { getDefaultBridgeRoute } from '../../pages/Bridge/helpers/getDefaultBridgeRoute'
import type { MulticallBalances } from '../../services/ethereum/multicall'

const defaultTheme: ThemeName = 'dune'

export const getInitialState = (): PortalReducerState => ({
  // // User data
  // permissions: null,
  // signatureAddress: null,

  // // Meta data
  selectedTheme: defaultTheme,
  requiredChain: null,
  // providerIsLate: 0,

  // // API / Blockchain Data
  // blockNumberPerChain: {},
  blockchainBalance: {},
  failedChains: {},
  hasBalanceForTransaction: {},
  defaultRoute: getDefaultBridgeRoute(),
})

export const portalSlice = createSlice({
  name: 'portal',
  initialState: getInitialState(),
  // extraReducers: (builder) => {
  //   const cleanState = {
  //     blockchainBalance: {},
  //     exchangeBalance: {},
  //     withdrawals: [],
  //     deposits: [],
  //     pendingDepositData: [],
  //     transfers: {},
  //     crossChainSwaps: {},
  //     crossChainBalanceQuantized: [],
  //     latestTx: '',
  //     exchangeBalanceFetched: false,
  //   }
  //   const reset = (state: PortalReducerState) => {
  //     return {
  //       ...state,
  //       ...cleanState,
  //     }
  //   }
  //   builder.addMatcher(isWalletConnectedAction, reset)
  //   builder.addMatcher(isAddressChangeAction, reset)
  //   builder.addMatcher(isWalletDisconnectAction, reset)
  // },
  reducers: {
    updateBlockchainBalance: {
      reducer: (
        state,
        {
          payload: { blockchainBalance, failedChains, hasBalanceForTransaction },
        }: PayloadAction<{
          blockchainBalance: MulticallBalances
          failedChains: Record<string, boolean>
          hasBalanceForTransaction: Record<string, boolean>
        }>,
      ) => {
        return {
          ...state,
          blockchainBalance: {
            ...state.blockchainBalance,
            ...blockchainBalance,
          },
          hasBalanceForTransaction: {
            ...state.hasBalanceForTransaction,
            ...hasBalanceForTransaction,
          },
          failedChains: {
            ...state.failedChains,
            ...failedChains,
          },
        }
      },
      prepare: prepareAutoBatched<any>(),
    },
    // setUserSignatureAddress: (
    //   state,
    //   { payload: { signatureAddress } }: PayloadAction<{ signatureAddress: string }>,
    // ) => {
    //   return {
    //     ...state,
    //     signatureAddress,
    //   }
    // },
    // setPortalReducerProviderIsLate: (
    //   state,
    //   { payload: { providerIsLate } }: PayloadAction<{ providerIsLate: number }>,
    // ) => {
    //   return {
    //     ...state,
    //     providerIsLate,
    //   }
    // },
    toggleTheme: (state, { payload: { theme } }: PayloadAction<{ theme: ThemeName }>) => {
      localStorage.setItem('theme', theme)

      return {
        ...state,
        selectedTheme: theme,
      }
    },
    setNextBatchTime: (
      state,
      action: PayloadAction<{
        batchTime: {
          estimatedTime: string | number | null
          averageTime: string | number | null
          finalisedBatchPendingConfirmation?: boolean | undefined
          origAverageTime?: number | null | undefined
        }
      }>,
    ) => {
      return {
        ...state,
        batchTime: action.payload.batchTime,
      }
    },
    setRequiredChain: (
      state,
      {
        payload: { requiredChain },
      }: PayloadAction<{
        requiredChain: RequiredChain
      }>,
    ) => {
      return {
        ...state,
        requiredChain,
      }
    },
  },
})

export const { toggleTheme, setNextBatchTime, setRequiredChain, updateBlockchainBalance } = portalSlice.actions

import type { GetAllGemsTransactionsResponse } from '@rhinofi/rhino-nation-api-spec'
import { useEffect } from 'react'
import { modalKeys } from '../../../constants/modalKeys'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectAddress } from '../../../store/selectors/user.selectors'
import { selectModal } from '../../../store/slices/modal.slice'
import { useUpdateLastSeenTransactionsMutation } from '../services/nation.api'
import { useGemsTransactions } from './useGemsTransactions'

type Props = {
  setTxs: (txs: GetAllGemsTransactionsResponse['items']) => void
  clearState: () => void
}

export const usePollForMissionsNotifications = ({ setTxs, clearState }: Props) => {
  const modal = useAppSelector(selectModal)
  const address = useAppSelector(selectAddress)

  const [updateLastSeenTransactionsTrigger] = useUpdateLastSeenTransactionsMutation()

  const { currentData } = useGemsTransactions({
    input: {
      type: 'EARNED',
      latest: true,
    },
    skip: modal.modalShown === modalKeys.nationOnboarding,
    pollingInterval: 30000,
  })

  useEffect(() => {
    if (!currentData || currentData.items.length === 0) {
      return
    }

    setTxs(currentData.items)

    if ('0' in currentData.items) {
      // Update last seen record so that the be knows to return newer items if available in the next poll
      // Update by the timestamp of the latest earned tx
      void updateLastSeenTransactionsTrigger({
        cursor: currentData.items[0].timestamp,
      })
    }
  }, [currentData, setTxs, updateLastSeenTransactionsTrigger])

  useEffect(() => {
    // Clear shown txs on address change
    clearState()
  }, [address, clearState])
}

import { isFeatureEnabled } from '../../../services/helperService/isFeatureEnabled'
import { F_FLAGS_TYPES } from '../../../constants/types'
import { useABTest } from '../../../hooks/useABTest'
import { ABTestExperimentId } from '../../../services/ABTestService'

const isCombinedBalancesFFEnabled = isFeatureEnabled(F_FLAGS_TYPES.CHAIN_PICKER_COMBINED_BALANCES)

export const useIsCombinedBalancesEnabled = () => {
  const variant = useABTest(ABTestExperimentId.CHAIN_PICKER_COMBINED_BALANCES)
  return isCombinedBalancesFFEnabled && variant === '1'
}
